import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";

const Ajustes = ({ movimiento, setMovimiento, producto, setProducto, addCantidad }) => {
    const [error, setError] = useState({})
    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <label className="form-label">Cantidad en stock</label>
                    <Input
                        name="stock"
                        type="text"
                        className="inputAñadirInventario"
                        readOnly
                        value={producto.cantidad}
                    />
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Cantidad</label>
                    <Input
                        name="solicitado"
                        type="number"
                        className="inputAñadirInventario"
                        onChange={(e) => setProducto({...producto,solicitado:e.target.value})}
                        value={producto.solicitado}
                        invalid={error.solicitado ? true : false}
                    />
                    {error.solicitado && <FormFeedback type="invalid">{error.solicitado}</FormFeedback>}
                </div>

            </div>
        </>
    )
}
export default Ajustes;