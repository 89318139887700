//REGISTER
export const POST_LOGIN_TOKEN = "/login"
export const RESET_ACCOUNT = "/reset-account"
export const FIRST_AUTH = '/login-code-verify'
export const RESET_AUTH = '/verify-reset-code'
//catalogos
//INVENTARIOS
export const PRODUCTOS_RESOURCE = '/productos'
//PROVEEDORES
export const PROVEEDORES_RESOURCE = '/proveedores'
export const PRODUCTOS_PROVEEDORES_RESOURCE = '/proveedores-productos'
export const PROVEEDOR_MASIVA = '/proveedores-masiva'
//Cotizaciones
export const COTIZACIONES_RESOURCE = '/cotizaciones'
export const GET_COTIZACION_PROVEEDORES = '/cotizaciones-producto-costos' 
export const GET_PRODUCT_COTIZACION = '/cotizaciones-producto-data' 
export const COTIZACION_MASIVA = '/cotizaciones-masiva' 
//catalogos
export const CIUDADES_RESOURCE = '/catalogos/ciudades-resource'
export const ESTADOS_MUNICIPIOS_CATALOGO = '/catalogos/direcciones'
export const CP_RESOURCE = '/catalogos/cp-resource'
export const GET_CATEGORIAS = '/catalogos/categorias'
export const GET_UNIDAD_MEDIDAS = '/catalogos/unidad-medidas'
export const GET_PRODUCTO_TIPOS = '/catalogos/producto-tipos'
export const GET_ESTATUS = '/catalogos/estatus'
export const DIVISAS_RESOURCE = '/catalogos/divisas'
export const GET_PROVEEDOR_TIPOS = '/catalogos/proveedor-tipos'
export const GET_Regimens = '/catalogos/fiscales'
export const GET_CFDI = '/catalogos/cfdi'
export const GET_PAGO_TERMINOS = '/catalogos/pago-terminos'
export const GET_PAGO_METODOS = '/catalogos/pago-metodos'
export const GET_PAGO_PLAZOS = '/catalogos/pago-plazos'
export const GET_PAGO_PERIODOS = '/catalogos/pago-periodos'
export const GET_SAT_CATALOGO = '/catalogos/sat-codigos'
export const GET_COMPRAS_TIPOS = '/catalogos/compra-tipos'
//INVENTARIOS
export const INVENTARIOS_RESOURCE = '/inventarios/resource';
export const PRIMER_INV_SUCURSAL = '/inventarios/sucursal/primer-inventario';
export const MOVIMIENTOS_RESOURCE = '/inventarios/movimientos'
export const GET_COSTEO = 'inventarios/sucursal/costeo'
//sucursales
export const SUCURSALES_RESOURCE = '/sucursales'
//Codigos de verificacion
export const CODE_RESOURCE = '/codes'
export const PASSWORD_CONFIRM = '/confirm-password'
//COLABORADOR
export const COLABORADOR_RESOURCE = '/colaboradores'
//Compras
export const COMPRAS_RESOURCE = '/compras'
//VENTAS
export const VENTAS_RESOURCE = '/ventas'

export const VENTAS_CANCELAR = '/ventas-cancelar'

export const GET_HOME_DATA = '/get-home-data'

export const GET_REPORT_GENERAL = '/get-report-general'
//CLIENTS
export const CLIENTS_RESOURCE = '/clientes'
export const CLIENTE_MASIVA = '/masiva-clientes'

//AUDITORIAS
export const AUDITORIA_RESOURCE = '/auditorias'
export const COTIZACION_RESPUESTA = '/cotizaciones-respuesta'

//iNCIDENCIAS
export const INCIDENCIA_SEND = '/incidencia-send'