import React from "react";
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { proveedoresResource } from "../../../helpers/backend_helper";

const Step4 = ({ proveedor, setProveedor, setModal, modal, setStep, getBase64 }) => {
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: proveedor,
        validationSchema: Yup.object({
            contrato: Yup.string().required('Contrato es requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await proveedoresResource('post',values);
            if(response.status){
                setProveedor(response.proveedor)
                setStep({ step: 5, name: 'Paso 5 - Añadir productos', size: 'lg' })
            }
        },
    });
    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            validation.setFieldValue('contrato', base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className='labelAñadir'>Contrato</label>
                    <div className="upload-container col-12">
                        <input type="file" id="file-upload" className="input-file" onChange={(e) => addFile(e)} accept=".pdf" />
                        <label htmlFor="file-upload" className="upload-box">
                            <div className="upload-icon col-12">
                                {/* Aquí podrías incluir el ícono de carga */}
                                
                                <img src={`${process.env.PUBLIC_URL}/assets/images/file.svg`} alt="Upload" />
                            </div>
                            <p className="upload-text">{validation.values.contrato ? 'Documento agregado' : 'Carga aqui el documento'}</p>
                            {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Vista previa" />} */}
                        </label>
                        <br/><br/>
                    </div>
                </div>
                
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Guardar Proveedor
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}
export default Step4;