import React, { useState, useEffect } from 'react';
import ProductosOfrece from '../ProductosOfrece';
import TablaHsitorial from '../TablaHistorial';
import { cpResource, getProveedores_tipos, estadosMunicipiosCatalogo, proveedoresResource } from '../../../helpers/backend_helper';
import PreviewPDF from '../../../components/CommonForBoth/previewPDF';
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import AsyncSelect from 'react-select/async';
import Select from "react-select";

const DatosFiscales = (props) => {
    const [modalPdf, setModalPdf] = useState({ show: false })
    const [showAddModal, setShowAddModal] = useState(false);
    const [modalView, setModalView] = useState('details');
    const [fiscales, setFiscales] = useState({})
    const [selects, setSelects] = useState({
        tipos: [],
    })

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos] = await Promise.all([
                    getProveedores_tipos(),
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.tipos,
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    useEffect(() => {
        const getFiscales = async () => {
            const response = await proveedoresResource('show', { params: { fiscales: true } }, props.proveedor.id)
            setFiscales(response.fiscales)
        }
        getFiscales()
    }, []);

    const showModalAdd = (item) => {
        setShowAddModal(true);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: fiscales,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            values.type = 'fiscales';
            const response = await proveedoresResource('put', values, props.proveedor.id);
            if (response.status) {
                setFiscales(response.data);
                props.setUpdate(false)
            }
        },
    });

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: validation.values.cp.estado_id, municipio_description: validation.values.municipio_nombre } });
            callback(options.data);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#e9ecef',
        }),
    };

    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await props.getBase64(archivo);
            validation.setFieldValue(name, base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    return (
        <>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (props.update)
                        validation.handleSubmit();
                    console.log(validation)
                    return false;
                }}
            >
                <div className='tableInformacionGeneral col-11 col-lg-12 d-flex flex-column p-3'>
                    <div className='d-flex col-12 col-lg-11 justify-content-start align-items-center flex-column flex-lg-row'>
                        <div className='mt-3 mt-lg-0 d-flex col-12 col-lg-8 justify-content-center align-items-center flex-column'>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Acta Constitutiva</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>

                                        <span className='inputValue'>{fiscales.cfdi}</span>
                                        <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: fiscales.actaDoc })}>Ver</button>


                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-6 mx-lg-4'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>CFDI</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>

                                        <span className='inputValue'>{fiscales.acta_constitutiva}</span>
                                        <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: fiscales.cfdi_doc })}>Ver</button>

                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>C.P.</label>
                                    <AsyncSelect
                                        isDisabled={!props.update}
                                        styles={customStyles}
                                        cacheOptions
                                        defaultOptions
                                        value={validation.values.cp}
                                        onChange={option => [validation.setFieldValue('cp', option), validation.setFieldValue('colonia', option.d_asenta)]}
                                        loadOptions={loadOptions}
                                        getOptionLabel={(option) => `${option.d_codigo || validation.values.cp.cp} - ${option.d_asenta || validation.values.cp.asentamiento}`}
                                        getOptionValue={(option) => option.d_codigo}
                                    />
                                    {(validation.touched.cp && validation.errors.cp) && <FormFeedback type="invalid">{validation.errors.cp.value ? validation.errors.cp.value : 'Error'}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>País</label>
                                    <input className='inputDetalleProveedor' disabled value={'Mexico'} />
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6 ml-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Tipo de proveedor</label>
                                    <Select
                                        isDisabled={!props.update}
                                        styles={customStyles}
                                        name="tipo_id"
                                        options={selects.tipos}
                                        value={validation.values.tipo}
                                        onChange={option => [validation.setFieldValue('tipo_id', option.id), validation.setFieldValue('tipo', option)]}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-6 ml-lg-4 mx-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>RFC</label>
                                    <Input
                                        disabled={!props.update}
                                        name="rfc"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.rfc || ""}
                                        invalid={
                                            validation.touched.rfc &&
                                                validation.errors.rfc
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.rfc && validation.errors.rfc) && <FormFeedback type="invalid">{validation.errors.rfc}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Colonia</label>
                                    <input className='inputDetalleProveedor' value={validation.values.cp?.asentamiento} disabled />
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Calle</label>
                                    <Input
                                        disabled={!props.update}
                                        name="direccion.calle"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.direccion?.calle || ""}
                                        invalid={
                                            validation.touched.direccion?.calle &&
                                                validation.errors.direccion?.calle
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.direccion?.calle && validation.errors.direccion?.calle) && <FormFeedback type="invalid">{validation.errors.direccion?.calle}</FormFeedback>}
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-star mx-lg-3 justify-content-between mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column flex-lg-row col-12 col-lg-12'>
                                    <div className='d-flex flex-column col-12 col-lg-6 mx-lg-3'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Sitio Web</label>
                                        <Input
                                            disabled={!props.update}
                                            name="proveedor.web"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.proveedor?.web || ""}
                                            invalid={
                                                validation.touched.proveedor?.web &&
                                                    validation.errors.proveedor?.web
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.proveedor?.web && validation.errors.proveedor?.web) && <FormFeedback type="invalid">{validation.errors.proveedor?.web}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-7 mx-3'>

                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-12 flex-lg-row mx-lg-5'>
                                    <div className='d-flex flex-column col-12 col-lg-3 mx-lg-4 px-lg-2'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número ext.</label>
                                        <Input
                                            disabled={!props.update}
                                            name="direccion.exterior"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.direccion?.exterior || ""}
                                            invalid={
                                                validation.touched.direccion?.exterior &&
                                                    validation.errors.direccion?.exterior
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.direccion?.exterior && validation.errors.direccion?.exterior) && <FormFeedback type="invalid">{validation.errors.direccion?.exterior}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-2'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número int.</label>
                                        <Input
                                            disabled={!props.update}
                                            name="direccion.interior"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.direccion?.interior || ""}
                                            invalid={
                                                validation.touched.direccion?.interior &&
                                                    validation.errors.direccion?.interior
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.direccion?.interior && validation.errors.direccion?.interior) && <FormFeedback type="invalid">{validation.errors.direccion?.interior}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.update &&
                        <button className={`btnVer col-3 mx-2`} type='submit'>
                            Guardar
                        </button>
                    }
                </div >
            </Form>
            <PreviewPDF setModalPdf={setModalPdf} modalPdf={modalPdf} />

        </>

    );
}

export default DatosFiscales;
