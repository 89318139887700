import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label, Table } from "reactstrap";
import { cotizacionesResource } from "../../../helpers/backend_helper";

const Evidencia = ({ modal, setModal }) => {
    console.log(modal.cotizacion)
    const [productos, setProductos] = useState([]);
    const [step, setStep] = useState({ step: 2, name: 'Paso 1 - Selección de tipo de compra', size: 'ls' });
    const [file,setFile] = useState({archivo:null})
    const sabeFile = async() => {
        const response = await cotizacionesResource('put',file,modal.cotizacion.id)
        if(response.status){
            setModal({show:false})
        }
    }

    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            setFile({cotizacion_id:modal.cotizacion.id,archivo: base64});
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };


    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Añadir evidencia
            </ModalHeader>
            <ModalBody>
                <div className="col-sm-12">
                    <label className='labelAñadir'>Contrato</label>
                    <div className="upload-container col-12">
                        <input type="file" id="file-upload" className="input-file" onChange={(e) => addFile(e)} accept=".pdf" />
                        <label htmlFor="file-upload" className="upload-box">
                            <div className="upload-icon col-12">
                                {/* Aquí podrías incluir el ícono de carga */}

                                <img src={`${process.env.PUBLIC_URL}/assets/images/file.svg`} alt="Upload" />
                            </div>
                            <p className="upload-text">{file.archivo ? 'Documento agregado' : 'Carga aqui el documento'}</p>
                            {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Vista previa" />} */}
                        </label>
                        <br /><br />
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block type="submit" onClick={() => sabeFile()}>
                    Añadir evidencia
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default Evidencia