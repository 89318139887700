import React, { useState, useEffect } from "react";
import { Input, Form, FormFeedback, Label, FormGroup } from 'reactstrap'
import ListaProductos from "./Tabs/ListaProductos";
import ListaProdProv from "./Tabs/ListaProdProv";
import ListaInventarioGeneral from "./Tabs/ListaInventarioGeneral";

const ListProductos = () => {
    const [tabActiva, setTabActiva] = useState('productos');
    const [filters, setFilters] = useState({ per_page: 20, page: 1, search: '', estatus_id: null })
    const [report,setReport] = useState({format:'',getReport:false})
    return (
        <>
            <div className='col-12 d-flex mt-4'>

                <div className='d-flex col-6'>
                    <button
                        className={`buttonSucursalesTable p-5 ${tabActiva === 'productos' ? 'buttonSucursalesTableActive' : ''}`}
                        onClick={() => [setTabActiva('productos'), setFilters({ ...filters, tipo_id: 1 })]}
                    >
                        Productos
                    </button>
                    <button
                        className={`buttonSucursalesTable p-4 ${tabActiva === 'prodProv' ? 'buttonSucursalesTableActive' : ''}`}
                        onClick={() => [setTabActiva('prodProv'), setFilters({ ...filters, tipo_id: 2 })]}
                    >
                        Productos Proveedores
                    </button>
                    <button
                        className={`buttonSucursalesTable p-4 ${tabActiva === 'inventGeneral' ? 'buttonSucursalesTableActive' : ''}`}
                        onClick={() => [setTabActiva('inventGeneral'),setFilters({ ...filters, tipo_id: 1 })]}
                    >
                        Inventario general
                    </button>
                </div>
    
    

                <div className='optionSucursales col-10 col-lg-6 d-flex flex-column flex-md-row align-items-center justify-content-center flex-wrap mt-2 mt-lg-1'>
                    <div className='d-flex align-items-center mx-2'>
                        <button onClick={()=>setReport({format:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',getReport:true})} className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>Excel</span></button>
                    </div>
                    <div className='d-flex align-items-center mx-2'>
                        <button onClick={()=>setReport({format:'application/pdf',getReport:true})} className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>PDF</span></button>
                    </div>
                    <div className='d-flex align-items-center mx-2'>
                        <Input className='button-option-filtros d-flex align-items-center justify-content-between' placeholder='Buscador' onChange={((e) => setFilters({ ...filters, search: e.target.value }))} />
                    </div>
                </div>
            </div>
            <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
                <div className="table-responsive table-responsive-sucursales">
                    {tabActiva == 'productos' &&
                        <ListaProductos filters={filters} setFilters={setFilters} report={report} setReport={setReport}/>
                    }
                    {tabActiva == 'prodProv' &&
                        <ListaProdProv filters={filters} setFilters={setFilters} report={report} setReport={setReport}/>
                    }
                    {tabActiva == 'inventGeneral' &&
                        <ListaInventarioGeneral filters={filters} setFilters={setFilters} report={report} setReport={setReport}/>
                    }
                    
                </div>
            </div>
        </>
    )
}

export default ListProductos;