import React, { useEffect, useState } from "react";
import { Table, Modal, Button, ModalBody, ModalFooter, ModalHeader, Form, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { costeoGet } from "../../helpers/backend_helper";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import HistorialProducto from "./HistorialProducto";

const DetalleProducto = ({ modal, setModal, sucursal }) => {
    const [producto, setProducto] = useState({});
    const [tipo_cambio, setTipoCambio] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modalHistoria,setModalHistoria] = useState({show:false,producto:modal.producto})
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const [filters, setFilters] = useState({ fecha_inicio: '', fecha_fin: '', producto_id: modal.producto_id, costeo: true })
    useEffect(() => {
        const getProducto = async () => {
            const response = await costeoGet({ params: filters }, sucursal.id);
            if (response.status) {
                setProducto(response.producto)
                setTipoCambio(response.tipoCambio)
            }
        }
        if (modal.producto_id) {
            console.log(modal)
            getProducto()
        }
    }, [modal.producto_id, filters]);
    const handleDateChange = (date, setter, first = false) => {
        setter(date);
        if (first) {
            setFilters({ ...filters, fecha_inicio: date })
        } else {
            setFilters({ ...filters, fecha_fin: date })
        }
    };
    return (
        <>
            <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={'lg'}>
                <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                    Detalle de producto
                </ModalHeader>

                <ModalBody>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <b>Filtrar por periodo de </b>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleDateChange(date, setStartDate, 'first')}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Fecha inicial"
                                />
                                <b> al</b>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => handleDateChange(date, setEndDate)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="Fecha final"
                                />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Nombre del producto</label>
                                <Input className="inputAñadirInventario" value={producto.producto?.nombre} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Categoría</label>
                                <Input className="inputAñadirInventario" value={producto.categoria} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Costo de adquisición</label>
                                <Input className="inputAñadirInventario" value={producto.adquisicion_costo?.precio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">usd</label>
                                <Input className="inputAñadirInventario" value={tipo_cambio ? producto.adquisicion_costo?.precio / tipo_cambio : 0} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Costo de venta</label>
                                <Input className="inputAñadirInventario" value={producto.producto?.precio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">usd</label>
                                <Input className="inputAñadirInventario" value={producto.producto?.precio / tipo_cambio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Unidades de inventario</label>
                                <Input className="inputAñadirInventario" value={producto.inventario_acomulado} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Precio actual</label>
                                <Input className="inputAñadirInventario" value={producto.costo_promedio} readOnly />
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label">Acomulado Costo de adquisición</label>
                                <Input className="inputAñadirInventario" value={producto.compras_precio_total} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">usd</label>
                                <Input className="inputAñadirInventario" value={producto.compras_precio_total / tipo_cambio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Acomulado Costo de venta</label>
                                <Input value={producto.ventas_precio_total} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">usd</label>
                                <Input className="inputAñadirInventario" value={producto.ventas_precio_total / tipo_cambio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Ganancia actual</label>
                                <Input className="inputAñadirInventario" value={producto.ventas_precio_total - producto.compras_precio_total} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">usd</label>
                                <Input className="inputAñadirInventario" value={(producto.ventas_precio_total - producto.compras_precio_total) / tipo_cambio} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">IVA</label>
                                <Input className="inputAñadirInventario" value={producto.producto?.iva ? producto.producto?.iva : 'Excento'} readOnly />
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">IEPS</label>
                                <Input value={producto.producto?.ieps} readOnly />
                            </div>
                        </div>
                    </div>


                    <br />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() =>  setModalHistoria({show:true,producto:modal.producto})} className="mt-2 fontBold500 button-modal-eliminacion col-12">
                        Historial
                    </Button>
                </ModalFooter>
            </Modal >
            {modalHistoria.show &&
                <HistorialProducto modal={modalHistoria} setModal={setModalHistoria} producto={producto} filters={filters} setFilters={setFilters}/>    
            }
            
        </>
    )
}
export default DetalleProducto;