import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { detalle_sucursal } from '../../store/titles/action'
import { Link } from 'react-router-dom';

const Title = ({ title, localizacion, titleConfig, dispatch }) => {
  // Función para renderizar las opciones basadas en la localización

  const renderOptions = () => {
    if (localizacion === "detalle") {
      // Opciones para "añadirInventario"
      return (
        <>
          <Link to={'/sucursal/nueva'}>
            <button className='button-option-title p-2 mx-md-4'>Añadir sucursal</button>
          </Link>


        </>
      );
    } else if (localizacion === "añadirInventario") {
      return (
        <>
          {/* <button className='button-option-title p-2 mx-md-4'>Añadir sucursal</button> */}
        </>
      );
    } else if (localizacion === "sucursal") {
      const [update, setUpdate] = useState(false);
      useEffect(() => {
        setUpdate(titleConfig.detalle_sucursal.update)
      }, [titleConfig.detalle_sucursal]);
      return (
        <>
          {update ?
            <>
              <button className='button-option-title-edit w-auto p-2 mx-md-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, save: true }))}>
                Guardar Edicion
              </button>
              <button className='button-option-title-edit w-auto p-2 mx-md-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, save: false, update: false }))}>
                Cancelar
              </button>
            </>
            :
            <>
              <button className='button-option-title p-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, insumos: true }))}>
                Solicitar insumos
              </button>
              <button className='button-option-title p-2 mx-md-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, movimiento: true }))}>
                Realizar movimiento
              </button>
              <button className='button-option-title-edit w-auto p-2 mx-md-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, update: true }))}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/edit.svg`} />
              </button>
              <button className='button-option-title-edit w-auto p-2 mx-md-2' onClick={() => dispatch(detalle_sucursal({ ...titleConfig.detalle_sucursal, delete: true }))}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/trash.svg`} />
              </button>
            </>
          }

        </>
      )
    } else if (localizacion === "Nueva_sucursal") {

    } else {
      // Opciones predeterminadas
      return (
        <>
          <Link to={'/auditorias'}>
            <button className='button-option-title p-2'>Auditorias</button>
          </Link>
          <Link to={'/ciudad/nueva'}>
            <button className='button-option-title p-2 mx-md-4'>Añadir nueva sucursal</button>
          </Link>

        </>
      );
    }

  };

  return (
    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center container-title'>
      <div className='title-dash mb-3 mb-md-0'>
        <h1 className='text-title-dash' style={{ fontWeight: "800" }}>{title}</h1>
      </div>

      <div className='container-option-title d-flex flex-column flex-md-row'>
        {renderOptions()}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  titleConfig: state.Titles
});

export default connect(mapStateToProps)(Title);
