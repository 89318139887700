import React from 'react';
import Sidebar from '../components/HOME/Sidebar';
import "../styles/Layout.css"

const Layout = ({ children }) => {
  return (
    <div className="d-flex">
      <Sidebar />
      <main className="flex-grow-1" style={{ marginTop: "2.2rem", marginLeft: "2.5rem", paddingRight: "30px" }}>
        <div className="content-scrollable">
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;