import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";
import { passwordConfirm } from "../../helpers/backend_helper";

const PasswConfirm = ({ modal, setModal, save }) => {
    const [password, setPassword] = useState({ password: '' });

    const handleChange = (value) => {
        console.log(value.target.value, 'kokoscs')
        setPassword({ password: value.target.value });
    };

    const confirm = async () => {
        const response = await passwordConfirm(password);
        if (response.status) {
            save()
            setModal({ status: true })
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} backdrop="static">
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>{'Confirma por password'}</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <p>Ingresa el codigo de confirmación de parte del supervisor</p>
                    <div className="row">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <Input
                                name="password"
                                className=""
                                placeholder="Ingresa tu contraseña"
                                type="password"
                                autoComplete='off'
                                onChange={e=>setPassword({...password,password:e.target.value})}
                            />
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirm()}>
                    Confirmar
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default PasswConfirm
