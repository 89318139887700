import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { sucursalesResource, comprasResource} from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";



const Step4 = ({ modal, setModal, compra, setCompra, setStep,setProductos,productos }) => {
    const [selects, setSelects] = useState({
        sucursales: [],
        
    })
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [sucursales] = await Promise.all([
                    sucursalesResource('get',{params:{select:true}}),
                   
                ]);

                setSelects({
                    ...selects,
                    sucursales:sucursales.sucursales,
                   
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: compra,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            values.productos=productos
            const response =  await comprasResource('post',values)
            if(response.status){
                setCompra(values)
                setStep({step:1})
                setModal({show:false})
            }
            
            
        },
    });

    const getProductos = async (option) => {
        let products = await getProductCotizacion(option.id);
        console.log(products)
        validation.setFieldValue('cotizacion', option)
        validation.setFieldValue('cotizacion_id', option.id)
        validation.setFieldValue('proveedor', option.proveedor)
        validation.setFieldValue('proveedor_id', option.proveedor.id)
        setProductos(products.productos)
    }

    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            validation.setFieldValue('archivo', base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-16">
                    <label className="form-label">Numero de seguimiento</label>
                    <Input
                        name="segiomiento"
                        type="text"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.segiomiento || ""}
                        invalid={
                            validation.touched.segiomiento &&
                                validation.errors.segiomiento
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.segiomiento && validation.errors.segiomiento) && <FormFeedback type="invalid">{validation.errors.segiomiento}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Sucursal asignada</label>
                    <Select
                        styles={customStyles}
                        name="sucursal_id"
                        options={selects.sucursales}
                        value={validation.values.sucursal}
                        onChange={option => [validation.setFieldValue('sucursal', option),validation.setFieldValue('sucursal_id', option.id)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.sucursal_id && validation.errors.sucursal_id) && <FormFeedback type="invalid">{validation.errors.sucursal_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">F. Entrega</label>
                    <Input
                        name="fecha_entrega"
                        type="date"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fecha_entrega || ""}
                        invalid={
                            validation.touched.fecha_entrega &&
                                validation.errors.fecha_entrega
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.fecha_entrega && validation.errors.fecha_entrega) && <FormFeedback type="invalid">{validation.errors.fecha_entrega}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">F. Estimada</label>
                    <Input
                        name="fecha_estimada"
                        type="date"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fecha_estimada || ""}
                        invalid={
                            validation.touched.fecha_estimada &&
                                validation.errors.fecha_estimada
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.fecha_estimada && validation.errors.fecha_estimada) && <FormFeedback type="invalid">{validation.errors.fecha_estimada}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className='labelAñadir'>Contrato</label>
                    <div className="upload-container col-12">
                        <input type="file" id="file-upload" className="input-file" onChange={(e) => addFile(e)} accept=".pdf" />
                        <label htmlFor="file-upload" className="upload-box">
                            <div className="upload-icon col-12">
                                {/* Aquí podrías incluir el ícono de carga */}

                                <img src={`${process.env.PUBLIC_URL}/assets/images/file.svg`} alt="Upload" />
                            </div>
                            <p className="upload-text">{validation.values.archivo ? 'Documento agregado' : 'Carga aqui el documento'}</p>
                            {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Vista previa" />} */}
                        </label>
                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step4