import React from 'react';

const Filtros = ({ detalle, ciudades, dataInventarios, ciudad }) => {
  return (
    <div className='container-fluid mx-3 mt-3 mb-4'>
      <div className='row justify-content-between align-items-center'>

        {detalle == "ciudad" ?
          <>
            {/* Tarjetas de filtro para "ciudad", todas en una fila */}
            <div className='d-flex justify-content-center justify-content-lg-start flex-wrap mb-3 mb-md-0'>

              {/* Tarjeta 1 */}
              <div className='cardFiltros text-start col-12 col-md-6 col-lg-2 mb-3 mx-md-4 p-4'>
                <h6 className='titleCiudadCardSpace mt-1' style={{ fontSize: "1.1em" }}>Total de Ingresos</h6>
                <h2 className='numberCardFiltros' style={{ fontSize: "1.6em" }}>${Number(ciudad.ingresos_total).toLocaleString('en-US')}</h2>
              </div>

              {/* Tarjeta 2 */}
              <div className='cardFiltros text-start col-12 col-md-6 col-lg-2 mb-3 mx-md-4 p-4'>
                <h6 className='titleCiudadCardSpace mt-1' style={{ fontSize: "1.1em" }}>Total de Egresos</h6>
                <h2 className='numberCardFiltros' style={{ fontSize: "1.6em" }}>${Number(ciudad.egresos_total).toLocaleString('en-US')}</h2>
              </div>

              {/* Tarjeta 3 */}
              <div className='cardFiltros text-start col-12 col-md-6 col-lg-2 mb-3 p-4'>
                <h6 className='titleCiudadCardSpace mt-1' style={{ fontSize: "1.1em" }}>Stock - {Number(ciudad.stock_total).toLocaleString('en-US')} u.</h6>
                <h2 className='numberCardFiltros' style={{ fontSize: "1.6em" }}>${Number(ciudad.precio_total).toLocaleString('en-US')}</h2>
              </div>

              {/* Tarjeta 4 */}
              

             
            </div>
          </>
          :
          <>
            {/* Sección de tarjetas de filtro */}
            <div className='col-12 col-md-auto d-flex justify-content-center flex-wrap mb-3 mb-md-0'>

              <div className='cardFiltros text-start'>
                <h6 className='p-1 titleCiudadCardSpace mt-1' style={{ fontSize: "0.9em" }}></h6>
                <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Stock - {Number(dataInventarios.stockTotal).toLocaleString('en-US')} u.</h6>
                <h2 className='spaceCardCiudad numberCardFiltros' style={{ fontSize: "1.6em" }}>${Number(dataInventarios.precioTotal).toLocaleString('en-US')}</h2>
                <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
              </div>

              <div className='cardFiltros text-start mx-4'>
                <h6 className='p-1 titleCiudadCardSpace mt-1' style={{ fontSize: "0.9em" }}></h6>
                <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Total de Ciudades</h6>
                <h2 className='spaceCardCiudad numberCardFiltros' style={{ fontSize: "1.6em" }}>{ciudades}</h2>
                <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
              </div>

            </div>

            {/* Sección de opciones de ordenación y filtros */}
            <div className='col-10 col-lg-auto d-flex flex-column flex-md-row align-items-center justify-content-center flex-wrap mx-md-4 ms-5 mt-md-5 mt-lg-5 mt-xl-0 mt-2 mt-lg-1'>

              <div className='d-flex align-items-center mx-2'>
                <h6 className='text-title-dash d-flex justify-content-center align-items-center mt-2 mx-2'>Ordenar por</h6>
                <button className='button-option-filtros d-flex align-items-center justify-content-between'><span className='mx-2'>A - Z</span></button>
              </div>

              <div className='d-flex align-items-center mx-2'>
                <h6 className='text-title-dash d-flex justify-content-center align-items-center mt-2 mx-2'>Filtros</h6>
                <button className='button-option-filtros d-flex align-items-center justify-content-between'><span className='mx-2'>Todos</span> </button>
              </div>

              <div className='d-flex align-items-center mx-2'>
                <input className='button-option-filtros d-flex align-items-center justify-content-between' placeholder='Buscador' />
              </div>

            </div>
          </>
        }

      </div>
    </div>
  );
}

export default Filtros;
