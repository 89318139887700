import React from 'react';
import Sidebar from '../components/HOME/Sidebar';
import Title from '../components/HOME/Title';
import "../styles/Layout.css"

const Layout = ({ children, title, localizacion }) => {
  return (
    <div className="d-flex">
      <Sidebar />
      <main className="flex-grow-1" style={{ marginTop: "2.2rem", marginLeft: "2.5rem", paddingRight: "30px" }}>
        <Title title={title} localizacion={localizacion} />
        <div className="content-scrollable">
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
