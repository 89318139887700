import React, { useMemo, useState, useEffect } from "react";
import PreviewPDF from "../../../components/CommonForBoth/previewPDF";
import { Table } from "reactstrap";
import { productosResource } from "../../../helpers/backend_helper";
import { productosProveedoresResource } from "../../../helpers/backend_helper";
const ListaProdProv = ({ filters, setFilters, report, setReport }) => {
    const headersTable = useMemo(() => [
        { name: 'Nombre', class: '' },
        { name: 'Descripcion', class: '' },
        { name: 'Unidad Medida', class: '' },
        { name: 'Precio', class: '' },
        { name: 'IVA', class: '' },
        { name: 'IEPS', class: 'justify-content-center text-center' },
        { name: 'Proveedor', class: 'justify-content-center text-center' },
    ], []);
    const [modalPdf, setModalPdf] = useState({ show: false })
    const [productos, setProductos] = useState([[]])


    useEffect(() => {
        const getProductos = async () => {
            const response = await productosProveedoresResource('get', { params: filters })
            setProductos(response.productos)
        }
        getProductos()
    }, [filters]);

    useEffect(() => {
        const getReporte = async () => {

            let config = {
                params: filters,
                headers: { 'Accept': report.format },
                responseType: "blob"
            }

            productosProveedoresResource('get', config).then(response => {
                if (report.format != 'application/pdf') {
                    console.log(response,'ok')
                    const url = URL.createObjectURL(new Blob([response]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        report.format == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'Reporte de productos.xlsx' : 'Reporte de productos.pptx'
                    )
                    document.body.appendChild(link)
                    link.click()

                } else {
                    let nombre = 'Archivo';
                    var reader = new FileReader();
                    reader.readAsDataURL(response);
                    reader.fileName = nombre[1] || nombre[0];
                    reader.onloadend = function (name) {
                        name = nombre[0];
                        var base64data = reader.result;
                        setModalPdf({ show: true, update: false, url: base64data });
                    };
                }
                setReport({getReporte:false})
            })


        }
        if (report.getReport) {
            getReporte()
        }
    }, [report]);

    return (
        <>
            <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
                <div className="table-responsive table-responsive-sucursales">
                    <table className="table table-sucursales">
                        <thead className='text-center'>
                            <tr>
                                {headersTable.map((h, i) => (
                                    <th key={i} className={h.class}>{h.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {productos.length ? productos.map((p, i) => (
                                <tr key={i}>
                                    <th>{p.producto?.nombre}</th>
                                    <th>{p.producto?.descripcion}</th>
                                    <th>{p.unidad_medida?.nombre}</th>
                                    <th>${Number(p.precio).toLocaleString('US')}</th>
                                    <th>{p.iva ? p.iva : 'Excento'}</th>
                                    <th>{p.ieps ? p.ieps : 'Excento'}</th>
                                    <th>{p.proveedor?.nombre}</th>
                                </tr>
                            ))
                                :
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={16} style={{ textAlign: 'center' }}>
                                        No hay registros para mostrar
                                    </th>
                                </tr>
                            }
                            {/* Puedes agregar más filas aquí */}
                        </tbody>
                    </table>

                </div>

            </div>
            <PreviewPDF modalPdf={modalPdf} setModalPdf={setModalPdf} />
        </>
    )
}
export default ListaProdProv;