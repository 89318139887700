import React, { useState, useEffect } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";

const Devolucion = ({ movimiento, setMovimiento, producto, setProducto, sucursalOrigen, addCantidad }) => {
    const [error, setError] = useState({})
    const [sucursales, setSucursales] = useState([])
    const [sucursal, setSucursal] = useState(null)
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const base64 = reader.result;
            setProducto({...producto,documento_devolucion:base64})
        };

        reader.readAsDataURL(file);
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-4">
                    <label className="form-label">Cantidad</label>
                    <Input
                        name="solicitado"
                        type="number"
                        className="inputAñadirInventario"
                        onChange={(e) => addCantidad(e)}
                        value={producto.solicitado}
                        invalid={error.solicitado ? true : false
                        }
                    />
                    {error.solicitado && <FormFeedback type="invalid">{error.solicitado}</FormFeedback>}
                </div>
                <div className="col-sm-8">
                    <label className="form-label">Motivo</label>
                    <Input
                        name="motivo"
                        type="text"
                        className="inputAñadirInventario"
                        onChange={(e) => setProducto({ ...producto, motivo: e.target.value })}
                        value={producto.motivo}
                        invalid={error.motivo ? true : false
                        }
                    />
                    {error.motivo && <FormFeedback type="invalid">{error.motivo}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Proveedor</label>
                    <Input
                        name="motivo"
                        type="text"
                        className="inputAñadirInventario"
                        value={producto.proveedor_nombre}
                        readOnly
                    />
                </div>
                <div className="col-sm-12">
                    <label className='labelAñadir'>Añadir documento de devolucion</label>
                    <div className="upload-container col-12">
                        <input type="file" id="file-upload" className="input-file" onChange={handleFileUpload} />
                        <label htmlFor="file-upload" className="upload-box">
                            <div className="upload-icon col-12">
                                {/* Aquí podrías incluir el ícono de carga */}
                                <img src={`${process.env.PUBLIC_URL}/assets/images/file.svg`} alt="Upload" />
                            </div>
                            <p className="upload-text">{producto.documento_devolucion ? 'Documento agregado':'Carga aqui el documento'}</p>
                            {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Vista previa" />} */}
                        </label>
                    </div>
                </div>


            </div>
        </>
    )
}
export default Devolucion;