import React, { useEffect, useState } from "react";
import {Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";


const NewCompraModal = ({ modal, setModal }) => {
    const [productos, setProductos] = useState([]);
    const [compra,setCompra] = useState({})
    const [step, setStep] = useState({step:1,name:'Paso 1 - Selección de tipo de compra',size:'ls'});
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Nueva Compra
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            {step.name}
                            <div className="text-center">
                                {step.step} of 4
                            </div>
                            <Progress
                                animated
                                max="4"
                                value={step.step}
                                style={{ height: '7px' }}
                            />
                        </div>
                        <div className="col-sm-12">
                            {step.step == 1 &&
                                <Step1 setStep={setStep} compra={compra} setCompra={setCompra} modal={modal} setModal={setModal} setProductos={setProductos} productos={productos}/>
                            }
                            {step.step == 2 &&
                                <Step2 setStep={setStep} compra={compra} setCompra={setCompra} modal={modal} setModal={setModal} setProductos={setProductos} productos={productos}/>
                            }
                            {step.step == 3 &&
                                <Step3 setStep={setStep} compra={compra} setCompra={setCompra} modal={modal} setModal={setModal} setProductos={setProductos} productos={productos}/>
                            }
                            {step.step ==4 &&
                                <Step4 setStep={setStep} compra={compra} setCompra={setCompra} modal={modal} setModal={setModal} setProductos={setProductos} productos={productos}/>
                            }
                            
                            
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </Modal>
    )
}

export default NewCompraModal