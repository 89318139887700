import React, { useMemo, useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { sucursalesResource,getCategorias } from '../../helpers/backend_helper';

const ChartDonaCategoria = (props) => {
  const [sucursales, setSucursales] = useState({})
  const [graficaDatos, setGraficaDatos] = useState([])
  useEffect(() => {
    const getDataDatos = async () => {
      const response = await getCategorias()
      let array = [];
      response.categorias.map((c) => {
        array.push({
          name: c.nombre,
          value: c.stock_total
        });
      });
      setGraficaDatos(array)

      setSucursales(response.categorias)

    }
    getDataDatos()
  }, []);
  

  const options = useMemo(() => ({
    color: ['#F94144', '#2D9CDB', '#F8961E', '#F9C74F', '#90BE6D', '#F3722C'],
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'horizontal',
      type: 'plain',
      bottom: '0%',
      itemGap: 10, // Puedes ajustar esto según sea necesario
      itemWidth: 8, // Puedes ajustar esto según sea necesario
      itemHeight: 8,
      icon: 'circle',
      width: '80%', // Ajusta el ancho de la leyenda para permitir que los elementos se distribuyan en filas de 3
      // Asegúrate de que el contenedor del gráfico sea lo suficientemente ancho para permitir 3 elementos por fila
    },
    series: [
      {
        name: 'Acceso a Sucursales',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: graficaDatos
      }
    ]
  }), [graficaDatos]);

  return (
    <div className='cardModulo p-3 text-start mx-4'>
      <h2>{props.title}</h2>
      <ReactECharts option={options} style={{ height: 400 }} />
    </div>
  )
}

export default ChartDonaCategoria;
