import React, { useEffect, useState } from "react";
import { getPago_metodos, getPago_plazos, getRegimens, getPago_terminos } from "../../../helpers/backend_helper";
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import Select from "react-select";

const Step3 = ({ proveedor, setProveedor, setModal, modal,setStep }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const [selects, setSelects] = useState({
        optionsPersona: [
            { label: 'Fisica', value: 0 },
            { label: 'Moral', value: 1 }
        ],
        estados: [],
        tipos: [],
        municipios: [],
        pago_terminos: [],
        plazos: [],
        metodos: [],
    })
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: proveedor,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            console.log('okckokosoksoksoksoksoksoksoks')
            setProveedor(values)
            setStep({ step: 4, name: 'Paso 4 - Contrato', size: 'ls' })
        },
    });
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [terminos, regimens, metodos, plazos] = await Promise.all([
                    getPago_terminos(),
                    getRegimens(),
                    getPago_metodos(),
                    getPago_plazos()
                ]);

                setSelects({
                    ...selects,
                    pago_terminos: terminos.terminos,
                    regimens: regimens.fiscales,
                    metodos: metodos.metodos,
                    plazos: plazos.plazos
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Términos de pago</label>
                    <Select
                        styles={customStyles}
                        name="bancos.pago_terminos_id"
                        options={selects.pago_terminos}
                        value={validation.values.banco.pago_termino}
                        onChange={option => [validation.setFieldValue('banco.pago_terminos_id', option.id), validation.setFieldValue('banco.pago_termino', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.banco?.pago_terminos_id && validation.errors.banco?.pago_terminos_id) && <FormFeedback type="invalid">{validation.errors.banco?.pago_terminos_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Forma de pago</label>
                    <Select
                        styles={customStyles}
                        name="bancos.pago_metodo_id"
                        options={selects.metodos}
                        value={validation.values.banco.metodo}
                        onChange={option => [validation.setFieldValue('banco.pago_metodo_id', option.id), validation.setFieldValue('banco.pago_metodo', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.banco?.pago_metodo_id && validation.errors.banco?.pago_metodo_id) && <FormFeedback type="invalid">{validation.errors.banco?.pago_metodo_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Monto de credito</label>
                    <Input
                        name="credito_monto"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.credito_monto || ""}
                        invalid={
                            validation.touched.credito_monto &&
                                validation.errors.credito_monto
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.credito_monto && validation.errors.credito_monto) && <FormFeedback type="invalid">{validation.errors.credito_monto}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Plazos de pago</label>
                    <Select
                        styles={customStyles}
                        name="bancos.pago_plazo_id"
                        options={selects.plazos}
                        value={validation.values.banco.pago_plazo}
                        onChange={option => [validation.setFieldValue('banco.pago_plazo_id', option.id), validation.setFieldValue('banco.pago_plazo', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.banco?.pago_plazo_id && validation.errors.banco?.pago_plazo_id) && <FormFeedback type="invalid">{validation.errors.banco?.pago_plazo_id}</FormFeedback>}
                </div>
                {proveedor.accreedor ?
                    <div className="col-sm-12">
                        <label className="form-label">Periodos</label>
                        <Select
                            styles={customStyles}
                            name="periodo_id"
                            options={selects.plazos}
                            value={validation.values.banco.pago_periodo}
                            onChange={option => [validation.setFieldValue('banco.periodo_id', option.id), validation.setFieldValue('banco.pago_periodo', option)]}
                            getOptionLabel={(option) => `${option.nombre}`}
                            getOptionValue={(option) => option.id}
                        />
                        {(validation.touched.banco?.periodo_id && validation.errors.banco?.periodo_id) && <FormFeedback type="invalid">{validation.errors.banco?.periodo_id}</FormFeedback>}
                    </div>
                    :
                    <div className="col-sm-6">
                        <label className="form-label">Tiempos de entrega</label>
                        <Select
                            styles={customStyles}
                            name="entrega_tiempo_id"
                            options={selects.plazos}
                            value={validation.values.entrega_tiempo}
                            onChange={option => [validation.setFieldValue('entrega_tiempo_id', option.id), validation.setFieldValue('entrega_tiempo', option)]}
                            getOptionLabel={(option) => `${option.nombre}`}
                            getOptionValue={(option) => option.id}
                        />
                        {(validation.touched.entrega_tiempo_id && validation.errors.entrega_tiempo_id) && <FormFeedback type="invalid">{validation.errors.entrega_tiempo_id}</FormFeedback>}
                    </div>
                }
                {!proveedor.accreedor &&
                    <div className="col-sm-6">
                        <label className="form-label">Tiempos de entrega</label>
                        <Input
                            name="costo_envio"
                            className="inputAñadirInventario"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.costo_envio || ""}
                            invalid={
                                validation.touched.costo_envio &&
                                    validation.errors.costo_envio
                                    ? true
                                    : false
                            }
                        />
                        {(validation.touched.costo_envio && validation.errors.costo_envio) && <FormFeedback type="invalid">{validation.errors.costo_envio}</FormFeedback>}
                    </div>
                }
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}
export default Step3;