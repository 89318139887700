import React, { useState, useEffect } from "react";
import CustomSelect from "../../components/SELECT/CustomSelect";
import { estadosMunicipiosCatalogo, cpResource, ciudadesResource } from '../../helpers/backend_helper'
import { Container, Button, Input } from "reactstrap";
import { FaRegTrashAlt } from 'react-icons/fa';
import Map from '../../components/Map/Map'
import { useNavigate } from 'react-router-dom';
import { update_sucursal } from '../../store/sucursales/action';
import { connect } from 'react-redux';
import Select from "react-select";

const NuevaCiudad = ({ sucursal, dispatch }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const navigate = useNavigate();
    const [ubicacion, setUbicacion] = useState(null);
    const [ciudades, setCiudades] = useState([]);
    const [estados, setEstados] = useState([]);
    const [ciudad, setCiudad] = useState({
        estado_id: null,
        nombre: '',
        estadoNombre: ''
    })

    useEffect(() => {
        const getEstados = async () => {
            const response = await estadosMunicipiosCatalogo({ params: { estados: true } });
            if (response.status) {
                setEstados(response.data)
            }
        }
        getEstados()
    }, []);

    useEffect(() => {
        const getCiudadesCatalogo = async () => {
            const response = await ciudadesResource('get', { params: { estado_id: ciudad.estado_id } });
            if (response.status) {
                console.log(response.ciudades)
                setCiudades(response.ciudades)
            }
        }
        if(ciudad.estado_id){
            getCiudadesCatalogo()
        }
        buscarUbicacionEnGoogleMaps()
    }, [ciudad.estado_id]);

    useEffect(() => {
        
        buscarUbicacionEnGoogleMaps()
    }, [ciudad])

    const save = async () => {
        ciudad.sucursal_almacen = true
        const response = await ciudadesResource('post', ciudad)
        if (response.status) {
            dispatch(update_sucursal({ ...sucursal, nombre_ciudad:ciudad.nombre,estado_nombre:ciudad.estadoNombre,estado_id: ciudad.estado_id, ciudad_id: response.municipio.id }));
            navigate("/sucursal/nueva")
        }
    }
    const buscarUbicacionEnGoogleMaps = async () => {
        if (ciudad.estadoNombre && ciudad.nombre) {
            try {
                const direccion = `${ciudad.nombre}, ${ciudad.estadoNombre}`;
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        direccion
                    )}&key=AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ`
                );
                const data = await response.json();

                if (data.results.length > 0) {
                    const ub = data.results[0].geometry.location;
                    setUbicacion({ lat: ub.lat, lng: ub.lng });
                } else {
                    console.error('No se pudo encontrar la ubicación para la dirección:', direccion);
                    //return { lat: 0, lng: 0 };
                }
            } catch (error) {
                console.error('Error al buscar la ubicación:', error);
                //return { lat: 0, lng: 0 };
            }
        }
    };

    return (
        <div className="d-flex">
            <main className="flex-grow-1">
                <p>Busca y selecciona la ciudad en la que quieres comenzar a operar una nueva sucursal</p>


                <div className="row">
                    <div className="col-sm-4">
                        <label className="form-label">Estado:</label>
                        <CustomSelect
                            name="estado_id"
                            options={estados}
                            value={ciudad.estadoData}
                            onChange={e => [setCiudad({ ...ciudad, estado_id: e.id, estadoData: e, estadoNombre: e.nombre }), buscarUbicacionEnGoogleMaps()]}
                            getOptionLabel={(option) => `${option.nombre}`}
                            getOptionValue={(option) => option.id}
                            isSearchable={true}
                        />
                        {

                        }
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label">Ciudad:</label>
                        <Select
                            name="estado_id"
                            styles={customStyles}
                            options={ciudades}
                            value={ciudad.data}
                            onChange={(option) => [setCiudad({ ...ciudad, nombre: option.nombre,ciudad_id: option.id,data: option }), buscarUbicacionEnGoogleMaps()]}
                            getOptionsLabel={true}
                            isSearchable={true}
                            isDisabled={ciudad.estado_id == null}
                            defaultValue={null}
                            getOptionLabel={(option) => `${option.nombre}`}
                            getOptionValue={(option) => option.id}
                        />

                        {

                        }
                        <br /><br />
                    </div>
                    {ciudad.nombre &&
                        <>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-1" style={{ textAlign: 'center' }}>
                                <label className="form-label">Nombre:</label>
                            </div>
                            <div className="col-sm-2" style={{ textAlign: 'center' }}>
                                <Input type="text" value={ciudad.nombre ? ciudad.nombre : ''} readOnly={true} onChange={(e) => console.log()} />
                            </div>
                            <div className="col-sm-1" style={{ textAlign: 'center' }}>
                                <label className="form-label">Estado:</label>
                            </div>
                            <div className="col-sm-2" style={{ textAlign: 'center' }}>
                                <Input type="text" value={ciudad.estadoNombre} readOnly={true} onChange={(e) => console.log()} />
                            </div>
                            <div className="col-sm-4">
                                <a className="" onClick={() => setCiudad({ ...ciudad, estadoData: null, data: null, nombre: '', estado_id: null, estadoNombre: '' })}><FaRegTrashAlt /></a>
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'center' }}>
                                <br /><br />
                                <Button color="secondary" block onClick={() => save()}>Confirmar ciudad</Button>
                            </div>
                            {ubicacion &&
                            <div className="col-sm-8" style={{ textAlign: 'center' }}>
                                <Map solicitud={ubicacion} setSolicitud={setUbicacion} streetViewMap={false} />
                            </div>
                            }
                        </>
                    }
                    <div>
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                    </div>
                </div>
            </main>
        </div>
    )
}
const mapStateToProps = state => ({
    sucursal: state.Sucursal.sucursal // Accede al estado de Redux mapeado a la prop 'sucursal'
});

export default connect(mapStateToProps)(NuevaCiudad)