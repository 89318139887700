import React, { useMemo, useState,useEffect } from 'react';
import ChartDona from '../components/HOME/ChartDona';
import ChartDonaCategoria from '../components/HOME/ChartDonaCategoria';
import ChartBarras from '../components/HOME/ChartBarras';
import Sidebar from '../components/HOME/Sidebar';
import Title from '../components/HOME/Title';
import Card from '../components/HOME/Card';
import "../styles/Home.css";
import Periodo from '../components/HOME/Periodo';
import { getHomeData } from '../helpers/backend_helper';


const Dashboard = () => {
const [filters,setFilters]=useState({fecha_inicial:'',fecha_final:''})
const modulos = [
  {label:'Compras',value:'ventas'},
  {label:'Ventas',value:'ventas'}
]

const [data,setData]=useState({}) 
  useEffect(() => {  
    const getData = async () => {
      let params = {params:filters};
        const response = await getHomeData(params)
        setData(response.data)
    }
    getData()
}, [filters]); 

    const cardsData = useMemo(() => [
        { ingreso: "Monto total de Ingresos", monto: Number(data.total_ventas_contado).toLocaleString('US'), fecha: "Del 01 al 15 de Dic." },
        { ingreso: "Monto total de Egresos", monto: Number(data.total_compras_contado).toLocaleString('US'), fecha: "Del 01 al 15 de Dic." },
        { ingreso: "Cantidad de compras", monto: Number(data.compras).toLocaleString('US'), fecha: "Del 01 al 15 de Dic.", sign:false},
        { ingreso: "Cantidad de Ventas", monto: Number(data.ventas).toLocaleString('US'), fecha: "Del 01 al 15 de Dic.", sign:false},
        { ingreso: "Cuentas por Cobrar", monto: Number(data.total_ventas_credito).toLocaleString('US'), fecha: "Del 01 al 15 de Dic." },
        { ingreso: "Cuentas por Pagar", monto: Number(data.total_compras_credito).toLocaleString('US'), fecha: "Del 01 al 15 de Dic." }
      ], [data]);
      

  return (
    <div className="d-flex">
      <main className="flex-grow-1">
        <Periodo title={"Inventarios"} filters={filters} setFilters={setFilters}/>
        <div className="row mt-3">
          {cardsData.map((card, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <Card titlePrincipal={""} {...card} />
            </div>
          ))}
        </div>

        {/* graficas */}
        <div className="row mt-3">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
            <ChartDona title={"Inventario por sucursal"} />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
            <ChartDonaCategoria title={"Inventario por categoria"} />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
            <ChartBarras title={"Datos de Finanzas y Contabilidad"} />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <ChartDona title={"Inventario por sucursal"} />
          </div> */}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
