import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import { productosResource } from "../../../helpers/backend_helper";

const Insumos = ({ modal, setModal }) => {
    const [producto, setProducto] = useState(null);
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>Solicitar Insumos a matriz</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="form-label">Buscador de productos </label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={producto}
                                onChange={option => [setProducto(option)]}
                                loadOptions={loadOptions}
                                getOptionLabel={(option) => `${option.nombre}`}
                                getOptionValue={(option) => option.id}
                            />
                            <br />

                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirm()}>
                    Confirmar
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Insumos