import React, { useState, useEffect } from "react";
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cpResource, getProveedores_tipos, estadosMunicipiosCatalogo, getRegimens, getCfdis,ciudadesResource } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";

const Step2 = ({ proveedor, setProveedor, modal, setModal, getBase64, setStep }) => {
    const [selects, setSelects] = useState({
        optionsPersona: [
            { label: 'Fisica', value: 0 },
            { label: 'Moral', value: 1 }
        ],
        estados: [],
        tipos: [],
        municipios: [],
        regimens: []
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: proveedor,
        validationSchema: Yup.object({
            rfc: Yup.string().required('RFC es requerido'),

            banco: Yup.object().shape({
                cuenta: Yup.string().required('Cuenta es requerido'),
                clabe: Yup.string().required('CLABE es requerido'),
                banco: Yup.string().required('Banco es requerido'),
            }),
            fiscales: Yup.object().shape({
                regimen_id: Yup.number().required('Regimen fiscal es requerido').integer('Regimen fiscal debe ser un número entero'),
                estado_id: Yup.number().required('Estado es requerido').integer('Estado debe ser un número entero'),
                municipio: Yup.object().nullable().required('Ciudad es requerida'),
                cp: Yup.object().nullable().required('Código Postal es requerido'),
                colonia: Yup.string().required('Colonia es requerida'),
                exterior: Yup.string().required('Número exterior es requerido'),
            }),
        }),
        onSubmit: async (values, { setErrors }) => {
            setProveedor(values)
            setStep({ step: 3, name: 'Paso 3 - Datos Bancarios', size: 'ls' })
        },
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: validation.values.fiscales.estado_id, municipio_description: validation.values.fiscales.municipio.label } });
            callback(options.data);
        }
    };
    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            validation.setFieldValue(name, base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos, estados, regimens] = await Promise.all([
                    getProveedores_tipos(),
                    estadosMunicipiosCatalogo({ params: { estados: true } }),
                    getRegimens(),
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.tipos,
                    estados: estados.data,
                    regimens: regimens.fiscales
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);
    

    useEffect(() => {
        const getCiudadesCatalogo = async () => {
            const response = await ciudadesResource('get', { params: { estado_id: validation.values.fiscales.estado_id } });
            if (response.status) {
                setSelects({ ...selects, municipios: response.ciudades })
            }
        }
        if (validation.values.fiscales.estado_id) {
            getCiudadesCatalogo()
        }
    }, [validation.values.fiscales.estado_id]);

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-6">
                    <label className="form-label">Persona</label>
                    <Select
                        styles={customStyles}
                        name="fiscales.persona"
                        options={selects.optionsPersona}
                        value={selects.optionsPersona.find(option => option.value === validation.values.fiscales.persona)}
                        onChange={option => [validation.setFieldValue('fiscales.persona', option.value)]}
                    />
                    {(validation.touched.fiscales?.persona && validation.errors.fiscales?.persona) && <FormFeedback type="invalid">{validation.errors.fiscales?.persona}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">RFC</label>
                    <Input
                        name="rfc"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rfc || ""}
                        invalid={
                            validation.touched.rfc &&
                                validation.errors.rfc
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.rfc && validation.errors.rfc) && <FormFeedback type="invalid">{validation.errors.rfc}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Acta constitutiva</label>
                    <Input
                        accept=".pdf"
                        name="acta_constitutiva"
                        type="file"
                        className="inputAñadirInventario"
                        onChange={(e) => addFile(e, 'acta_constitutiva')}
                    />
                    {(validation.touched.acta_constitutiva && validation.errors.acta_constitutiva) && <FormFeedback type="invalid">{validation.errors.acta_constitutiva}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">CFDI</label>
                    <Input
                        accept=".pdf"
                        name="cfdi"
                        type="file"
                        className="inputAñadirInventario"
                        onChange={(e) => addFile(e, 'cfdi')}
                    />
                    {(validation.touched.cfdi && validation.errors.cfdi) && <FormFeedback type="invalid">{validation.errors.cfdi}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Regimen fiscal</label>
                    <Select
                        styles={customStyles}
                        name="fiscales.regimen_id"
                        options={selects.regimens}
                        value={validation.values.fiscales.regimen}
                        onChange={option => [validation.setFieldValue('fiscales.regimen_id', option.id), validation.setFieldValue('fiscales.regimen', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.fiscales?.regimen_id && validation.errors.fiscales?.regimen_id) && <FormFeedback type="invalid">{validation.errors.fiscales?.regimen_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Banco</label>
                    <Input
                        name="banco.banco"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.banco?.banco || ""}
                        invalid={
                            validation.touched.banco?.banco &&
                                validation.errors.banco?.banco
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.banco?.banco && validation.errors.banco?.banco) && <FormFeedback type="invalid">{validation.errors.banco?.banco}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Cuenta</label>
                    <Input
                        name="banco.cuenta"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.banco?.cuenta || ""}
                        invalid={
                            validation.touched.banco?.cuenta &&
                                validation.errors.banco?.cuenta
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.banco?.cuenta && validation.errors.banco?.cuenta) && <FormFeedback type="invalid">{validation.errors.banco?.cuenta}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">CLABE</label>
                    <Input
                        name="banco.clabe"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.banco.clabe || ""}
                        invalid={
                            validation.touched.banco?.clabe &&
                                validation.errors.banco?.clabe
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.banco?.clabe && validation.errors.banco?.clabe) && <FormFeedback type="invalid">{validation.errors.banco?.clabe}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Estado</label>
                    <Select
                        styles={customStyles}
                        name="estado_id"
                        options={selects.estados}
                        value={validation.values.fiscales.estado}
                        onChange={option => [validation.setFieldValue('fiscales.estado_id', option.id), validation.setFieldValue('fiscales.estado', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.fiscales?.estado_id && validation.errors.fiscales?.estado_id) && <FormFeedback type="invalid">{validation.errors.fiscales?.estado_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Ciudad</label>
                    <Select
                        isDisabled={!validation.values.fiscales.estado_id}
                        styles={customStyles}
                        name="municipio"
                        options={selects.municipios}
                        value={validation.values.fiscales.municipio}
                        onChange={option => [validation.setFieldValue('fiscales.municipio', option),validation.setFieldValue('fiscales.municipio_id', option.id)]}
                        getOptionLabel={(option) => `${option.no} - ${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.fisacles?.municipio && validation.errors.fisacles?.municipio) && <FormFeedback type="invalid">{validation.errors.fisacles?.municipio}</FormFeedback>}
                </div>

                <div className="col-sm-6">
                    <label className="form-label">Codigo Postal</label>
                    <AsyncSelect
                        styles={customStyles}
                        cacheOptions
                        defaultOptions
                        value={validation.values.fiscales.cp}
                        onChange={option => [validation.setFieldValue('fiscales.cp', option), validation.setFieldValue('fiscales.cp_id', option.id), validation.setFieldValue('fiscales.colonia', option.asentamiento)]}
                        loadOptions={loadOptions}
                        getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.fiscales?.cp_id && validation.errors.fiscales?.cp_id) && <FormFeedback type="invalid">{validation.errors.fiscales?.cp_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Colonia</label>
                    <Input
                        readOnly
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fiscales.colonia}
                    />

                </div>
                <div className="col-sm-6">
                    <label className="form-label">Calle</label>
                    <Input
                        name="fiscales.calle"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fiscales.calle || ""}
                        invalid={
                            validation.touched.calle &&
                                validation.errors.calle
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.calle && validation.errors.calle) && <FormFeedback type="invalid">{validation.errors.calle}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Numero ext.</label>
                    <Input
                        name="fiscales.exterior"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fiscales.exterior || ""}
                        invalid={
                            validation.touched.exterior &&
                                validation.errors.exterior
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.exterior && validation.errors.exterior) && <FormFeedback type="invalid">{validation.errors.exterior}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Numero Int.</label>
                    <Input
                        name="fiscales.interior"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fiscales.interior || ""}
                        invalid={
                            validation.touched.interior &&
                                validation.errors.interior
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.interior && validation.errors.interior) && <FormFeedback type="invalid">{validation.errors.interior}</FormFeedback>}
                </div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}></div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}
export default Step2;