import { UPDATE_SUCURSAL } from "./actionTypes";

// Función para cargar el estado desde localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('sucursalState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Función para guardar el estado en localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('sucursalState', serializedState);
  } catch {
    // Manejar errores de almacenamiento
  }
};

const initialState = loadState() || {
  sucursal: {
    nombre: '',
    email: '',
    telefono: '',
    hora_entrada: '',
    hora_salida: '',
    dia_inicial: '',
    dia_final: '',
    direccion_id: null,
    estatus_id: null,
    responsable_id: null,
    calle: '',
    cp_id: null,
    cp:null,
    colonia: '',
    ciudad_id: null
  },
};

const sucursal = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUCURSAL:
      // Actualizar el estado
      const newState = {
        ...state,
        sucursal: action.payload
      };
      // Guardar el estado en localStorage
      saveState(newState);
      return newState;
    default:
      return state;
  }
};

export default sucursal;
