import React,{useEffect,useState} from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import { codesResource } from "../../helpers/backend_helper";
const ConfirmacionModal = ({ modal, setModal,setConfirm }) => {
    const [values, setValues] = useState(['', '', '', '', '', '']);

    const handleChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
        newValues.join(''); 
    };

    const confirm = async() =>{
        let code = `${values[0]}${values[1]}${values[2]}${values[3]}${values[4]}${values[5]}`
        modal.data.code = code
        const response = await codesResource('put',modal.data,1);
        if(response.status){
            setModal({show:false})
            setConfirm(true)
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} backdrop="static">
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>{modal.title}</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <p>Ingresa el codigo de confirmación de parte del supervisor</p>
                    <div className="row">
                        {[...Array(6)].map((_, index) => (
                            <div className="col-2" key={index} style={{ textAlign: 'center' }}>
                                <input
                                    type="text"
                                    value={values[index]}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    maxLength={1}
                                    style={{ backgroundColor: '#B2BD0066', width: '45px', height: '45px', textAlign: 'center' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirm()}>
                    Confirmar
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default ConfirmacionModal