import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import "../../styles/Login.css";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//actionsRedux
import { postLoginToken, resetAccount } from "../../helpers/backend_helper"
import { Form, Input, FormFeedback } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [resetPassw, setResetPassw] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch()
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresa tu correo electronico"),
      password: Yup.string().required("Ingresa tu contraseña"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values)
      const response = await postLoginToken(values)
      console.log(response, validation)
      if (!response.isAuth) {
        switch (response.action) {
          case 'errorCode':
            console.log('errorCode')
            //redirectUserToHome({}, navigate, response.action, response.token,)
            navigate(`/verify/${response.action}/${response.token}`);
            break;
          case 'first':
            navigate(`/verify/${response.action}/${response.token}`);
            //redirectUserToHome({}, navigate, response.action, response.token,)
            //props.navigate('/');
            break;
          default:
            //setErrors(response.errors)
            break;
        }
      } else {
        console.log(response,'response')
        if (response.isAuth) {
          localStorage.setItem("authUser", JSON.stringify(response))

          navigate('/');
        }

      }
    }
  });

  const validaResetPassw = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresa tu correo electronico"),
    }),
    onSubmit: async (values, { setErrors }) => {
      const response = await resetAccount(values)
      if (response.action) {
        navigate(`/verify/${response.action}/${response.token}`);
      }
    }
  });

  return (
    <div className="LoginContainer">

      <div className="imageLoginContainer d-none d-lg-flex">
        <img src="/assets/images/imageLogin.svg" alt="Login" className="imgLogin"></img>
      </div>

      <div className="inputsLoginContainer mt-5 mb-5">
        <div className="logoContainer">
          <img src="/assets/images/icon.svg" alt="Icon"></img>
          <div className="textImgLogin"> {!resetPassw ? 'Iniciar Sesión' : '¿Olvidaste tu contraseña?'}</div>
          <div className="textImgLogin"> {!resetPassw ? '' : 'Ingresa el correo electrónico asociado a tu cuenta MKP.'}</div>
        </div>
        {!resetPassw ?
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="d-flex flex-column justify-content-center mb-3">
                <label htmlFor="inputEmail" className="text-lef text-label-login">Correo electrónico</label>
                <Input
                  name="email"
                  className="inputLogin"
                  placeholder="Ingresa tu email"
                  type="email"
                  autoComplete='off'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email ? true : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </div>

              <div className="d-flex flex-column justify-content-center text-label-login">
                <label htmlFor="inputPassword" className="text-left">Contraseña</label>

                <Input
                  name="password"
                  className="inputLogin"
                  placeholder="Ingresa tu contraseña"
                  type="password"
                  autoComplete='off'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password ? true : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                ) : null}
              </div>
              <button type="submit" className="but-log">Continuar</button>
              <a className='' href='#' onClick={() => setResetPassw(true)}>¿Olvidaste tu contraseña?</a>
            </div>
          </Form>
          :
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validaResetPassw.handleSubmit();
              return false;
            }}
          >
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="d-flex flex-column justify-content-center mb-3">
                <label htmlFor="inputEmail" className="text-lef text-label-login">Correo electrónico</label>
                <Input
                  name="email"
                  className="inputLogin"
                  placeholder="Ingresa tu email"
                  type="email"
                  autoComplete='off'
                  onChange={validaResetPassw.handleChange}
                  onBlur={validaResetPassw.handleBlur}
                  value={validaResetPassw.values.email || ""}
                  invalid={
                    validaResetPassw.touched.email && validaResetPassw.errors.email ? true : false
                  }
                />
                {validaResetPassw.touched.email && validaResetPassw.errors.email ? (
                  <FormFeedback type="invalid">{validaResetPassw.errors.email}</FormFeedback>
                ) : null}
              </div>
              <button type="submit" className="but-log">Recuperar Contraseña</button>
              <a className='' href='#' onClick={() => setResetPassw(false)}>Iniciar Sesión</a>
            </div>
          </Form>
        }
      </div>

    </div>
  );
}


export default Login;
