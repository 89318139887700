import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Asegúrate de tener instalado `@fortawesome/react-fontawesome`
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Asegúrate de tener instalado `@fortawesome/free-solid-svg-icons`
<FontAwesomeIcon icon="fa-solid fa-chevron-left" />

import "../../../styles/VerProducto.css"
import TablaDetalleCompra from '../../../components/Compras/TablaDetalleCompra';

const VerCompra = ({onClose, view, changeView}) => {


  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para manejar la actualización de los datos
    console.log(formData);
    onClose(); // Cierra el modal después de la actualización
  };

  const handleClose = () => {
    onClose();
    changeView("details")
  }

  const renderModalContent = () => {
    switch (view) {
      case 'details':
        return (
            <div className='col-12 d-flex flex-column'>
                <div className='mb-3'>
                    <h6 className="col-12 d-flex justify-content-start mx-2 fontBold400">No. Orden 2172819</h6>
                </div>
            <div className='d-flex col-12 justify-content-center flex-column align-self-center p-3'>

            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-4 ml-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Sucursal</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4 mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Responsable</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Fecha de compra*</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
            </div>
            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-4  ml-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Método de pago</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4  mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Divisa</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4 '>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Cantidad de productos</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
            </div>
            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-4  ml-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Precio compra</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4  mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Detalle de impuestos</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4 '>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Monto total</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
            </div>
            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-4  ml-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número de factura</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4  mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Estado de compra</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4 '>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Proveedor</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
            </div>
            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-4  ml-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Fecha de entrega prevista</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4  mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número de seguimiento</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
                <div className='d-flex flex-column col-12 col-lg-4 '>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Fecha de entrega real</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>
            </div>

            <div className='d-flex col-12 col-lg-11 align-self-center justify-content-center mb-2 flex-column flex-lg-row'>
                <div className='d-flex flex-column col-12 col-lg-8 mx-lg-4'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Notas/Comentarios</label>
                <input  className='inputSucursal' placeholder='escribe'/>
                </div>

                <div className='d-flex flex-column col-12 col-lg-4 mx-lg-4'>
                    <label htmlFor='inputConBoton' className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Adjuntos</label>
                    <div className='inputSucursal d-flex justify-content-between align-items-center' id='inputConBoton'>
                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                        <button className='btnVer col-3 mx-2' onClick={() => changeView('history')}>Ver</button>
                    </div>
                </div>

            </div>

            <div className='d-flex col-12 justify-content-center mb-2'>
                <div className='d-flex flex-column col-12'>
                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Resumen de compra</label>
                <TablaDetalleCompra/>
                </div>
            </div>

            </div>
                {/* <form onSubmit={handleSubmit} className="d-flex col-12 justify-content-center align-items-center">
                        <button className="mt-5 button-modal-ver-producto" onClick={() => changeView('history')}>Añadir</button>
                </form> */}
            </div>
        );
      case 'history':
        return (
            <div className='col-12 d-flex flex-column mt-1 justify-content-center align-items-center'>
                <div className='d-flex justify-content-center col-12 flex-column'>
                    <img className='imgDetalleCompra' src={`${process.env.PUBLIC_URL}/assets/images/mkp.png`}/>
                </div>

            </div>
        );
      default:
        return <div>Contenido no definido</div>;
    }
  };



  const renderModalTitle = () => {
    switch (view) {
      case 'details':
        return 'Detalle de compra'; // Título para 'details'
      case 'history':
        return 'Ver archivo adjunto No. de orden 2172819'; // Título para 'history'
      default:
        return 'Editar';
    }
  };

  const modalDialogStyle = view === 'details' ? 
    { maxWidth: '100%', width: "60em", margin: '0.5rem' } :  // Estilo para 'details'
    { maxWidth: '100em', width: "40em", margin: '0.5rem' };   // Estilo para 'history'


  return (
    <div className="modal show d-flex justify-content-center " tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered " role="document" style={modalDialogStyle}>
                <div className="modal-content contentModalDetalleCompra">
                    <div className={`d-flex justify-content-${view === "view4" ? "center" : "between"} align-items-center`}>
                        <button type="button" className="fontBold500 close p-2 mx-3" style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <span className=''>{renderModalTitle()} </span>
                        </button>
                    {view === "view4" ?
                    <></>
                    :
                    <button type="button" className="close p-2 mx-3" onClick={handleClose} style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    }
                    </div>
          <div className="modal-body">
            {renderModalContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerCompra;
