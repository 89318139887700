import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import store from "./store";
import { Provider } from 'react-redux';
import {ToastContainerHelper} from './helpers/alerts/alertHelper';

import Routes from './Auth/Routes';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store} >
    <ToastContainerHelper/>
    
    <React.StrictMode>
    
      <Routes />

      {/* aqui esta la logica de el asistente de voz */}
      {/* <App /> */}

    </React.StrictMode>
    
  </Provider>,
);

