import React from "react";
import { Link } from "react-router-dom";

const CardProveedor = ({proveedor}) => {
    return (
    <div className='card cardProveedor col-11 col-sm-6 col-md-4 col-lg-3 m-2 d-flex flex-column'>
        <div className='d-flex align-items-center mx-2 my-2'>
            <img src={`${process.env.PUBLIC_URL}/assets/images/user.jpeg`} alt="Proveedor" className="mr-3" style={{ width: '50px', height: '50px' }} />
            <p className='fontBold600 m-0'>{proveedor.nombre}</p>
        </div>
        <span className='fontBold400 mx-2'>Ciudad <p className='fontBold600 mx-2 d-inline'>{proveedor.municipio.nombre}</p></span>
        <span className='fontBold400 mx-2'>Compras <p className='fontBold600 mx-2 d-inline'>{proveedor.compras_total}</p></span>
        <span className='fontBold400 mx-2'>Última C. <p className='fontBold600 mx-2 d-inline'>{proveedor.ultima_compra}</p></span>
        <span className='fontBold400 mx-2'>T. Facturado <p className='fontBold600 mx-2 d-inline'>{proveedor.precio_facturado}</p></span>

        <Link to={`/proveedor/${proveedor.id}`} className="text-decoration-none d-flex justify-content-center align-items-center color-black buttonDetallesProveedores mx-3 mt-2 mb-2">Ver detalles</Link>
    </div>
    )
}
export default CardProveedor