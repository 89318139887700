import React, { useEffect, useState } from "react";
import { Form, Modal,Spinner, Button, Table, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getCompras_tipos, getCotizacionProveedores, proveedoresResource } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";

const Step3 = ({ modal, setModal, cotizacion, setCotizacion, setStep, productos, setProductos }) => {

    const [selects, setSelects] = useState({
        tipos: [],
        sucursales: []
    })
    const [loading, setLoading] = useState(false)
    const [selectedData, setSelectedData] = useState([]);

    const [seleccionProductos, setSeleccionProductos] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    useEffect(() => {
        const getCotizaciones = async () => {
            console.log(productos,'productos enn')
            const response = await getCotizacionProveedores(productos);
            const responseProv = await proveedoresResource('get', { props: { select: true } })
            setProveedores(responseProv.proveedores);
            let productosRespuesta = response.productos;
            productosRespuesta = productosRespuesta.map((p)=>{
                let prodFind = productos.filter((prod) => prod.id === p.id)
                console.log(prodFind,'producto')
                if(prodFind.length){
                    console.log(prodFind[0],'desíeascascascascsacs')
                    p.cantidad_solicitada = prodFind[0].cantidad_solicitada;
                    console.log(prodFind[0],'desíes')
                }
                return p;
            })
            setSeleccionProductos(productosRespuesta)
        };
        getCotizaciones();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: cotizacion,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            setCotizacion(values)
            setStep({ step: 2, name: 'Paso 2 - Selección de productos', size: 'ls' })
        },
    });

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        const value = JSON.parse(e.target.value);

        if (isChecked) {
            // Agregar los datos al arreglo selectedData
            setSelectedData([...selectedData, value]);
        } else {
            // Remover los datos del arreglo selectedData
            setSelectedData(selectedData.filter(item => {
                if (item.proveedor.id != value.proveedor.id && item.producto.id != value.producto.id) {
                    return item
                }
            }));
        }
    };

    const saveCotizacion = async () => {
        setLoading(true)
        cotizacion.productos = selectedData
        const response = await cotizacionesResource('post', cotizacion)
        console.log(response)
        if (response.status) {
            setStep({ step: 1, name: 'Paso 1 - Selección de tipo de compra', size: 'ls' })
            setCotizacion({})
            setSelectedData([])
            setModal({ show: false })
            setLoading(false)
        }
        setLoading(false)
    }
    return (

        <div className="row">
            <div className="col-sm-12"><br /></div>
            <div className="col-sm-12">
                <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                    <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                        <thead>
                            <tr>
                                <th></th>
                                {seleccionProductos.map((prod) => (
                                    <th key={prod.id} style={{ backgroundColor: 'transparent' }}>{prod.nombre}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {proveedores.length && proveedores.map((p) => (
                                <tr key={p.id}>
                                    <th style={{ backgroundColor: 'transparent' }}>{p.nombre}</th>
                                    {seleccionProductos.length ? seleccionProductos.map((prod, i) => (
                                        <th key={i} style={{ backgroundColor: 'transparent' }}>
                                            {(prod.proveedores.find(item => item.id === p.id)).producto_precio > 0 ?
                                                <Input type="checkbox"
                                                    value={JSON.stringify({ proveedor: p, producto: prod })}
                                                    onChange={handleCheckboxChange}
                                                />
                                                :
                                                <Input type="checkbox" disabled />
                                            }
                                            {(prod.proveedores.find(item => item.id === p.id)).producto_precio}
                                        </th>
                                    ))
                                        :
                                        <th></th>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>


            <div className="col-sm-6" style={{ textAlign: 'right' }}>
                
                {!loading ?
                    <>
                        <Button color="primary" block type="submit" onClick={() => saveCotizacion()}>
                            Continuar
                        </Button>{' '}
                    </>
                    :
                    <>
                        <Button className='buttonAñadirInventarioOption p-2 mx-md-2' disable={true}>
                            <Spinner size="sm">Guardando datos...</Spinner>
                            <span>{' '}Guardando datos...</span>
                        </Button>
                    </>
                }
            </div>
        </div>

    )
}

export default Step3