import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Table, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { productosResource } from "../../../../helpers/backend_helper";

const Step2 = ({ modal, setModal, auditoria, setAuditoria, setStep, setProductos, productos }) => {
    const [selects, setSelects] = useState({
        cotizaciones: []
    })

    const [producto, setProducto] = useState({})

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const [productoBase, setProductoBase] = useState({ cantidad: 0 });


    const save = async () => {
            setStep({ step: 3, name: 'Paso 3 - Hora de termino de auditoria', size: 'ls' })
    }

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };

    const AddProducto = () => {
        setProductos([...productos,producto])
    }

    return (
        <div className="row">
            <div className="col-sm-5">

                <div className="row">
                    <div className="col-sm-12">
                        <label className="form-label">Buscador de productos </label>
                        <AsyncSelect
                            styles={customStyles}
                            cacheOptions
                            defaultOptions
                            value={producto}
                            onChange={option => [setProducto(option)]}
                            loadOptions={loadOptions}
                            getOptionLabel={(option) => `${option.nombre}`}
                            getOptionValue={(option) => option.id}
                        />
                        <br />
                    </div>
                    <div className="col-sm-12">
                        <label className="form-label">Cantidad fisica del producto</label>
                        <Input
                            name="nombre"
                            className="inputAñadirInventario"
                            onChange={(e) => setProducto({ ...producto, cantidad_fisica: e.target.value })}
                            value={producto.cantidad_fisica || ""}
                        />
                    </div>
                    <div className="col-sm-8" style={{ textAlign: 'right' }}>
                        <Button color="primary" block type="submit" onClick={()=>AddProducto()}>
                            Añadir producto
                        </Button>{' '}
                    </div>
                </div>

            </div>
            <div className="col-sm-7">
                <div className="">
                    procuctos
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Unidad</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Cantidad</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: '12px' }}>
                                        {productos.map((prod, i) => (
                                            <tr key={i} >
                                                {console.log(prod)}
                                                <th style={{ backgroundColor: 'transparent' }}>{prod.nombre}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>{prod.unidad.nombre}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>{prod.cantidad_fisica}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <button className='buttonAñadirInventarioOption p-2 mx-md-3' onClick={() => save()} disabled={productos.length == 0}>Finalizar  alta</button>
                </div>
            </div>
        </div>
    )
}

export default Step2