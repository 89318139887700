import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Table, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getPago_terminos,getCompras_tipos, cpResource, sucursalesResource, estadosMunicipiosCatalogo, inventariosResource, ventasResource, clientResource,ciudadesResource} from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import Swal from 'sweetalert2'

const NewVentaModal = ({ modal, setModal, setFilters, filters }) => {
   
    const [client, setClient] = useState({})
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState(null);
    const [compra, setCompra] = useState({})
    const [step, setStep] = useState({ step: 1, name: 'Paso 1 - Selección de tipo de compra', size: 'xl' });
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: compra,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            values.productos = productos
            const response = await ventasResource('post', values)
            setModal({ show: false })
            console.log(response.status)
            if (response.status) {
                setFilters({ ...filters })
                setModal({ show: false })
            }
        },
    });

    const [selects, setSelects] = useState({
        tipos: [],
        sucursales: [],
        cotizaciones: [],
        estados: [],
        clients:[]
    })

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos, sucursales, cotizaciones, estados,clientes] = await Promise.all([
                    getPago_terminos({ params: { compras: false } }),
                    sucursalesResource('get', { params: { select: true } }),
                    cotizacionesResource('get', { params: { select: true, ventas: true } }),
                    estadosMunicipiosCatalogo({ params: { estados: true } }),
                    clientResource('get',{})
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.terminos,
                    sucursales: sucursales.sucursales,
                    cotizaciones: cotizaciones.cotizaciones,
                    estados: estados.data,
                    clients:clientes.clientes
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const loadOptionsCp = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: validation.values.estado_id, municipio_id: validation.values.municipio_id } });
            callback(options.data);
        }
    };
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 1 && validation.values.sucursal_id) {
            const options = await inventariosResource('show', { params: { search: inputValue } }, validation.values.sucursal_id);
            callback(options.inventarios);
        }
    };

    const addProd = () => {
        setProductos([...productos, producto])
        setProducto(null)
    }

    const productChange = (e, p, name = 'cantidad_solicitada') => {
        let array = productos.map((prod) => {
            if (prod.id == p.id) {
                if (name == 'cantidad_solicitada') {
                    p.cantidad_solicitada = e.target.value
                } else {
                    p.precio_unitario = e.target.value
                }

            }
            return prod
        })
        setProductos(array)
    }

    useEffect(() => {
        const getCiudadesCatalogo = async () => {
            const response = await ciudadesResource('get', { params: { estado_id: validation.values.estado_id } });
            if (response.status) {
                setSelects({
                    ...selects,
                    municipios: response.ciudades,
                });
            }
        }
        if(validation.values.estado_id){
            getCiudadesCatalogo()
        }
    }, [validation.values.estado_id]);

    const clienteSelected = (option) => {
        console.log(option)
        validation.setFieldValue('cliente_id', option.id)
        validation.setFieldValue('clienteSelected', option)
        validation.setFieldValue('nombre_cliente', option.nombre)
        validation.setFieldValue('credito_usado', option.credito)
        validation.setFieldValue('credito', '')
        validation.setFieldValue('credito_favor', '')
        validation.setFieldValue('cp', option.direccion.cp)
        validation.setFieldValue('cp.id', option.direccion.cp.id)
        validation.setFieldValue('estado', option.estado)
        validation.setFieldValue('estado_id', option.estado_id)
        validation.setFieldValue('municipio', option.municipio)
        validation.setFieldValue('municipio_id', option.municipio_id)
        validation.setFieldValue('calle', option.direccion.calle)
        validation.setFieldValue('interior', option.direccion.interior)
        validation.setFieldValue('exterior', option.direccion.exterior)

    }
    
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Nueva Venta
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();

                        validation.handleSubmit();
                        console.log(validation)
                        return false;
                    }}
                >
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <label className="form-label">Tipo de compra</label>
                                        <Select
                                            styles={customStyles}
                                            name="tipo_id"
                                            options={selects.tipos}
                                            value={validation.values.tipo}
                                            onChange={option => [validation.setFieldValue('tipo_id', option.id), validation.setFieldValue('tipo', option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Cliente registrado</label>
                                        <Select
                                            styles={customStyles}
                                            name="cliente_id"
                                            options={selects.clients}
                                            value={validation.values.clienteSelected}
                                            onChange={option => [clienteSelected(option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.cliente_id && validation.errors.cliente_id) && <FormFeedback type="invalid">{validation.errors.cliente_id}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Cliente</label>
                                        <Input
                                            name="nombre_cliente"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.nombre_cliente || ""}
                                            list="options-list"
                                            invalid={
                                                validation.touched.nombre_cliente &&
                                                    validation.errors.nombre_cliente
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <datalist id="options-list">
                                            {selects.clients.map((option, index) => (
                                                <option
                                                    key={index}
                                                    value={option.name}
                                                    onClick={() => handleSelectOption(option)}
                                                />
                                            ))}
                                        </datalist>
                                        {(validation.touched.nombre_cliente && validation.errors.nombre_cliente) && <FormFeedback type="invalid">{validation.errors.nombre_cliente}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Credito utilizado</label>
                                        <Input
                                            name="credito_usado"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.credito_usado || ""}
                                            invalid={
                                                validation.touched.credito_usado &&
                                                    validation.errors.credito_usado
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.credito_usado && validation.errors.credito_usado) && <FormFeedback type="invalid">{validation.errors.credito_usado}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Limite de credito</label>
                                        <Input
                                            name="credito"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.credito || 0}
                                            invalid={
                                                validation.touched.credito &&
                                                    validation.errors.credito
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.credito && validation.errors.credito) && <FormFeedback type="invalid">{validation.errors.credito}</FormFeedback>}
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="form-label">Número de celular</label>
                                        <Input
                                            name="celular"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.celular || ""}
                                            invalid={
                                                validation.touched.celular &&
                                                    validation.errors.celular
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.celular && validation.errors.celular) && <FormFeedback type="invalid">{validation.errors.celular}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Credito a favor</label>
                                        <Input
                                            name="credito_favor"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.credito_favor || ""}
                                            invalid={
                                                validation.touched.credito_favor &&
                                                    validation.errors.credito_favor
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.credito_favor && validation.errors.credito_favor) && <FormFeedback type="invalid">{validation.errors.credito_favor}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Credito vencido</label>
                                        <Input
                                            name="credito_vencido"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.credito_vencido || ""}
                                            invalid={
                                                validation.touched.credito_vencido &&
                                                    validation.errors.credito_vencido
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.credito_vencido && validation.errors.credito_vencido) && <FormFeedback type="invalid">{validation.errors.credito_vencido}</FormFeedback>}
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="form-label">Estado</label>
                                        <Select
                                            styles={customStyles}
                                            name="estado_id"
                                            options={selects.estados}
                                            value={validation.values.estado}
                                            onChange={option => [validation.setFieldValue('estado_id', option.id), validation.setFieldValue('estado', option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.estado_id && validation.errors.estado_id) && <FormFeedback type="invalid">{validation.errors.estado_id}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Ciudad</label>
                                        
                                        <Select
                                            isDisabled={!validation.values.estado_id}
                                            styles={customStyles}
                                            name="municipio"
                                            options={selects.municipios}
                                            value={validation.values.municipio}
                                            onChange={option => [validation.setFieldValue('municipio', option),validation.setFieldValue('municipio_id', option.id)]}
                                            getOptionLabel={(option) => `${option.no} - ${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.municipio && validation.errors.municipio) && <FormFeedback type="invalid">{validation.errors.municipio}</FormFeedback>}
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="form-label">Codigo Postal</label>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={validation.values.cp}
                                            onChange={option => [validation.setFieldValue('cp', option),validation.setFieldValue('cp_id', option.id), validation.setFieldValue('colonia', option.asentamiento)]}
                                            loadOptions={loadOptionsCp}
                                            getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.cp_id && validation.errors.cp_id) && <FormFeedback type="invalid">{validation.errors.cp_id}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Calle</label>
                                        <Input
                                            name="calle"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.calle || ""}
                                            invalid={
                                                validation.touched.calle &&
                                                    validation.errors.calle
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.calle && validation.errors.calle) && <FormFeedback type="invalid">{validation.errors.calle}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Numero ext.</label>
                                        <Input
                                            name="exterior"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.exterior || ""}
                                            invalid={
                                                validation.touched.exterior &&
                                                    validation.errors.exterior
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.exterior && validation.errors.exterior) && <FormFeedback type="invalid">{validation.errors.exterior}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className="form-label">Numero Int.</label>
                                        <Input
                                            name="interior"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.interior || ""}
                                            invalid={
                                                validation.touched.interior &&
                                                    validation.errors.interior
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.interior && validation.errors.interior) && <FormFeedback type="invalid">{validation.errors.interior}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-label">Buscador de productos </label>
                                        <AsyncSelect
                                            styles={customStyles}
                                            cacheOptions
                                            defaultOptions
                                            value={producto}
                                            onChange={option => [setProducto(option)]}
                                            loadOptions={loadOptions}
                                            getOptionLabel={(option) => `${option.producto.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        <br />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                            <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>Cantidad</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>Precio unitario</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>IEPS</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>IVA</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>Subtotal</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productos.length ? productos.map((prod, i) => (
                                                        <tr key={i}>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                {prod.nombre}
                                                            </th>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                <Input
                                                                    name="cantidad_solicitada"
                                                                    className="inputAñadirInventario"
                                                                    onChange={(e) => productChange(e, prod, 'cantidad_solicitada')}
                                                                    value={prod.cantidad_solicitada || ""}

                                                                />
                                                            </th>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                <Input
                                                                    name="precio_unitario"
                                                                    className="inputAñadirInventario"
                                                                    onChange={(e) => productChange(e, prod, 'precio_unitario')}
                                                                    value={prod.precio_unitario || ""}

                                                                />
                                                            </th>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                {prod.producto.iva}
                                                            </th>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                {prod.producto.ieps}
                                                            </th>
                                                            <th style={{ backgroundColor: 'transparent' }}>
                                                                ${(prod.cantidad_solicitada * prod.precio_unitario)+((prod.cantidad_solicitada * prod.precio_unitario)*((prod.producto.iva+prod.producto.ieps)/100))}
                                                            </th>
                                                        </tr>
                                                    )) :
                                                        <tr>

                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="form-label">Descuento.</label>
                                        <Input
                                            name="descuento"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.descuento || ""}
                                            invalid={
                                                validation.touched.descuento &&
                                                    validation.errors.descuento
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.descuento && validation.errors.descuento) && <FormFeedback type="invalid">{validation.errors.descuento}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="form-label">Motivo</label>
                                        <Input
                                            name="motivo"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.motivo || ""}
                                            invalid={
                                                validation.touched.motivo &&
                                                    validation.errors.motivo
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.motivo && validation.errors.motivo) && <FormFeedback type="invalid">{validation.errors.motivo}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-3">
                                        <br />
                                        {producto &&
                                            <Button color="primary" block onClick={() => addProd()}>
                                                Añadir producto
                                            </Button>
                                        }
                                    </div>
                                    <div className="col-sm-3">
                                        <br />
                                        <Button disabled={productos.length ? false : true} color="primary" block type="submit">
                                            Confirmar
                                        </Button>{' '}
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-3">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="form-label">Fecha vencimiento</label>
                                        <Input
                                            name="fecha_vencimiento"
                                            type="date"
                                            className="inputAñadirInventario"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.fecha_vencimiento || ""}
                                            invalid={
                                                validation.touched.fecha_vencimiento &&
                                                    validation.errors.fecha_vencimiento
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.fecha_vencimiento && validation.errors.fecha_vencimiento) && <FormFeedback type="invalid">{validation.errors.fecha_vencimiento}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-label">Sucursal</label>
                                        <Select
                                            styles={customStyles}
                                            name="sucursal_id"
                                            options={selects.sucursales}
                                            value={validation.values.sucursal}
                                            onChange={option => [validation.setFieldValue('sucursal', option), validation.setFieldValue('sucursal_id', option.id)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.sucursal_id && validation.errors.sucursal_id) && <FormFeedback type="invalid">{validation.errors.sucursal_id}</FormFeedback>}
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-label">Notas</label>
                                        <textarea
                                            style={{ width: '100%' }}
                                            name="notas"
                                            className="inputAñadirInventario" // Utilizamos la clase form-control de Bootstrap para estilos básicos de textarea
                                            rows="10" // Establecemos el número de filas que se mostrarán inicialmente en el textarea
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.notas || ""}
                                        />
                                        {(validation.touched.notas && validation.errors.notas) && <FormFeedback type="invalid">{validation.errors.notas}</FormFeedback>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Form>
                <br />
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}

export default NewVentaModal