import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback,FormGroup,Label} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { productosResource } from "../../../helpers/backend_helper";
import { ToastHelper } from "../../../helpers/alerts/alertHelper";
const ModalProducto = ({ modal, setModal, productos, setProductos }) => {
    const [error, setError] = useState({})
    const productoBase ={
        nombre: '',
        id: null,
        cantidad: '',
        solicitado: '',
        stock:false
    }
    const [producto, setProducto] = useState({...productoBase})
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };
    const save = async () => {
        const validation = productos.filter((p) => p.id === producto.id);
        let canSave = validation.length === 0;
        if (modal.update || canSave) {
            setError({});
            setProductos([...productos, producto]);
            setProducto({ ...productoBase });
            setModal({ ...modal, show: false });
        } else {
            ToastHelper('Ya tienes el producto en tu inventario','error')
        }
    }

    const changeProducto = (e) => {
        if(e.target.value <= producto.stock_disponible){
            setError({});
            setProducto({ ...producto, solicitado: e.target.value });
        }else{
            setError({solicitado:'Superaste el limite disponible en stok general'});
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>Añade tus productos</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="form-label">Buscador de productos </label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={producto}
                                onChange={option => [setProducto(option)]}
                                loadOptions={loadOptions}
                                getOptionLabel={(option) => `${option.nombre}`}
                                getOptionValue={(option) => option.id}
                            />
                            <br/>
                            
                        </div>
                        
                        {producto.id &&
                        <>
                        <div className="col-sm-4 text-center">
                            <label className="form-label">¿Hay Stock?</label>
                        </div>
                        <div className="col-sm-4">
                            <FormGroup tag="fieldset">
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        value={true}
                                        checked={producto.stock === true}
                                        onChange={() => setProducto({ ...producto, stock: true })}
                                    />
                                    {' '}
                                    <Label check>
                                        Si
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                        </div>
                        <div className="col-sm-4">                            
                            <FormGroup tag="fieldset">
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        value={false}
                                        checked={producto.stock === false}
                                        onChange={() => setProducto({ ...producto, stock: false, solicitado:0 })}
                                    />
                                    {' '}
                                    <Label check>
                                        No
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Carga la cantidad de producto que hay en stock para este almacén</label>
                            <Input
                                name="solicitado"
                                type="number"
                                disabled={!producto.stock}
                                className="inputAñadirInventario"
                                onChange={(e) => changeProducto(e)}
                                value={producto.solicitado}
                                invalid={error.solicitado ? true : false
                                }
                            />
                            {error.producto && <FormFeedback type="invalid">{error.producto}</FormFeedback>}
                            {error.solicitado && <FormFeedback type="invalid">{error.solicitado}</FormFeedback>}
                        </div>
                        </> 
                        }
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => save()}>
                    Añadir producto
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default ModalProducto;