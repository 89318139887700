import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
<FontAwesomeIcon icon="fa-solid fa-chevron-left" />

import "../../../styles/VerProducto.css"

const EliminarProveedor = ({onClose, view, changeView}) => {

  const handleClose = () => {
    onClose();
    changeView("details")
  }

  const renderModalContent = () => {
    switch (view) {
      case 'details':
        return (
            <div className='col-12 d-flex flex-column'>
                <div className='mb-3'>
                    <h5 className="col-12 d-flex justify-content-center textActualizacionVerProductos">Estas a punto de eliminar al proveedor.</h5>
                    <h5 className="col-12 d-flex justify-content-center textActualizacionVerProductos">¿Estás seguro de la acción? Es un proceso irreversible</h5>
                </div>
                <div className="d-flex justify-content-center align-items-center col-12">
                        <button className="mt-3 fontBold500 button-modal-eliminacionRegresar col-4" onClick={handleClose}>Cancelar</button>
                        <button className="mt-3 fontBold500 button-modal-eliminacion col-4 mx-2" onClick={() => changeView('history')}>Continuar</button>
                </div>
            </div>
        );
      case 'history':
        return (
            <div className='col-12 d-flex flex-column mt-1 justify-content-center align-items-center'>
                <div className='d-flex justify-content-center col-12 flex-column p-1'>
                    <div className='d-flex justify-content-start col-12 mx-2 mb-3'>
                        <label className='razonEliminacion'>Ingresa tu contraseña de acceso al sistema</label>
                    </div>
                    <div className="d-flex justify-content-center input-group col-7">
                    <input type="text" className="col-8 inputSalidaPassword" />
                </div>
                </div>
                <div className="d-flex justify-content-center align-items-center col-12">
                        <button className="mt-3 fontBold500 button-modal-eliminacion col-7" onClick={() => changeView('view3')} >Continuar</button>
                </div>
            </div>
        );
      case 'view3':
        return (
            <>
            <div className='d-flex justify-content-start col-12 mx-2 mb-3'>
                            <label className='razonEliminacion'>Ingresar código de confirmación de parte del supervisor</label>
                                </div>
                                <div className='col-12 d-flex flex-column mt-4'>
                                    <div className='col-12 d-flex justify-content-center'>
                                <input className='col-2 inputGuardarCambios'/>
                                <input className='col-2 mx-2 inputGuardarCambios'/>
                                <input className='col-2 inputGuardarCambios'/>
                                <input className='col-2 mx-2 inputGuardarCambios'/>
                                <input className='col-2 mx-2 inputGuardarCambios'/>
                                <input className='col-2 mx-2 inputGuardarCambios'/>
                            </div>
                        <span className='textSolicitar mt-4'>Solicitar código de confirmación de supervisor vía correo</span>
                        <div className="d-flex justify-content-center align-items-center col-12">
                        <button className="mt-5 button-modal-ver-producto col-6" onClick={() => changeView('view4')} >Confirmar eliminacion</button>
                   </div>
            </div>
            </>
        );
      case 'view4':
        return (
            <div className='col-12 d-flex flex-column mt-1 justify-content-center align-items-center'>
                <div className="d-flex justify-content-center align-items-center col-12">
                    <button className="mt-3 fontBold500 button-modal-eliminacion col-7" onClick={handleClose}>Aceptar</button>
                </div>
            </div>
        );
      default:
        return <div>Contenido no definido</div>;
    }
  };

  const renderModalTitle = () => {
    switch (view) {
      case 'details':
        return 'Eliminar proveedor'; 
      case 'history':
        return 'Confirma movimiento';
      case 'view3':
        return 'Confirmar movimiento'; 
      case 'view4':
        return 'Se ha eliminado y aprobado con éxito'; 
      default:
        return 'Editar';
    }
  };

  const modalDialogStyle = view === 'details' ? 
    { maxWidth: '100%', width: "40em", margin: '0.5rem' } : 
    { maxWidth: '100em', width: "40em", margin: '0.5rem' };  


  return (
    <div className="modal show d-flex justify-content-center" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered" role="document" style={modalDialogStyle}>
                <div className="modal-content">
                    <div className={`d-flex justify-content-${view === "view4" ? "center" : "between"} align-items-center`}>
                        <button type="button" className="fontBold500 close p-2 mx-3" style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <span className=''>{renderModalTitle()} </span>
                        </button>
                    {view === "view4" ?
                    <></>
                    :
                    <button type="button" className="close p-2 mx-3" onClick={handleClose} style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    }
                    </div>

          <div className="modal-body">
            {renderModalContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EliminarProveedor;
