import React, { useState } from 'react'
import Login from '../Screens/Auth/Login'
import TwoStep from '../Screens/Auth/TwoStep'
import { BrowserRouter, Route as Ruta, Routes as Rutas } from 'react-router-dom'
import Dashboard from '../Screens/Dashboard'
import Inventarios from '../Screens/Inventarios/Inventarios'
import NewInventario from '../Screens/Inventarios/NewInventario'
import Compras from '../Screens/Compras/Compras'
import Main from '../Auth/Main'
import Layout from '../Auth/Layout' // Asegúrate de importar el nuevo componente Layout
//ciudades
import DetalleCiudad from '../Screens/Ciudades/DetalleCiudad'
import NuevaCiudad from '../Screens/Ciudades/NuevaCiudad'
//sucursales
import DetalleSucursal from '../Screens/Sucursales/DetalleSucursal'
import NuevaSucursal from '../Screens/Sucursales/NuevaSucursal'
import PrimerInventario from '../Screens/Sucursales/PrimerInventario'
import { connect } from 'react-redux';
import Ventas from '../Screens/Ventas/Ventas'
import Proveedor from '../Screens/Proveedores/Proveedor'
import Auditorias from '../Screens/Inventarios/Auditorias/Auditorias'
//resp
import Respuesta from '../Screens/Cotizaciones/Respuesta'

export const Routes = () => {
  const [title,setTitle] = useState('');
  return (
    <BrowserRouter>
      <Rutas>
        <Ruta path="/login/:action?/:estatus?" element={<Login />} />
        <Ruta path="/verify/:action/:token?" element={<TwoStep />} />
        <Ruta path="/respuesta/:token/:id" element={<Respuesta />} />
        <Ruta path="/" element={<Layout title="Home"><Dashboard /></Layout>} />
        
        <Ruta path="/inventarios" element={<Layout title="Inventarios"><Inventarios /></Layout>} />
        <Ruta path="/inventarios/agregar" element={<Layout title="Añadir Inventario" localizacion="añadirInventario"><NewInventario /></Layout>} />
        {/*Ciudades*/}
        <Ruta path="/ciudad/nueva" element={<Layout title="Nueva ciudad" localizacion="Nueva_sucursal"><NuevaCiudad /></Layout>} />
        <Ruta path="/ciudad/:id" element={<Layout title={title} localizacion="detalle"><DetalleCiudad setTitle={setTitle}/></Layout>} />
        {/*Sucursales*/}
        <Ruta path="/sucursal/nueva" element={<Layout title="Crear nueva sucursal" localizacion="Nueva_sucursal"><NuevaSucursal /></Layout>} />
        <Ruta path="/sucursal/nueva/:id/:token" element={<Layout title="Crear nueva sucursal" localizacion="Nueva_sucursal"><PrimerInventario /></Layout>} />
        <Ruta path="/sucursal/:id" element={<Layout title={title} localizacion="sucursal"><DetalleSucursal setTitle={setTitle}/></Layout>} />
        <Ruta path="/ventas" element={<Main><Ventas /></Main>} />
        <Ruta path="/compras" element={<Main><Compras /></Main>} />
        <Ruta path="/Auditorias" element={<Main><Auditorias /></Main>} />
        {/*Proveedores*/}
        <Ruta path="/Proveedor/:id" element={<Main><Proveedor /></Main>} />
        {/* Más rutas que requieran el Sidebar y el Title */}
      </Rutas>
    </BrowserRouter>
  );
};

export default Routes;
