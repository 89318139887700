import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ChartBarras from '../../components/HOME/ChartBarras';
import Filtros from '../../components/INVENTARIOS/Filtros';
import { ciudadesResource, sucursalesResource } from '../../helpers/backend_helper'

const DetalleCiudad = ({setTitle}) => {
  const { id } = useParams();
  const [ciudad, setCiudad] = useState({
    stock_total: 0,
    precio_total: 0
  });
  const [sucursales, setSucursales] = useState([]);
  const location = useLocation();
  const [mostrarSoloCardsPequeñas, setMostrarSoloCardsPequeñas] = useState(false);

  useEffect(() => {

    const getRecursos = async () => {
      const [getSucursales, getCiudad] = await Promise.all([
        sucursalesResource('get', { params: { ciudad_id: id } }),
        ciudadesResource('show', {}, id)
      ]);
      setSucursales(getSucursales.sucursales)
      setCiudad(getCiudad.ciudad)
      setTitle(getCiudad.ciudad.nombre)
    }
    getRecursos()
  }, []);

  const toggleMostrarSoloCardsPequeñas = () => {
    setMostrarSoloCardsPequeñas(!mostrarSoloCardsPequeñas);
  };


  // Restablece mostrarSoloCardsPequeñas al salir del componente
  useEffect(() => {
    return () => setMostrarSoloCardsPequeñas(false);
  }, []);
  // Ahora puedes usar cardData para renderizar los detalles
  return (
    <div>
      {!mostrarSoloCardsPequeñas && (
        <>
          <Filtros detalle={"ciudad"} ciudad={ciudad} />
          <h4 className='p-4'>Informes</h4>
          <div className='d-flex flex-column flex-lg-row col-12'>
            <div className='d-flex flex-column col-12 col-lg-7'>
              <div className='col-12  mt-4'>
                <div className="table-responsive">
                  <table className="table">
                    <thead className='text-center'>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Operacion en</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Producto(s)</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Ing/Egreso</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      <tr>
                        <td>08/08/23</td>
                        <td>Sucursal Norte</td>
                        <td>Entrada</td>
                        <td>Fertilizantes</td>
                        <td>24</td>
                        <td>- $103,492</td>
                        <td><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/eye.svg`}></img></td>
                      </tr>
                      {/* Puedes agregar más filas aquí */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='col-12 d-flex flex-column flex-lg-row'>
                <h4 className='p-4'>Sucursales ({sucursales.length})
                  <button onClick={toggleMostrarSoloCardsPequeñas} className='buttonOptionSucursalIndividualVer mx-5 w-auto' >Ver todos</button>
                </h4>
              </div>



              {/* AQUI VAN LAS CARDS */}
              <div className='col-12 d-flex flex-column flex-lg-row'>
                {sucursales.map((sucursal) => (
                  <Link key={sucursal.id} className='cardCiudad text-start  col-12 col-lg-3 ms-lg-4 text-dark text-decoration-none' to={`/sucursal/${sucursal.id}`}>
                    <h6 className='p-1 titleCiudadCardSpace mt-2' style={{ fontSize: "0.9em" }}></h6>
                    <h6 className='p-1 titleCiudadCard mt-3 text-center font-weight-bold' style={{ fontSize: "0.9em" }}>{sucursal.nombre}</h6>
                    <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}><span className='descriptionCardCiudad'>{`${sucursal.direccion.calle} ${sucursal.direccion.exterior}, ${sucursal.colonia}, ${sucursal.cp} ${sucursal.municipio}, ${sucursal.estado}`}</span></h6>
                    <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Responsable: <span className='descriptionCardCiudad'>{'nombre de responsable'}</span></h6>
                    <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Estado: <span className='descriptionCardCiudad'>{sucursal.estatus.nombre}</span></h6>
                    <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Fecha de creación: <span className='descriptionCardCiudad'> {sucursal.created_at}  </span></h6>
                    <div className='containerOptionSucursalIndividual col-12 d-flex justify-content-center align-items-center'>
                      <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/eye.svg`}></img></button>
                      <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/edit.svg`}></img></button>
                      <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/trash.svg`}></img></button>
                    </div>
                    <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
                  </Link>
                ))
                }

              </div>

              <h4 className='mb-5'></h4>

            </div>

            <div className='col-12 col-lg-4 mb-5 mb-lg-0'>
              <ChartBarras />
            </div>


          </div>
        </>
      )}

      {mostrarSoloCardsPequeñas && (
        <>
          <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>

          {/* AQUI VAN LAS CARDS */}
          <div className='col-12 d-flex flex-column flex-lg-row'>
            <Link className='cardCiudad text-start  col-12 col-lg-2 ms-lg-4 text-dark text-decoration-none' to={"/inventarios/sucursal"}>
              <h6 className='p-1 titleCiudadCardSpace mt-2' style={{ fontSize: "0.9em" }}></h6>
              <h6 className='p-1 titleCiudadCard mt-3 text-center font-weight-bold' style={{ fontSize: "0.9em" }}>Sucursal Sur</h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}><span className='descriptionCardCiudad'>Av. Patria 1045, Echeverría, 44970 Guadalajara, Jal.</span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Responsable: <span className='descriptionCardCiudad'>Responsable: Luis Galvan </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Estado: <span className='descriptionCardCiudad'>Estado Activo   </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Fecha de creación: <span className='descriptionCardCiudad'> 08/08/23   </span></h6>
              <div className='containerOptionSucursalIndividual col-12 d-flex justify-content-center align-items-center'>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/eye.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/edit.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/trash.svg`}></img></button>
              </div>
              <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
            </Link>

            <Link className='cardCiudad text-start  col-12 col-lg-2 ms-lg-4 text-dark text-decoration-none' to={"/inventarios/sucursal"}>
              <h6 className='p-1 titleCiudadCardSpace mt-2' style={{ fontSize: "0.9em" }}></h6>
              <h6 className='p-1 titleCiudadCard mt-3 text-center font-weight-bold' style={{ fontSize: "0.9em" }}>Sucursal Sur</h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}><span className='descriptionCardCiudad'>Av. Patria 1045, Echeverría, 44970 Guadalajara, Jal.</span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Responsable: <span className='descriptionCardCiudad'>Responsable: Luis Galvan </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Estado: <span className='descriptionCardCiudad'>Estado Activo   </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Fecha de creación: <span className='descriptionCardCiudad'> 08/08/23   </span></h6>
              <div className='containerOptionSucursalIndividual col-12 d-flex justify-content-center align-items-center'>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/eye.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/edit.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/trash.svg`}></img></button>
              </div>
              <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
            </Link>

            <Link className='cardCiudad text-start col-12 col-lg-2 ms-lg-4 text-dark text-decoration-none' to={"/inventarios/sucursal"}>
              <h6 className='p-1 titleCiudadCardSpace mt-2' style={{ fontSize: "0.9em" }}></h6>
              <h6 className='p-1 titleCiudadCard mt-3 text-center font-weight-bold' style={{ fontSize: "0.9em" }}>Sucursal Sur</h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}><span className='descriptionCardCiudad'>Av. Patria 1045, Echeverría, 44970 Guadalajara, Jal.</span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Responsable: <span className='descriptionCardCiudad'>Responsable: Luis Galvan </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Estado: <span className='descriptionCardCiudad'>Estado Activo   </span></h6>
              <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Fecha de creación: <span className='descriptionCardCiudad'> 08/08/23   </span></h6>
              <div className='containerOptionSucursalIndividual col-12 d-flex justify-content-center align-items-center'>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/eye.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/edit.svg`}></img></button>
                <button className='buttonOptionSucursalIndividual mx-3 d-flex justify-content-center align-items-center'><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/trash.svg`}></img></button>
              </div>
              <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
            </Link>
          </div>

          <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>

        </>
      )}



    </div>
  );
};

export default DetalleCiudad;
