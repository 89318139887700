import React from 'react'

const TablaDetalleEvaluacion = () => {

  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
    <div className="table-responsive table-responsive-sucursales mi-tabla-scroll">
        <table className="table table-sucursales">
        <thead className='text-start table-thead'>
            <tr className='' style={{color: "white"}}>
            <th scope="col"></th>
            <th scope="col">Criterio</th>
            <th scope="col">Evaluación</th>
            <th scope="col ">Ponderación</th>
            </tr>
        </thead>
        <tbody className='text-start table-tbody'>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
            <tr>
            <td className='d-flex justify-content-center'>
                <button className='col-6 buttonOptionInvetarioInterno'><img src={`${process.env.PUBLIC_URL}/assets/images/admiracion.svg`}/></button>
              </td>
              <td>Calidad</td>
              <td>Cumple</td>
              <td>30%</td>
            </tr>
        </tbody>
        </table>
    </div>
</div>
  )
}

export default TablaDetalleEvaluacion;