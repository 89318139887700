import React from 'react'

const TablaInventario = ({  inventarios,modalDetalleProd, setModalDetalleProd }) => {

    return (
        <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
            <div className="table-responsive table-responsive-sucursales">
                <table className="table table-sucursales">
                    <thead className='text-center'>
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Categoria</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Últ. act. stock</th>
                            <th scope="col">Caducidad</th>
                            <th scope="col">Tipo M.</th>
                            <th scope="col">P. compra</th>
                            <th scope="col">Fecha C.</th>
                            <th scope="col">Precio Venta</th>
                            <th scope="col">Últ. compra</th>
                            <th scope="col">P. Entras</th>
                            <th scope="col">Rdto.</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {inventarios.length ? inventarios.map((p) => (
                            <tr className='' key={p.id} onClick={()=>setModalDetalleProd({show:true,producto_id:p.id})}>
                                <td><span>{p.producto.sku}</span><img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/producto.svg`} /></td>
                                <td>{p.producto.nombre}</td>
                                <td>{p.categoria}</td>
                                <td>{p.unidad_medida}</td>
                                <td>{p.cantidad}</td>
                                <td>{p.updated_at}</td>
                                <td>{p.caducidad}</td>
                                <td>{p.divisa}</td>
                                <td>{p.costo}</td>
                                <td>{p.fecha_compra}</td>
                                <td>{p.producto.precio}</td>
                                <td>{p.fecha_compra}</td>
                                <td>{p.p_entra}</td>
                                <td style={{color:p.producto.precio-p.costo>0 ?'green':'red'}}>{p.producto.precio-p.costo}</td>
                            </tr>

                        ))
                            :
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={16} style={{ textAlign: 'center' }}>
                                    No hay registros para mostrar
                                </th>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TablaInventario