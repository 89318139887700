import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getCompras_tipos } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";

const Step1 = ({ modal, setModal, cotizacion, setCotizacion, setStep }) => {
    const [selects, setSelects] = useState({
        tipos: [],
        sucursales: []
    })
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos, sucursales] = await Promise.all([
                    getCompras_tipos({params:{compras:true}}),
                    sucursalesResource('get',{params:{select:true}})
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.compraTipos,
                    sucursales: sucursales.sucursales
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: cotizacion,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            setCotizacion(values)
            setStep({step:2,name:'Paso 2 - Selección de productos',size:'ls'})
        },
    });
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Tipo de compra</label>
                    <Select
                        styles={customStyles}
                        name="tipo_id"
                        options={selects.tipos}
                        value={validation.values.tipo}
                        onChange={option => [validation.setFieldValue('tipo_id', option.id), validation.setFieldValue('tipo', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Sucursal que solicita la cotización</label>
                    <Select
                        styles={customStyles}
                        name="sucursal_id"
                        options={selects.sucursales}
                        value={validation.values.sucursal}
                        onChange={option => [validation.setFieldValue('sucursal', option),validation.setFieldValue('sucursal_id', option.id)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.sucursal_id && validation.errors.sucursal_id) && <FormFeedback type="invalid">{validation.errors.sucursal_id}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Nombre del requerimiento</label>
                    <Input
                        name="nombre_requerimiento"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nombre_requerimiento || ""}
                        invalid={
                            validation.touched.nombre_requerimiento &&
                                validation.errors.nombre_requerimiento
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.nombre_requerimiento && validation.errors.nombre_requerimiento) && <FormFeedback type="invalid">{validation.errors.nombre_requerimiento}</FormFeedback>}
                </div>
                
                
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step1