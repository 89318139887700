import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Step5 from "./Step5";

const AddProductos = ({ modal, setModal,proveedor,setFilters,filters }) => {
    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={'lg'}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Añadir proveedor
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            Añadir productos
                        </div>
                        <div className="col-sm-12">
                            <Step5  getBase64={getBase64} proveedor={proveedor}  modal={modal} setModal={setModal} setStep={setFilters}filters={filters}/>
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </Modal>
    )
}
export default AddProductos