import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getCompras_tipos,getProductCotizacion } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";



const Step1 = ({ modal, setModal, compra, setCompra, setStep,setProductos,productos }) => {
    const [selects, setSelects] = useState({
        cotizaciones: []
    })
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [cotizaciones, sucursales] = await Promise.all([
                    cotizacionesResource('get',{params:{select:true}})
                ]);

                setSelects({
                    ...selects,
                    cotizaciones: cotizaciones.cotizaciones
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: compra,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            setCompra(values)
            setStep({step:2,name:'Paso 2 - Selección de productos',size:'xl'})
        },
    });

    const getProductos = async (option) => {
        let products = await getProductCotizacion(option.id);
        
        validation.setFieldValue('cotizacion', option)
        validation.setFieldValue('cotizacion_id', option.id)
        validation.setFieldValue('proveedor', option.proveedor)
        validation.setFieldValue('proveedor_id', option.proveedor.id)
        setProductos(products.productos)
    }
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Fecha de compra</label>
                    <Input
                        name="fecha_compra"
                        type="date"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fecha_compra || ""}
                        invalid={
                            validation.touched.fecha_compra &&
                                validation.errors.fecha_compra
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.fecha_compra && validation.errors.fecha_compra) && <FormFeedback type="invalid">{validation.errors.fecha_compra}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Numero de cotizacion</label>
                    <Select
                        styles={customStyles}
                        name="cotizacion_id"
                        options={selects.cotizaciones}
                        value={validation.values.cotizacion}
                        onChange={option => getProductos(option)}
                        getOptionLabel={(option) => `${option.id}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.cotizacion_id && validation.errors.cotizacion_id) && <FormFeedback type="invalid">{validation.errors.cotizacion_id}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Proveedor</label>
                    <Input
                    readOnly
                        name="proveedor_id"
                        className="inputAñadirInventario"
                        value={validation.values.proveedor?.nombre || ""}
                    />
                </div>
                
                
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step1