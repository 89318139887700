import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { ButtonGroup } from 'reactstrap';

const ColumnsImport = (editProduct) => {
    let columns = [
        {
            id: 'nombre',
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            id: 'categoria_id',
            name: 'Categoria',
            selector: row => row.categoria_id,
        },
        {
            id: 'cantidad',
            name: 'Cantidad',
            selector: row => row.cantidad,
        },
        {
            id: 'actions',
            name: 'Actions',
            cell: row => (
                <div className="d-flex justify-content-between">
                    <div className="p-2">
                        <ButtonGroup>
                            <a className='warning' href='#' onClick={() => editProduct(row)}><FaPencilAlt style={{ fontSize: '0.75rem' }} /></a>
                        </ButtonGroup>
                    </div>

                </div>
            ),
        }
    ];
    return columns;
}

export default ColumnsImport;