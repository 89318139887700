import React, { useState, useEffect } from 'react'
import "../../styles/Sucursales.css"
import FormDetalle from './FormDetalle'
import { sucursalesResource, inventariosResource, getEstatus, movimientosResource } from '../../helpers/backend_helper'
import { useParams, useNavigate } from 'react-router-dom'
import { detalle_sucursal, update_title } from '../../store/titles/action'
import { connect } from 'react-redux';
import { Input, Form, FormFeedback, Label, FormGroup } from 'reactstrap'
import Select from "react-select";
import Insumos from './modals/Insumos'
import ModalMovimientos from './modals/ModalMovimientos'
import TablaHistorial from './tabs/TablaHistorial';
import TablaInventario from './tabs/TablaInventario';
import TablaCosteo from './tabs/TablaCosteo';
import DetalleProducto from '../Inventarios/DetalleProducto'
import TablaInventarioInterno from './tabs/TablaInventarioInterno';
import { tab } from '@testing-library/user-event/dist/tab'

const DetalleSucursal = ({ setTitle, dataSucursal, dispatch }) => {
  const pagInateOptions = [
    { label: 'Todos', value: '' },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  const [modalDetalleProd,setModalDetalleProd] = useState({show:false,producto:{}});
  const [tabActiva, setTabActiva] = useState('inventario');
  const [selectedPerPage, setSelectedPerPage] = useState(pagInateOptions[0])
  const [selectedEstatus, setSelectedEstatus] = useState(null)
  const [movimientos, setMovimientos] = useState([])
  const [estatus, setEstatus] = useState([])
  const navigate = useNavigate();
  const { id } = useParams();
  const [filters, setFilters] = useState({ per_page: 20, page: 1, search: '', interno: true, tipo_id: 1, estatus_id: null })
  const [inventarios, setInventarios] = useState([]);
  const [sucursal, setSucursal] = useState({ id: null })
  const [botonActivo, setBotonActivo] = useState('Inventario');
  const [modalInsumos, setModalInsumos] = useState({ show: false, sucursal_id: id })
  const [modalMovimiento, setModalMovimiento] = useState({ show: false, sucursal_id: id })
  const [update, setUpdate] = useState(false);
  const manejarClicBoton = (boton) => {
    setBotonActivo(boton);
  };

  useEffect(() => {
    if (dataSucursal.insumos) {
      setModalInsumos({ ...modalInsumos, show: true })
    } else {
      setModalInsumos({ ...modalInsumos, show: false })
    }
  }, [dataSucursal.insumos]);

  useEffect(() => {
    console.log(dataSucursal)
    if (dataSucursal.movimiento) {
      setModalMovimiento({ ...modalMovimiento, show: true })
    } else {
      setModalMovimiento({ ...modalMovimiento, show: false })
    }
  }, [dataSucursal.movimiento]);

  useEffect(() => {
    const getRecursos = async () => {
      const [getSucursales, estatus] = await Promise.all([
        sucursalesResource('show', {}, id),
        getEstatus({ params: { model: 'Movimiento' } })
      ]);
      if (getSucursales.status) {
        setEstatus(estatus.estatus)
        setSucursal(getSucursales.sucursal)
        setTitle(`${getSucursales.sucursal.estado} / ${getSucursales.sucursal.nombre}`)
      } else {
        navigate('/')
      }
    }
    getRecursos()
  }, []);

  useEffect(() => {
    const getInventarios = async () => {
      const response = await inventariosResource('show', { params: filters }, id);
      if (response.status) {
        setInventarios(response.inventarios)
      }
    }

    const getInformes = async () => {
      const response = await movimientosResource('show', { params: filters }, id);
      if (response.status) {
        setMovimientos(response.movimientos)
      }
    }

    if (tabActiva == 'informes') {
      getInformes()
    } else {
      getInventarios()
    }

  }, [filters, tabActiva]);

  return (
    <>

      {/* // formulario y mapa */}
      {sucursal.id &&
        <FormDetalle setUpdate={setUpdate} sucursal={sucursal} setSucursal={setSucursal} tipo_cambio={20} />
      }
      {/* seleccionadores */}
      <div className='col-12 d-flex mt-4'>

        <div className='d-flex col-6'>
          <button
            className={`buttonSucursalesTable p-5 ${tabActiva === 'inventario' ? 'buttonSucursalesTableActive' : ''}`}
            onClick={() => [setTabActiva('inventario'), setFilters({ ...filters, tipo_id: 1 })]}
          >
            Inventario
          </button>
          <button
            className={`buttonSucursalesTable p-4 ${tabActiva === 'interno' ? 'buttonSucursalesTableActive' : ''}`}
            onClick={() => [setTabActiva('interno'), setFilters({ ...filters, tipo_id: 2 })]}
          >
            Inventario interno
          </button>
          <button
            className={`buttonSucursalesTable p-4 ${tabActiva === 'informes' ? 'buttonSucursalesTableActive' : ''}`}
            onClick={() => [setTabActiva('informes')]}
          >
            Informes
          </button>
        </div>

        <div className='optionSucursales col-10 col-lg-6 d-flex flex-column flex-md-row align-items-center justify-content-center flex-wrap mt-2 mt-lg-1'>
          <div className='d-flex align-items-center mx-2'>
            <button className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>Excel</span></button>
          </div>
          <div className='d-flex align-items-center mx-2'>
            <button className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>PDF</span></button>
          </div>
          {tabActiva == 'informes' &&
            <div className='d-flex align-items-center mx-2'>
              <Select
                placeholder='Estatus'
                name="estatus_id"
                options={estatus} // Opciones para el Select
                value={selectedEstatus}
                getOptionLabel={(option) => `${option.nombre}`}
                getOptionValue={(option) => option.id}
                onChange={option => [setFilters({ ...filters, estatus_id: option.id }), setSelectedEstatus(option)]}
              />
            </div>
          }
          <div className='d-flex align-items-center mx-2'>
            <Select
              name="categoria_id"
              options={pagInateOptions} // Opciones para el Select
              value={selectedPerPage}
              onChange={option => [setFilters({ ...filters, per_page: option.value }), setSelectedPerPage(option)]}
            />
          </div>

          <div className='d-flex align-items-center mx-2'>
            <Input className='button-option-filtros d-flex align-items-center justify-content-between' placeholder='Buscador' onChange={((e) => setFilters({ ...filters, search: e.target.value }))} />
          </div>
        </div>
      </div>

      {/* tabla */}
      <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
        <div className="table-responsive table-responsive-sucursales">
          {tabActiva == 'inventario' &&
            <TablaInventario inventarios={inventarios} modalDetalleProd={modalDetalleProd} setModalDetalleProd={setModalDetalleProd}/>
          }
          {tabActiva == 'interno' &&
            <TablaInventarioInterno inventarios={inventarios} modalDetalleProd={modalDetalleProd} setModalDetalleProd={setModalDetalleProd}/>
          }
          {tabActiva == 'informes' &&
            <TablaHistorial setFilters={setFilters} filters={filters} movimientos={movimientos} sucursal={sucursal} />
          }
        </div>
        <Insumos modal={modalInsumos} setModal={setModalInsumos} />
        {sucursal.id &&
          <ModalMovimientos modal={modalMovimiento} setModal={setModalMovimiento} sucursal={sucursal} dispatch={dispatch} />
        }
        <br /><br />
        {sucursal.id &&
          <TablaCosteo sucursal={sucursal} />
        }
        {modalDetalleProd.show &&
          <DetalleProducto modal={modalDetalleProd} setModal={setModalDetalleProd} sucursal={sucursal}/>
        }
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  dataSucursal: state.Titles.detalle_sucursal
});
export default connect(mapStateToProps)(DetalleSucursal);