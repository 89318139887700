import React, { useState, useEffect, useRef } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomSelect from "../../components/SELECT/CustomSelect"
import { FormGroup, Label, Spinner, Form, Input, FormFeedback, Button } from 'reactstrap';
import { getCategorias, getUnidad_medidas, divisasResourse, proveedoresResource, getProducto_tipos, getEstatus, sucursalesResource, cotizacionesResource, inventariosResource } from "../../helpers/backend_helper"
import { ToastHelper, ToastContainerHelper } from '../../helpers/alerts/alertHelper';
import ExcelJS from 'exceljs';
import DataTableOnlyHelper from '../../components/CommonForBoth/tables/DataTableOnlyHelper'
import ColumnsImport from './ColumnsImport'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const NewInventario = () => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [spinnerElement, setSpinnerElement] = useState(false);
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const workbook = new ExcelJS.Workbook();
        try {
            await workbook.xlsx.load(file);
            const worksheet = workbook.getWorksheet(1);
            const data = [];
            // Obtener los nombres de las columnas
            const headers = [
                '',
                'nombre',
                'descripcion',
                'categoria_id',
                'precio',
                'costo_proveedor',
                'cantidad',
                'unidad_medida_id',
                'divisa_id',
                'fecha_compra',
                'fecha_elaboracion',
                'caducidad',
                'sku',
                'proveedor_id',
                'rfc',
                'tipo_id',
                'activo',
                'estatus_id',
                'ieps',
                'iva',
            ];
            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
                if (rowNumber !== 1) {
                    const rowData = {};
                    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                        rowData[headers[colNumber]] = cell.value;
                    });
                    rowData.excel = true
                    data.push(rowData);
                }
            });
            setInventarios(data)
        } catch (error) {
            console.error('Error al cargar el archivo de Excel:', error);
        }
    };

    const [inventarios, setInventarios] = useState([]);
    
    const [selects, setSelects] = useState({
        categorias: [],
        unidad_medidas: [],
        divisas: [],
        proveedores: [],
        producto_tipos: [],
        estatus: [],
        sucursales: [],
        cotizaciones: [],
        ivas: [
            { label: 'Excento', value: null },
            { label: '0%', value: 0 },
            { label: '16%', value: 16 },
        ],
        ieps: [
            { label: 'Excento', value: null },
            { label: '6%', value: 6 },
            { label: '7%', value: 7 },
            { label: '9%', value: 9 }
        ],
    });

    const productoBase = {
        nombre: '',
        descripcion: '',
        categoria_id: null,
        precio: '',
        costo_proveedor: '',
        cantidad: '',
        unidad_medida_id: null,
        divisa_id: '',
        fecha_compra: '',
        fecha_elaboracion: '',
        sku: '',
        proveedor_id: null,
        imagen: '',
        tipo_id: '',
        transpaso: false,
        sucursal_origen_id: null,
        sucursal_destino_id: null,
        cantidad_transpaso: '',
        ivaSelect:null,
        iepsSelect:null,
        activoSelect:null
    };

    const [producto, setProducto] = useState({ ...productoBase });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: producto,
        validationSchema: Yup.object({
            nombre: Yup.string().required('El nombre es requerido'),
            descripcion: Yup.string().required('La descripción es requerida'),
            categoria_id: Yup.number().required('La categoría es requerida'),
            precio: Yup.number().required('El precio es requerido'),
            costo_proveedor: Yup.number().required('El costo del proveedor es requerido'),
            cantidad: Yup.number().required('La cantidad es requerida'),
            unidad_medida_id: Yup.number().required('La unidad de medida es requerida'),
            divisa_id: Yup.number().required('La divisa es requerida'),
            fecha_compra: Yup.date().required('La fecha de compra es requerida'),
            fecha_elaboracion: Yup.date().required('La fecha de elaboración es requerida'),
            sku: Yup.string().required('El SKU es requerido'),
            proveedor_id: Yup.number().required('El proveedor es requerido'),
            tipo_id: Yup.number().required('El tipo es requerido'),
            transpaso: Yup.boolean(),
            sucursal_origen_id: Yup.number().nullable(),
            sucursal_destino_id: Yup.number().nullable(),
            cantidad_transpaso: Yup.number().nullable()
        }),
        onSubmit: async (values, { setErrors }) => {
            let validate = inventarios.filter(i => i.sku === values.sku)
            if (validate.length == 0) {
                values.excel = false
                setInventarios([...inventarios, values])
                validation.resetForm();
            } else {
                ToastHelper('Error ya tienes un producto con ese SKU. Asigna otro codigo de producto (SKU)', 'error')
                setErrors({ sku: 'Ya hay algun producto con este Codigo de producto' })
                console.log('error')
            }
        },
    });
    const getBase64File = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            validation.setFieldValue('imagen', base64String)
        };
        if (file) {
            reader.readAsDataURL(file); // Lee el contenido del archivo como una URL de datos
        }
    };

    const saveInventario = async () => {
        setSpinnerElement(true)
        const response = await inventariosResource('post', inventarios);
        if (response.status) {
            navigate(`/inventarios/`);
        } else {
            if (response.error) {
                console.log(response.error, 'errprde')
            } else {
                //const arrayErrors = Object.keys(response.response.data.errors);
                //errorsItems
                //arrayErrors.map()
                //console.log(arrayErrors)
            }
        }
        setSpinnerElement(false)
    }
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [categorias, unidad_medidas, divisas, proveedores, producto_tipos, estatus, sucursales] = await Promise.all([
                    getCategorias({}),
                    getUnidad_medidas({}),
                    divisasResourse('get', {}),
                    proveedoresResource('get', {}),
                    getProducto_tipos({}),
                    getEstatus({ params: { model: 'Producto' } }),
                    sucursalesResource('get', { params: { select: true } })
                ]);

                setSelects({
                    ...selects,
                    sucursales: sucursales.sucursales,
                    categorias: categorias.categorias,
                    unidad_medidas: unidad_medidas.unidad_medidas,
                    divisas: divisas.divisas,
                    proveedores: proveedores.proveedores,
                    producto_tipos: producto_tipos.producto_tipos,
                    estatus: estatus.estatus
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const editProduct = (product) => {
        validation.setValues(product);
        let newInventarios = inventarios.filter(i => i.sku != product.sku)
        setInventarios(newInventarios)
    }

    const columns = ColumnsImport(editProduct);
    return (
        <div className=''>
            <ToastContainerHelper />
            <div className='d-flex justify-content-end flex-column flex-lg-row'>
                {!spinnerElement ?
                    <>
                        <button className='button-option-title p-2 mx-md-2' onClick={() => fileInputRef.current.click()}>Añadir con Excel</button>
                        <button className='buttonAñadirInventarioOption p-2 mx-md-2' disabled={inventarios.length == 0} onClick={() => saveInventario()}>Finalizar Inventario</button>
                    </>
                    :
                    <>
                        <button className='button-option-title p-2 mx-md-2' disabled={true}>Añadir con Excel</button>
                        <Button className='buttonAñadirInventarioOption p-2 mx-md-2' disable={true}>
                            <Spinner size="sm">Guardando datos...</Spinner>
                            <span>{' '}Guardando datos...</span>
                        </Button>
                    </>
                }
            </div>

            <div className='title-dash mb-4 mt-3'>
                <h5 className='text-title-dash mt-2 titleAñadir'>Añadir de manera manual</h5>
            </div>

            <div className='d-flex flex-column'>
                <div className='d-flex flex-column flex-lg-row col-12'>
                    <div className="card-body">
                        <Form
                            onSubmit={e => {
                                console.log(validation);
                                e.preventDefault();
                                validation.handleSubmit();

                                return false;
                            }}
                        >
                            <div className="row">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="form-label">Nombre</label>
                                            <Input
                                                name="nombre"
                                                type="text"
                                                className='inputAñadirInventario'
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.nombre || ""}
                                                invalid={
                                                    validation.touched.nombre &&
                                                        validation.errors.nombre
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Descripcion</label>
                                            <Input
                                                className='inputAñadirInventario'
                                                name="descripcion"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.descripcion || ""}
                                                invalid={
                                                    validation.touched.descripcion &&
                                                        validation.errors.descripcion
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.descripcion && validation.errors.descripcion) && <FormFeedback type="invalid">{validation.errors.descripcion}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Categorias</label>
                                            <CustomSelect
                                                name="categoria_id"
                                                options={selects.categorias} // Opciones para el Select
                                                value={selects.categorias.find(option => option.value === validation.values.categoria_id)}
                                                onChange={option => validation.setFieldValue('categoria_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.categoria_id && validation.errors.categoria_id) && <FormFeedback type="invalid">{validation.errors.categoria_id.value ? validation.errors.categoria_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Precio</label>
                                            <Input
                                                className='inputAñadirInventario'
                                                name="precio"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.precio || ""}
                                                invalid={
                                                    validation.touched.precio &&
                                                        validation.errors.precio
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.precio && validation.errors.precio) && <FormFeedback type="invalid">{validation.errors.precio}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Costo</label>
                                            <Input
                                                className='inputAñadirInventario'
                                                name="costo_proveedor"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.costo_proveedor || ""}
                                                invalid={
                                                    validation.touched.costo_proveedor &&
                                                        validation.errors.costo_proveedor
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.costo_proveedor && validation.errors.costo_proveedor) && <FormFeedback type="invalid">{validation.errors.costo_proveedor}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Cantidad en stock</label>
                                            <Input
                                                className='inputAñadirInventario'
                                                name="cantidad"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.cantidad || ""}
                                                invalid={
                                                    validation.touched.cantidad &&
                                                        validation.errors.cantidad
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.cantidad && validation.errors.cantidad) && <FormFeedback type="invalid">{validation.errors.cantidad}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Unidad de medida</label>
                                            <CustomSelect
                                                name="unidad_medida_id"
                                                options={selects.unidad_medidas} // Opciones para el Select
                                                value={selects.unidad_medidas.find(option => option.value === validation.values.unidad_medida_id)}
                                                onChange={option => validation.setFieldValue('unidad_medida_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.unidad_medida_id && validation.errors.unidad_medida_id) && <FormFeedback type="invalid">{validation.errors.divisa_id.value ? validation.errors.divisa_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">Tipo de moneda</label>
                                            <CustomSelect
                                                name="divisa_id"
                                                options={selects.divisas} // Opciones para el Select
                                                value={selects.divisas.find(option => option.value === validation.values.divisa_id)}
                                                onChange={option => validation.setFieldValue('divisa_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.divisa_id && validation.errors.divisa_id) && <FormFeedback type="invalid">{validation.errors.divisa_id.value ? validation.errors.divisa_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="fecha_compra">Fecha de Compra</Label>
                                                <Input
                                                    className='inputAñadirInventario'
                                                    name="fecha_compra"
                                                    type="date"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.fecha_compra || ""}
                                                    invalid={
                                                        validation.touched.fecha_compra &&
                                                            validation.errors.fecha_compra
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.fecha_compra && validation.errors.fecha_compra) && <FormFeedback type="invalid">{validation.errors.fecha_compra}</FormFeedback>}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="fecha_elaboracion">fecha_elaboracion</Label>
                                                <Input
                                                    name="fecha_elaboracion"
                                                    className='inputAñadirInventario'
                                                    type="date"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.fecha_elaboracion || ""}
                                                    invalid={
                                                        validation.touched.fecha_elaboracion &&
                                                            validation.errors.fecha_elaboracion
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.fecha_elaboracion && validation.errors.fecha_elaboracion) && <FormFeedback type="invalid">{validation.errors.fecha_elaboracion}</FormFeedback>}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="fecha_caducidad">Caducidad</Label>
                                                <Input
                                                    id="exampleDate"
                                                    name="fecha_caducidad"
                                                    className='inputAñadirInventario'
                                                    type="date"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.fecha_caducidad || ""}
                                                    invalid={
                                                        validation.touched.fecha_caducidad &&
                                                            validation.errors.fecha_caducidad
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.fecha_caducidad && validation.errors.fecha_caducidad) && <FormFeedback type="invalid">{validation.errors.fecha_caducidad}</FormFeedback>}
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Código de producto</label>
                                            <Input
                                                name="sku"
                                                type="text"
                                                className='inputAñadirInventario'
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.sku || ""}
                                                invalid={
                                                    validation.touched.sku &&
                                                        validation.errors.sku
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {(validation.touched.sku && validation.errors.sku) && <FormFeedback type="invalid">{validation.errors.sku}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Proveedores</label>
                                            <CustomSelect
                                                name="proveedor_id"
                                                options={selects.proveedores} // Opciones para el Select
                                                value={selects.proveedores.find(option => option.value === validation.values.proveedor_id)}
                                                onChange={option => validation.setFieldValue('proveedor_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.proveedor_id && validation.errors.proveedor_id) && <FormFeedback type="invalid">{validation.errors.proveedor_id.value ? validation.errors.proveedor_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className={validation.values.tipo_id== 2 ? "col-sm-3":"col-sm-6"}>
                                            <label className="form-label">Tipo de Producto</label>
                                            <CustomSelect
                                                name="tipo_id"
                                                options={selects.producto_tipos} // Opciones para el Select
                                                value={selects.producto_tipos.find(option => option.value === validation.values.tipo_id)}
                                                onChange={option => validation.setFieldValue('tipo_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id.value ? validation.errors.tipo_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        {validation.values.tipo_id== 2 &&
                                        <div className="col-sm-3">
                                            <label className="form-label">Categoria interna</label>
                                            <Select
                                                styles={customStyles}
                                                name="activo"
                                                options={[
                                                    {'label':'Activo','value':'activo'},
                                                    {'label':'Consumible','value':'consumible'},
                                                ]}
                                                value={validation.values.activoSelect}
                                                onChange={option => [validation.setFieldValue('activo', option.value),validation.setFieldValue('activoSelect', option)]}
                                            />
                                            {(validation.touched.activo && validation.errors.activo) && <FormFeedback type="invalid">{validation.errors.activo}</FormFeedback>}
                                        </div>
                                        }
                                        <div className="col-sm-3">
                                            <label className="form-label">Cotizaciones</label>
                                            <CustomSelect
                                                name="cotizacion_id"
                                                options={selects.cotizaciones} // Opciones para el Select
                                                value={selects.cotizaciones.find(option => option.value === validation.values.cotizacion_id)}
                                                onChange={option => validation.setFieldValue('cotizacion_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.cotizacion_id && validation.errors.cotizacion_id) && <FormFeedback type="invalid">{validation.errors.cotizacion_id.value ? validation.errors.cotizacion_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="factura">
                                                    Factura
                                                </Label>
                                                <Input
                                                    className='inputAñadirInventario'
                                                    name="factura"
                                                    type="file"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Label for="factura">Nota de remisión</Label>
                                                <Input
                                                    className='inputAñadirInventario'
                                                    name="nota"
                                                    type="file"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3">
                                            <Label for="estatus_id">Estado</Label>
                                            <CustomSelect
                                                name="estatus_id"
                                                options={selects.estatus} // Opciones para el Select
                                                value={selects.estatus.find(option => option.value === validation.values.estatus_id)}
                                                onChange={option => validation.setFieldValue('estatus_id', option.id)}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {(validation.touched.estatus_id && validation.errors.estatus_id) && <FormFeedback type="invalid">{validation.errors.estatus_id.value ? validation.errors.estatus_id.value : 'Error'}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">IVA</label>
                                            <Select
                                                styles={customStyles}
                                                name="iva"
                                                options={selects.ivas}
                                                value={validation.values.ivaSelect}
                                                onChange={option => [validation.setFieldValue('iva', option.value),validation.setFieldValue('ivaSelect', option)]}
                                            />
                                            {(validation.touched.iva && validation.errors.iva) && <FormFeedback type="invalid">{validation.errors.iva}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="form-label">IEPS</label>
                                            <Select
                                                styles={customStyles}
                                                name="iva"
                                                options={selects.ieps}
                                                value={validation.values.iepsSelect}
                                                onChange={option => [validation.setFieldValue('ieps', option.value.id),validation.setFieldValue('iepsSelect', option)]}
                                            />
                                            {(validation.touched.ieps && validation.errors.ieps) && <FormFeedback type="invalid">{validation.errors.ieps}</FormFeedback>}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="upload-container col-12">
                                                <input type="file" id="file-upload" className="input-file" accept=".jpeg, .png, .jpg" onChange={(e) => getBase64File(e)} />
                                                <label htmlFor="file-upload" className="upload-box">
                                                    <div className="upload-icon col-12">
                                                        {validation.values.imagen ?
                                                            <img src={validation.values.imagen} alt="Upload" style={{ width: '70%', height: '110px' }} />
                                                            :
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/inventarios/image.svg`} alt="Upload" />
                                                        }
                                                    </div>
                                                    <p className="upload-text">Carga imagen del producto</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <FormGroup check>
                                                        <Input type="checkbox"
                                                            name="transpaso"
                                                            value={validation.values.transpaso}
                                                            onChange={(e) => validation.values.transpaso = !validation.values.transpaso}
                                                        />
                                                        <Label check>
                                                            Transpaso de producto
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Label for="sucursal_origen_id">Sucursal de origen</Label>
                                                    <CustomSelect
                                                        name="sucursal_origen_id"
                                                        options={selects.sucursales} // Opciones para el Select
                                                        value={selects.sucursales.find(option => option.value === validation.values.sucursal_origen_id)}
                                                        onChange={option => validation.setFieldValue('sucursal_origen_id', option.id)}
                                                        getOptionLabel={(option) => `${option.nombre}`}
                                                        getOptionValue={(option) => option.id}
                                                        isDisabled={!validation.values.transpaso}
                                                    />
                                                    {(validation.touched.sucursal_origen_id && validation.errors.sucursal_origen_id) && <FormFeedback type="invalid">{validation.errors.sucursal_origen_id.value ? validation.errors.sucursal_origen_id.value : 'Error'}</FormFeedback>}
                                                </div>
                                                <div className="col-sm-6">
                                                    <Label for="sucursal_destino_id">Sucursal de destino</Label>
                                                    <CustomSelect
                                                        name="sucursal_destino_id"
                                                        options={selects.sucursales} // Opciones para el Select
                                                        value={selects.sucursales.find(option => option.value === validation.values.sucursal_destino_id)}
                                                        onChange={option => validation.setFieldValue('sucursal_destino_id', option.id)}
                                                        getOptionLabel={(option) => `${option.nombre}`}
                                                        getOptionValue={(option) => option.id}
                                                        isDisabled={!validation.values.transpaso}
                                                    />
                                                    {(validation.touched.sucursal_destino_id && validation.errors.sucursal_destino_id) && <FormFeedback type="invalid">{validation.errors.sucursal_destino_id.value ? validation.errors.sucursal_destino_id.value : 'Error'}</FormFeedback>}
                                                </div>
                                                <div className="col-sm-12">
                                                    <Label for="cantidad_transpaso">Cantidad</Label>
                                                    <Input
                                                        name="cantidad_transpaso"
                                                        className='inputAñadirInventario'
                                                        type="text"
                                                        disabled={!validation.values.transpaso}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.cantidad_transpaso || ""}
                                                        invalid={
                                                            validation.touched.cantidad_transpaso &&
                                                                validation.errors.cantidad_transpaso
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {(validation.touched.cantidad_transpaso && validation.errors.cantidad_transpaso) && <FormFeedback type="invalid">{validation.errors.cantidad_transpaso}</FormFeedback>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        

                                    </div>
                                </div>
                                <div className="col-4">
                                    <DataTableOnlyHelper columns={columns} items={inventarios} pending={false} />
                                </div>
                            </div>
                            <div className="col-8" style={{ textAlign: 'center' }}>
                                <br /><br />
                                <button className="buttonAñadirInventario" type="submit">
                                    Añadir producto
                                </button>
                            </div>
                        </Form>
                        <input

                            type="file"
                            accept=".xlsx, .xls"
                            className="hidden-input"
                            ref={fileInputRef}
                            onChange={(e) => handleFileChange(e)}
                        />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default NewInventario