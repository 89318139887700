import React, { useState, useEffect } from "react";
import { Form, Input, FormFeedback, Button, Table } from "reactstrap";
import AsyncSelect from 'react-select/async';
import { productosResource, getCategorias, getUnidad_medidas, productosProveedoresResource, getSat_catalogo, getProducto_tipos } from "../../../helpers/backend_helper";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { FaTrash } from 'react-icons/fa';

const Step5 = ({ proveedor, setProveedor, setModal, modal, setStep }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const save = async () => {
        const response = await productosProveedoresResource('post', productos)
        if (response.status) {
            setStep({ step: 1, name: 'Paso 1 - Informacion de la persona fisica o moral', size: 'ls' })
            setModal({ show: false })
        }
    }
    const [selects, setSelects] = useState({
        categorias: [],
        unidades: [],
        sat: [],
        producto_tipos: [],
        ivas: [
            { label: 'Excento', value: null },
            { label: '0%', value: 0 },
            { label: '16%', value: 16 },
        ],
        ieps: [
            { label: 'Excento', value: null },
            { label: '6%', value: 6 },
            { label: '7%', value: 7 },
            { label: '9%', value: 9 }
        ],
    })
    const [productos, setProductos] = useState([]);
    const [proveedorProductos, setProveedorProductos] = useState([]);
    const [inventarios, setInventarios] = useState([]);
    const productoBase = {
        nombre: '',
        id: null,
        cantidad: '',
        solicitado: '',
        proveedor_id: proveedor.id,
        stock: false,
        unidad_medida: null,
        categoria: null,
        iva:null,
        ivaSelect:null,
        iepsSelect:null,
        ieps:null,
        activo:true,
        activoSelect:null
    }
    const [producto, setProducto] = useState({ ...productoBase })
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: productoBase,
        validationSchema: Yup.object({
            nombre: Yup.string().required('Nombre es requerido'),
            precio: Yup.string().required('Precio es requerido'),
            categoria: Yup.object().required('Categorias es requerido'),
            unidad_medida: Yup.object().required('Unidad es requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setProductos([...productos, values])
            validation.resetForm();
        },
    });

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [categorias, unidades, sat, producto_tipos] = await Promise.all([
                    getCategorias(),
                    getUnidad_medidas(),
                    getSat_catalogo(),
                    getProducto_tipos({}),
                ]);
                setSelects({
                    ...selects,
                    categorias: categorias.categorias,
                    unidades: unidades.unidad_medidas,
                    sat: sat.sat,
                    producto_tipos: producto_tipos.producto_tipos
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const editProduct = (product) => {
        let newlistado = productos.filter(i => i.nombre != product.nombre)
        setProductos(newlistado)
    }

    const changeProd = (p) => {
        console.log(p)
        validation.setFieldValue('id', p.id)
        validation.setFieldValue('nombre', p.nombre)
        validation.setFieldValue('descripcion', p.descripcion)
        validation.setFieldValue('precio', p.precio)
        validation.setFieldValue('unidad_medida', p.unidad)

    }

    return (
        <>

            <div className="row">
                <div className="col-sm-5">
                    <Form
                        onSubmit={e => {
                            e.preventDefault();

                            validation.handleSubmit();
                            console.log(validation)
                            return false;
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="form-label">Buscador de productos </label>
                                <AsyncSelect
                                    styles={customStyles}
                                    cacheOptions
                                    defaultOptions
                                    value={producto}
                                    onChange={option => [changeProd(option)]}
                                    loadOptions={loadOptions}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                <br />
                            </div>
                            <div className="col-sm-12">
                                <label className="form-label">Nombre del producto</label>
                                <Input
                                    name="nombre"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.nombre || ""}
                                    invalid={
                                        validation.touched.nombre &&
                                            validation.errors.nombre
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                            </div>
                            <div className="col-sm-12">
                                <label className="form-label">Description</label>
                                <Input
                                    name="descripcion"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.descripcion || ""}
                                    invalid={
                                        validation.touched.descripcion &&
                                            validation.errors.descripcion
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.descripcion && validation.errors.descripcion) && <FormFeedback type="invalid">{validation.errors.descripcion}</FormFeedback>}
                            </div>
                            <div className="col-sm-12">
                                <label className="form-label">Codigo de producto del SAT</label>
                                <Select
                                    styles={customStyles}
                                    name="sat_id"
                                    options={selects.sat}
                                    value={validation.values.sat}
                                    onChange={option => [validation.setFieldValue('sat_id', option.id), validation.setFieldValue('sat', option)]}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.sat_id && validation.errors.sat_id) && <FormFeedback type="invalid">{validation.errors.sat_id}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Departamento</label>
                                <Select
                                    styles={customStyles}
                                    name="categoria_id"
                                    options={selects.categorias}
                                    value={validation.values.categoria}
                                    onChange={option => [validation.setFieldValue('categoria_id', option.id), validation.setFieldValue('categoria', option)]}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.categoria_id && validation.errors.categoria_id) && <FormFeedback type="invalid">{validation.errors.categoria_id}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Precio</label>
                                <Input
                                    name="precio"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.precio || ""}
                                    invalid={
                                        validation.touched.precio &&
                                            validation.errors.precio
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.precio && validation.errors.precio) && <FormFeedback type="invalid">{validation.errors.precio}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Unidad</label>
                                <Select
                                    styles={customStyles}
                                    name="unidad_medida_id"
                                    options={selects.unidades}
                                    value={validation.values.unidad_medida}
                                    onChange={option => [validation.setFieldValue('unidad_medida_id', option.id), validation.setFieldValue('unidad_medida', option)]}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.unidad_medida_id && validation.errors.unidad_medida_id) && <FormFeedback type="invalid">{validation.errors.unidad_medida_id}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Cantidad</label>
                                <Input
                                    name="cantidad_unidad"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.cantidad_unidad || ""}
                                    invalid={
                                        validation.touched.cantidad_unidad &&
                                            validation.errors.cantidad_unidad
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.cantidad_unidad && validation.errors.cantidad_unidad) && <FormFeedback type="invalid">{validation.errors.cantidad_unidad}</FormFeedback>}
                            </div>
                            <div className={validation.values.tipo_id == 2 ? "col-sm-6" : "col-sm-12"}>
                                <label className="form-label">Tipo de Producto</label>
                                <Select
                                    styles={customStyles}
                                    name="tipo_id"
                                    options={selects.producto_tipos} // Opciones para el Select
                                    value={selects.producto_tipos.find(option => option.value === validation.values.tipo_id)}
                                    onChange={option => validation.setFieldValue('tipo_id', option.id)}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id.value ? validation.errors.tipo_id.value : 'Error'}</FormFeedback>}
                            </div>
                            {validation.values.tipo_id == 2 &&
                                <div className="col-sm-6">
                                    <label className="form-label">Categoria interna</label>
                                    <Select
                                        styles={customStyles}
                                        name="activo"
                                        options={[
                                            { 'label': 'Activo', 'value': true },
                                            { 'label': 'Consumible', 'value': false },
                                        ]}
                                        value={validation.values.activoSelect}
                                        onChange={option => [validation.setFieldValue('activo', option.value), validation.setFieldValue('activoSelect', option)]}
                                    />
                                    {(validation.touched.activo && validation.errors.activo) && <FormFeedback type="invalid">{validation.errors.activo}</FormFeedback>}
                                </div>
                            }
                            <div className="col-sm-6">
                                <label className="form-label">IVA</label>
                                <Select
                                    styles={customStyles}
                                    name="iva"
                                    options={selects.ivas}
                                    value={validation.values.ivaSelect}
                                    onChange={option => [validation.setFieldValue('iva', option.value), validation.setFieldValue('ivaSelect', option)]}
                                />
                                {(validation.touched.iva && validation.errors.iva) && <FormFeedback type="invalid">{validation.errors.iva}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">IEPS</label>
                                <Select
                                    styles={customStyles}
                                    name="ieps"
                                    options={selects.ieps}
                                    value={validation.values.iepsSelect}
                                    onChange={option => [validation.setFieldValue('ieps', option.value), validation.setFieldValue('iepsSelect', option)]}
                                />
                                <br/>
                                {(validation.touched.ieps && validation.errors.ieps) && <FormFeedback type="invalid">{validation.errors.ieps}</FormFeedback>}
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'right' }}>
                                <Button color="primary" block type="submit">
                                    Añadir producto
                                </Button>{' '}
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="col-sm-7">
                    <div className="">
                        procuctos
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                    <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                                <th style={{ backgroundColor: 'transparent' }}>Precio</th>
                                                <th style={{ backgroundColor: 'transparent' }}>Departamento</th>
                                                <th style={{ backgroundColor: 'transparent' }}>Unidad</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '12px' }}>
                                            {productos.map((prod, i) => (
                                                <tr key={i} >
                                                    <th style={{ backgroundColor: 'transparent' }}>{prod.nombre}</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>{prod.precio}</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>{prod.categoria.nombre}</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>{prod.unidad_medida.nombre}</th>
                                                    <th style={{ backgroundColor: 'transparent' }}><a className='warning' href='#' onClick={() => editProduct(prod)}><FaTrash style={{ fontSize: '0.75rem' }} /></a></th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <button className='buttonAñadirInventarioOption p-2 mx-md-3' onClick={() => save()} disabled={productos.length == 0}>Finalizar  alta</button>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Step5;