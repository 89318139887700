import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader,Table, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";

const DetalleCompra = ({ modal, setModal }) => {
    const [productos, setProductos] = useState([]);
    const [compra, setCompra] = useState({})
    const [step, setStep] = useState({ step: 1, name: 'Paso 1 - Selección de tipo de compra', size: 'ls' });
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size='xl'>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Detalle de Compra
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-4">
                            <label className="form-label">Responsable</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.responsable?.name}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Fecha de compra</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.fecha_compra}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Divisa</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.divisa.nombre}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Cantidad de productos</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.cantidad_total}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Precio de compra</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.total}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Detalle de impuestos</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={'$0'}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Monto Total</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.total}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Numero de factura</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.tiket}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Estado de compra</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.estatus.nombre}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Proveedor</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.proveedor.nombre}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Fecha de entrega prevista</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.fecha_estimada}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Numero de seguimiento</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.seguimiento}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Fecha de entrega real</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.fecha_entrega}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Notas comentarios</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.notas}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Adjuntos</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={modal.compra.adjunto}
                            />
                        </div>
                        <div className="col-sm-12">
                            <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: 'transparent' }}>SKU</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Cantidad</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Precio unitario</th>
                                            <th style={{ backgroundColor: 'transparent' }}>Impuestos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modal.compra.productos.map((p,i)=>(
                                            <tr key={i}>
                                                <th style={{ backgroundColor: 'transparent' }}>{p.producto.producto.sku}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>{p.producto.producto.nombre}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>{p.cantidad}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>{p.producto.precio}</th>
                                                <th style={{ backgroundColor: 'transparent' }}>${0}</th>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}

export default DetalleCompra