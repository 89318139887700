import React, { useEffect, useState } from 'react'
import { inventariosResource, costeoGet } from '../../../helpers/backend_helper'
import Select from 'react-select'
import { Input, Form, FormFeedback, Label, FormGroup } from 'reactstrap'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PreviewPDF from '../../../components/CommonForBoth/previewPDF';

const TablaCosteo = ({ sucursal }) => {
    const [filters, setFilters] = useState({ per_page: 20, page: 1, search: '', fecha_inicio: '', fecha_fin: '', costeo: true })
    const [inventarios, setInventarios] = useState([]);
    const [headers, setHeaders] = useState('application/pdf');
    const [selectedPerPage, setSelectedPerPage] = useState({});
    useEffect(() => {
        const getInventarios = async () => {
            const response = await costeoGet({ params: filters }, sucursal.id);
            if (response.status) {
                setInventarios(response.inventarios)
            }
        }
        if (sucursal.id) {
            getInventarios()
        }
    }, [sucursal, filters]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [option, setOption] = useState(null)
    const [modalPdf, setModalPdf] = useState({ show: false })

    const handleDateChange = (date, setter, first = false) => {
        setter(date);
        if (first) {
            setFilters({ ...filters, fecha_inicio: date })
        } else {
            setFilters({ ...filters, fecha_fin: date })
        }
    };

    const getReport = async (format) => {
        //e == 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        let config = {
            params: filters,
            headers: { 'Accept': format },
            responseType: "blob"
        }

        costeoGet(config, sucursal.id).then(response => {

            if (format != 'application/pdf') {
                console.log(response)
                const url = URL.createObjectURL(new Blob([response]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    format=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'Reporte de Costeo.xlsx':'Reporte de Costeo.pptx'
                )
                document.body.appendChild(link)
                link.click()

            } else {
                let nombre = 'Archivo';
                var reader = new FileReader();
                reader.readAsDataURL(response);
                reader.fileName = nombre[1] || nombre[0];
                reader.onloadend = function (name) {
                    name = nombre[0];
                    var base64data = reader.result;
                    setModalPdf({ show: true, update: false, url: base64data });
                };
            }


        })
    }
    return (
        <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
            <div className='optionSucursales col-10 col-lg-12 d-flex flex-column flex-md-row align-items-center justify-content-center flex-wrap mt-2 mt-lg-1'>
                <div className='d-flex align-items-center mx-2'>
                    <button onClick={() => getReport('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')} className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>Excel</span></button>
                </div>
                <div className='d-flex align-items-center mx-2'>
                    <button onClick={() => getReport('application/pdf')} className='button-option-download d-flex align-items-center justify-content-between'><img src={`${process.env.PUBLIC_URL}/assets/images/home/download.svg`} className='ms-2' /><span className='mx-2'>PDF</span></button>
                </div>

                <DatePicker
                    selected={startDate}
                    onChange={(date) => handleDateChange(date, setStartDate, 'first')}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Fecha inicial"
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => handleDateChange(date, setEndDate)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Fecha final"
                />

                <div className='d-flex align-items-center mx-2'>
                    <Input className='button-option-filtros d-flex align-items-center justify-content-between' placeholder='Buscador' onChange={((e) => setFilters({ ...filters, search: e.target.value }))} />
                </div>
            </div>

            <div className="table-responsive table-responsive-sucursales">
                <table className="table table-sucursales">
                    <thead className='text-center'>
                        <tr>
                            <th scope="col">Producto</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Inventario inicial</th>
                            <th scope="col">Costo unitario inicial</th>
                            <th scope="col">Costo total de Inventario Inicial</th>
                            <th scope="col">Unidades Compradas</th>
                            <th scope="col">Costo total compras</th>
                            <th scope="col">Unidades vendidas</th>
                            <th scope="col">Costo total ventas</th>
                            <th scope="col">Inventario acomulado</th>
                            <th scope="col">Monto acomulado</th>
                            <th scope="col">Costo Promedio</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {inventarios.length ? inventarios.map((p) => (
                            <tr className='' key={p.id}>
                                <td>{p.producto.nombre}</td>
                                <td>{p.unidad_medida}</td>
                                <td>{p.inventario_inicial}</td>
                                <td>${Number(p.precio_unitario_inicial).toLocaleString('en-US')}</td>
                                <td>${Number(p.precio_total_inicial).toLocaleString('en-US')}</td>
                                <td>{p.compras_unidades}</td>
                                <td>${Number(p.compras_precio_total).toLocaleString('en-US')}</td>
                                <td>{p.ventas_unidades}</td>
                                <td>${Number(p.ventas_precio_total).toLocaleString('en-US')}</td>
                                <td>{p.inventario_acomulado}</td>
                                <td>${Number(p.monto_acomulado).toLocaleString('en-US')}</td>
                                <td>${Number(p.costo_promedio).toLocaleString('en-US')}</td>
                            </tr>
                        ))
                            :
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={16} style={{ textAlign: 'center' }}>
                                    No hay registros para mostrar
                                </th>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <PreviewPDF setModalPdf={setModalPdf} modalPdf={modalPdf} />
        </div>
    )
}

export default TablaCosteo