import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { getPago_terminos, getPago_metodos, divisasResourse} from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";



const Step3 = ({ modal, setModal, compra, setCompra, setStep,setProductos,productos }) => {
    const [selects, setSelects] = useState({
        pago_formas: [],
        pago_metodos:[],
        divisas:[],
        pago_terminos:[]
    })
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [formas, teminos,divisas] = await Promise.all([
                    getPago_metodos(),
                    getPago_terminos(),
                    divisasResourse('get',{params:{select:true}})
                ]);

                setSelects({
                    ...selects,
                    pago_metodos:formas.metodos,
                    pago_terminos:teminos.terminos,
                    divisas:divisas.divisas
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: compra,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            setCompra(values)
            setStep({step:4,name:'Paso 4 - Entrega',size:'ls'})
        },
    });

    const getProductos = async (option) => {
        let products = await getProductCotizacion(option.id);
        console.log(products)
        validation.setFieldValue('cotizacion', option)
        validation.setFieldValue('cotizacion_id', option.id)
        validation.setFieldValue('proveedor', option.proveedor)
        validation.setFieldValue('proveedor_id', option.proveedor.id)
        setProductos(products.productos)
    }
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                
                
                <div className="col-sm-6">
                    <label className="form-label">Metodo de pago</label>
                    <Select
                        styles={customStyles}
                        name="pago_metodo_id"
                        options={selects.pago_metodos}
                        value={validation.values.pago_metodo}
                        onChange={option => [validation.setFieldValue('pago_metodo', option),validation.setFieldValue('pago_metodo_id', option.id)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.pago_metodo_id && validation.errors.pago_metodo_id) && <FormFeedback type="invalid">{validation.errors.pago_metodo_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Numero de tiket</label>
                    <Input
                        name="tiket"
                        type="text"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tiket || ""}
                        invalid={
                            validation.touched.tiket &&
                                validation.errors.tiket
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.tiket && validation.errors.tiket) && <FormFeedback type="invalid">{validation.errors.tiket}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Divisa</label>
                    <Select
                        styles={customStyles}
                        name="divisa_id"
                        options={selects.divisas}
                        value={validation.values.divisa}
                        onChange={option => [validation.setFieldValue('divisa', option),validation.setFieldValue('divisa_id', option.id)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.divisa_id && validation.errors.divisa_id) && <FormFeedback type="invalid">{validation.errors.divisa_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Fecha limite de pago</label>
                    <Input
                        name="fecha_limite"
                        type="date"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fecha_limite || ""}
                        invalid={
                            validation.touched.fecha_limite &&
                                validation.errors.fecha_limite
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.fecha_limite && validation.errors.fecha_limite) && <FormFeedback type="invalid">{validation.errors.fecha_limite}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Terminos de pago</label>
                    <Select
                        styles={customStyles}
                        name="pago_termino_id"
                        options={selects.pago_terminos}
                        value={validation.values.pago_termino}
                        onChange={option => [validation.setFieldValue('pago_termino', option),validation.setFieldValue('pago_termino_id', option.id)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.pago_termino_id && validation.errors.pago_termino_id) && <FormFeedback type="invalid">{validation.errors.pago_termino_id}</FormFeedback>}
                    <br/>
                </div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step3