import React, { useState, useEffect } from "react";
import { Input, Form, FormFeedback, Label, FormGroup } from 'reactstrap';
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import CustomSelect from "../../components/SELECT/CustomSelect";
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import { codesResource, cpResource, sucursalesResource } from '../../helpers/backend_helper';
import ConfirmacionModal from '../../components/CommonForBoth/ConfirmacionModal';
import { detalle_sucursal } from '../../store/titles/action'
import Map from "../../components/Map/Map";

const FormDetalle = ({ detalleSucursal, sucursal, setSucursal, tipo_cambio, dispatch }) => {
    const [ubicacion, setUbicacion] = useState(null);
    const [update, setUpdate] = useState(detalleSucursal.update);
    const [confirm, setConfirm] = useState(false);
    const [modalConfirmacion, setModalConfirmacion] = useState({ show: false, sucursal_id: null })
    const [selects, setSelects] = useState({
        estatus: [],
        colaboradores: [],
        cps: [],
        dias: [{ label: 'L', value: 'Lunes' }, { label: 'M', value: 'Martes' }, { label: 'Mi', value: 'Miercoles' }, { label: 'J', value: 'Jueves' }, { label: 'V', value: 'Viernes' }, { label: 'S', value: 'Sábado' }, { label: 'D', value: 'Domingo' }]
    })
    useEffect(() => {
        setUpdate(detalleSucursal.update)
        if (!detalleSucursal.update) {
            validation.resetForm();
        }
        if (detalleSucursal.save) {
            console.log(validation)
            validation.handleSubmit();
        }
    }, [detalleSucursal]);
    useEffect(() => {
        console.log(confirm)
        if (confirm) {
            const saveData = () => {
                validation.handleSubmit();
                setConfirm(false)
            }
            saveData()
        }
    }, [confirm]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const buscarUbicacionEnGoogleMaps = async () => {
        if (sucursal.estado && sucursal.municipio) {
            try {
                const direccion = `${sucursal.colonia}, ${sucursal.colonia}, ${sucursal.municipio}, ${sucursal.estado}`;
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        direccion
                    )}&key=AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ`
                );
                const data = await response.json();

                if (data.results.length > 0) {
                    const ub = data.results[0].geometry.location;
                    //ubicacion.lat = ub.lat
                    //ubicacion.lng = ub.lng
                    setUbicacion({ lat: ub.lat, lng: ub.lng });
                    //return { lat: ubicacion.lat, lng: ubicacion.lng };
                } else {
                    console.error('No se pudo encontrar la ubicación para la dirección:', direccion);
                    //return { lat: 0, lng: 0 };
                }
            } catch (error) {
                console.error('Error al buscar la ubicación:', error);
                //return { lat: 0, lng: 0 };
            }
        }
    };

    useEffect(() => {
        buscarUbicacionEnGoogleMaps()
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: sucursal,
        validationSchema: Yup.object({
            nombre: Yup.string().required('El nombre es requerido'),
            email: Yup.string().email('Formato de correo electrónico inválido').required('El correo electrónico es requerido'),
            telefono: Yup.string().required('El teléfono es requerido').matches(/^\d{10}$/, 'El teléfono debe tener 10 dígitos numéricos'),
            estatus_id: Yup.number().required('El estatus es requerido'),
            //responsable_id: Yup.number().required('El responsable es requerido'),
            calle: Yup.string().required('La calle es requerida'),
            cp: Yup.object().required('El código postal es requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            
                const response = await sucursalesResource('put', values, values.id)
                if (response.status) {
                    setSucursal(response.sucursal)
                    dispatch(detalle_sucursal({ ...detalleSucursal, save: false, update: false }))
                    validation.resetForm();
                } else {
                    setErrors(response.response.data.errors)
                }
        },
    });
    const loadOptions = async (inputValue, callback) => {

        if (inputValue.length > 2) {
            console.log(inputValue)
            const options = await cpResource('get', { params: { search: inputValue, estado_id: sucursal.direccion.cp.estado_id, municipio_id: sucursal.municipio.id } });
            callback(options.data);
        }
    };

    return (
        <div className='col-12 d-flex mt-5'>
            <div className='col-8'>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();

                        return false;
                    }}
                >
                    <div className="row">

                        <div className="col-sm-2">
                            <label className="form-label">Telefono</label>
                            <Input
                                readOnly={!update}
                                name="telefono"
                                className="inputAñadirInventario"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.telefono || ""}
                                invalid={
                                    validation.touched.telefono &&
                                        validation.errors.telefono
                                        ? true
                                        : false
                                }
                            />
                            {(validation.touched.telefono && validation.errors.telefono) && <FormFeedback type="invalid">{validation.errors.telefono}</FormFeedback>}
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Correo Electrónico</label>
                            <Input
                                readOnly={!update}
                                name="email"
                                className="inputAñadirInventario"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email &&
                                        validation.errors.email
                                        ? true
                                        : false
                                }
                            />
                            {(validation.touched.email && validation.errors.email) && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Responsable</label>
                            <CustomSelect
                                isDisabled={!update}
                                name="responsable_id"
                                options={selects.colaboradores}
                                value={validation.values.responsable}
                                onChange={option => [validation.setFieldValue('responsable_id', option.id), validation.setFieldValue('responsable', option)]}
                                getOptionLabel={(option) => `${option.nombre}`}
                                getOptionValue={(option) => option.id}
                            />
                            {(validation.touched.responsable_id && validation.errors.responsable_id) && <FormFeedback type="invalid">{validation.errors.responsable_id.value ? validation.errors.responsable_id.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-2">
                            <label className="form-label">Calle</label>
                            <Input
                                readOnly={!update}
                                name="calle"
                                className="inputAñadirInventario"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.direccion.calle || ""}
                                invalid={
                                    validation.touched.calle &&
                                        validation.errors.calle
                                        ? true
                                        : false
                                }
                            />
                            {(validation.touched.calle && validation.errors.calle) && <FormFeedback type="invalid">{validation.errors.calle}</FormFeedback>}
                        </div>
                        <div className="col-sm-3">
                            <label className="form-label">Codigo Postal</label>
                            <AsyncSelect
                                    styles={customStyles}
                                    cacheOptions
                                    defaultOptions
                                    value={validation.values.cp}
                                    onChange={option => [validation.setFieldValue('cp', option),validation.setFieldValue('cp_id', option.id), validation.setFieldValue('colonia', option.asentamiento)]}
                                    loadOptions={loadOptions}
                                    getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                                    getOptionValue={(option) => option.id}
                                />
                            {(validation.touched.cp && validation.errors.cp) && <FormFeedback type="invalid">{validation.errors.cp.value ? validation.errors.cp.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-3">
                            <label className="form-label">Colonia</label>
                            <Input
                                name="colonia"
                                readOnly
                                className="inputAñadirInventario"
                                type="text"
                                value={validation.values.colonia}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label className="form-label">Nombre</label>
                            <Input
                                readOnly={!update}
                                name="nombre"
                                className="inputAñadirInventario"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nombre || ""}
                                invalid={
                                    validation.touched.nombre &&
                                        validation.errors.nombre
                                        ? true
                                        : false
                                }
                            />
                            {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                        </div>
                        <div className="col-sm-1">
                            <FormGroup>
                                <Label for="exampleSelect">Apertura</Label>
                                <Input
                                    readOnly={!update} id="exampleSelect" name="select" type="select" className="plain-select"
                                    onChange={(e) => validation.setFieldValue('dia_inicial', e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dia_inicial}
                                    invalid={
                                        validation.touched.dia_inicial &&
                                            validation.errors.dia_inicial
                                            ? true
                                            : false
                                    }
                                >
                                    {selects.dias.map((d) => (
                                        <option value={d.value} key={d.value}>{d.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {(validation.touched.dia_inicial && validation.errors.dia_inicial) && <FormFeedback type="invalid">{validation.errors.dia_inicial.value ? validation.errors.dia_inicial.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-1">
                            <FormGroup>
                                <Label for="exampleSelect">Cierre</Label>
                                <Input readOnly={!update} id="exampleSelect" name="select" type="select" className="plain-select"
                                    onChange={(e) => validation.setFieldValue('dia_final', e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dia_final}
                                    invalid={
                                        validation.touched.dia_final &&
                                            validation.errors.dia_final
                                            ? true
                                            : false
                                    }
                                >
                                    {selects.dias.map((d) => (
                                        <option value={d.value} key={d.value}>{d.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {(validation.touched.dia_final && validation.errors.dia_final) && <FormFeedback type="invalid">{validation.errors.dia_final.value ? validation.errors.dia_final.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-2">
                            <FormGroup>
                                <Label for="exampleDatetime">Apertura</Label>
                                <Input
                                    readOnly={!update}
                                    name="datetime"
                                    placeholder="datetime placeholder"
                                    type="time"
                                    onChange={(e) => validation.setFieldValue('hora_entrada', e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.hora_entrada}
                                    invalid={
                                        validation.touched.hora_entrada &&
                                            validation.errors.hora_entrada
                                            ? true
                                            : false
                                    }
                                />
                            </FormGroup>
                            {(validation.touched.hora_entrada && validation.errors.hora_entrada) && <FormFeedback type="invalid">{validation.errors.hora_entrada.value ? validation.errors.hora_entrada.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-2">
                            <FormGroup>
                                <Label for="exampleDatetime">Cierre</Label>
                                <Input
                                    readOnly={!update}
                                    name="datetime"
                                    placeholder="datetime placeholder"
                                    type="time"
                                    onChange={(e) => validation.setFieldValue('hora_salida', e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.hora_salida}
                                    invalid={
                                        validation.touched.hora_salida &&
                                            validation.errors.hora_salida
                                            ? true
                                            : false
                                    }
                                />
                            </FormGroup>
                            {(validation.touched.hora_salida && validation.errors.hora_salida) && <FormFeedback type="invalid">{validation.errors.hora_salida.value ? validation.errors.hora_salida.value : 'Error'}</FormFeedback>}
                        </div>
                        <div className="col-sm-2">
                            <label className="form-label">Stock</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={sucursal.stock_total}
                            />
                        </div>
                        <div className="col-sm-2">
                            <label className="form-label">MXN</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={Number(sucursal.precio_total).toLocaleString('en-US')}
                            />
                        </div>
                        <div className="col-sm-2">
                            <label className="form-label">Valores en USD</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={Number(sucursal.precio_total / (tipo_cambio || 20)).toLocaleString('en-US')}
                            />
                        </div>
                        <div className="col-sm-2">
                            <label className="form-label">Tipo de cambio</label>
                            <Input
                                readOnly
                                className="inputAñadirInventario"
                                value={Number(tipo_cambio).toLocaleString('en-US') || '$20'}
                            />
                        </div>

                    </div>
                </Form>
            </div>
            {/* mapa */}
            <div className='w-100'>
                <iframe className='h-100 col-12 mapSucursales' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5386.257179514498!2d-93.7345449461294!3d16.67989280057439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1708477612752!5m2!1ses!2smx" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
            </div>
            <ConfirmacionModal modal={modalConfirmacion} setModal={setModalConfirmacion} setConfirm={setConfirm} />
        </div>
    )
}
const mapStateToProps = state => ({
    detalleSucursal: state.Titles.detalle_sucursal
});

export default connect(mapStateToProps)(FormDetalle);