import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";

const Salidas = ({ movimiento, setMovimiento, producto, setProducto,addCantidad }) => {
    const [error, setError] = useState({})
    return (
        <>
            <div className="col-sm-12">
                <label className="form-label">Carga la cantidad de producto que sale</label>
                <Input
                    name="solicitado"
                    type="number"
                    className="inputAñadirInventario"
                    onChange={(e) => addCantidad(e)}
                    value={producto.solicitado}
                    invalid={error.solicitado ? true : false
                    }
                />
                {error.producto && <FormFeedback type="invalid">{error.solicitado}</FormFeedback>}
            </div>
            <div className="col-sm-4">
                <FormGroup tag="fieldset">
                    <FormGroup check>
                        <Input
                            name="radio1"
                            type="radio"
                            value={true}
                            checked={producto.donacion === true}
                            onChange={() => setProducto({ ...producto, donacion: true, nombre_mov:'Donacion'})}
                        />
                        {' '}
                        <Label check>
                            ¿Es donacion?
                        </Label>
                    </FormGroup>
                </FormGroup>

            </div>
            <div className="col-sm-4">
                <FormGroup tag="fieldset">
                    <FormGroup check>
                        <Input
                            name="radio1"
                            type="radio"
                            value={false}
                            checked={producto.donacion === false}
                            onChange={() => setProducto({ ...producto, donacion: false,nombre_mov:'Caducado' })}
                        />
                        {' '}
                        <Label check>
                            ¿Se caduco?
                        </Label>
                    </FormGroup>
                </FormGroup>

            </div>
            
        </>
    )
}
export default Salidas;