import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getReportGeneral,inventariosResource,productosProveedoresResource,productosResource } from '../../helpers/backend_helper';
import PreviewPDF from '../CommonForBoth/previewPDF';

const Periodo = ({ setFilters, filters }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [option, setOption] = useState('')
  const [modalPdf, setModalPdf] = useState({ show: false })

  const handleDateChange = (date, setter, first = false) => {
    setter(date);
    if (first) {
      setFilters({ ...filters, fecha_inicial: date })
    } else {
      setFilters({ ...filters, fecha_final: date })
    }

  };

  const handleChange = async(e) => {
    let config = {
      params: { report: option, fecha_inicial: filters.fecha_inicial, fecha_final: filters.fecha_final },
      headers: { 'Accept': e == 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      responseType: "blob"
    }
    let response = '';

    switch (option) {
      case 'ventas':
        response = await getReportGeneral(config);
        break;
      case 'compras':
        response = await getReportGeneral(config);
        break;
      case 'inventarioGeneral':
        response = await inventariosResource('get', config);
        break;
      case 'inventarioProveedores':
        response = await productosProveedoresResource('get', config)
      case 'productos':
        response = await productosResource('get', config)
      default:
        break;
    }

    if (e == 'pdf') {
      let nombre = 'Archivo';
      var reader = new FileReader();
      reader.readAsDataURL(response);
      reader.fileName = 'reporte.pdf';
      reader.onloadend = function (name) {
        name = 'reporte.pdf';
        var base64data = reader.result;
        setModalPdf({ show: true, update: false, url: base64data });
      };
    } else {
      console.log(response, 'ok')
      const url = URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Reporte.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    }

  }
  return (
    <div className='mx-4 d-flex flex-column flex-md-row justify-content-between align-items-center container-title mt-3 mb-4'>
      <div className='title-dash mb-3 mb-md-0'>
        <h5 className='text-title-dash mt-2'>Selecciona un periodo</h5>
        <DatePicker
          selected={startDate}
          onChange={(date) => handleDateChange(date, setStartDate, 'first')}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Fecha inicial"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => handleDateChange(date, setEndDate)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="Fecha final"
        />
      </div>

      <div className='container-option-title d-flex flex-column flex-md-row mx-5'>
        <h5 className='text-title-dash d-flex justify-content-center align-items-center mt-2 mx-3'>selecciona un módulo para descargarlo</h5>
        <select value={option} onChange={(e) => [setOption(e.target.value), console.log(e.target.value)]}>
          <option value="">Selecciona una opción</option>
          <option value="ventas">Ventas</option>
          <option value="compras">Compras</option>
          <option value="inventarioGeneral">Inventario general</option>
          <option value="inventarioProveedores">Inventario Proveedor</option>
          <option value="productos">Productos</option>
        </select>
        <button onClick={() => handleChange('exel')} className='button-option-download mb-2 mb-md-0 mr-md-2 d-flex align-items-center justify-content-between'> <img src='assets/images/home/download.svg' className='ms-2' /> <span className='mx-2'>Excel</span></button>
        <button onClick={() => handleChange('pdf')} className='button-option-download d-flex align-items-center justify-content-between'><img src='assets/images/home/download.svg' className='ms-2' /><span className='mx-2'>PDF</span></button>
      </div>
      <PreviewPDF setModalPdf={setModalPdf} modalPdf={modalPdf} />
    </div>
  );
}

export default Periodo;
