import React, { useState } from 'react'
import VerCompra from '../../components/Modales/Compras/VerCompra';
import { FaRegEye } from "react-icons/fa";
import { map } from 'd3-array';

const TablaHsitorial = (props) => {

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalView, setModalView] = useState('details');
  const [compras, setCompras] = useState([])
  const abrirEditar = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
      <div className="table-responsive table-responsive-sucursales table-proveedores">
        <table className="table">
          <thead className='text-start'>
            <tr className=''>
              <th scope="col">Folio</th>
              <th scope="col">Fecha de compra</th>
              <th scope="col">Fecha de entrega</th>
              <th scope="col">Total</th>
              <th scope="col">Tipo de moneda</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className='text-start'>
            {compras.length ?
              compras.map((c, index) => {
                <tr>
                  <td>8473782392</td>
                  <td>11/09/23</td>
                  <td>11/09/23</td>
                  <td>$150.00</td>
                  <td>MXN</td>
                  <td className='d-flex justify-content-center'>
                    <a className='col-6' onClick={() => abrirEditar()}><FaRegEye /></a>
                  </td>
                </tr>
              })
              :
              <tr>
                <td colSpan={5}><b>No hay registros para mostrar</b></td>
              </tr>
            }
            <tr>
              <td></td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>11/09/23</td>
              <td>11/09/23</td>
              <td>$150.00</td>
              <td>MXN</td>
              <td className='d-flex justify-content-center'>
                <a className='col-6' onClick={() => abrirEditar()}><FaRegEye /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {showEditModal && (
        <VerCompra
          onClose={() => setShowEditModal(false)}
          data={selectedItem}
          view={modalView}
          changeView={setModalView}
        />
      )}
    </div>
  )
}

export default TablaHsitorial;