import { combineReducers } from "redux"
// Authentication
import Login from "./auth/login/reducer"
//sucursales
import Sucursal from "./sucursales/reducer"
//Configuracion de acciones de titulos y titulo
import Titles from "./titles/reducer"



const rootReducer = combineReducers({
  Login,
  Sucursal,
  Titles
})

export default rootReducer
