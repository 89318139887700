import React, { useState,useEffect } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";

const Transpaso = ({ movimiento, setMovimiento, producto, setProducto, sucursalOrigen,addCantidad }) => {
    const [error, setError] = useState({})
    const [sucursales, setSucursales] = useState([])
    const [sucursal, setSucursal] = useState(null)
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    useEffect(() => {
        const getSucursales = async() =>{
            const response = await sucursalesResource('get', { params:{select: true, except:sucursalOrigen.id} });
            setSucursales(response.sucursales)
        }
        getSucursales()
    }, []);


    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <label className="form-label">Cantidad</label>
                    <Input
                        name="solicitado"
                        type="number"
                        className="inputAñadirInventario"
                        onChange={(e) => addCantidad(e)}
                        value={producto.solicitado}
                        invalid={error.solicitado ? true : false
                        }
                    />
                    {error.solicitado && <FormFeedback type="invalid">{error.solicitado}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Fecha</label>
                    <Input
                        name="fecha"
                        type="date"
                        className="inputAñadirInventario"
                        onChange={(e) => setProducto({ ...producto, fecha: e.target.value })}
                        value={producto.fecha}
                        invalid={error.fecha ? true : false
                        }
                    />
                    {error.fecha && <FormFeedback type="invalid">{error.fecha}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Origen</label>
                    <Input
                        className="inputAñadirInventario"
                        value={sucursalOrigen.nombre}
                        readOnly
                    />
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Destino</label>
                    <Select
                        styles={customStyles}
                        name="sucursal_destino_id"
                        options={sucursales} // Opciones para el Select
                        value={sucursal}
                        onChange={option => [setProducto({...producto,'sucursal_destino_id':option.id}), setSucursal(option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {error.sucursal_destino_id && <FormFeedback type="invalid">{error.sucursal_destino_id}</FormFeedback>}
                </div>

            </div>
        </>
    )
}
export default Transpaso;