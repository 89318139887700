import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { incidenciaSend } from '../../helpers/backend_helper';

import '../../styles/Home.css'

const BotonFlotante = ({ onClick }) => {
  const [modalAbierto, setModalAbierto] = useState(false);

  const toggleModal = () => {
    setModalAbierto(!modalAbierto);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Correo inválido').required('El correo es requerido'),
    modulo: Yup.string().required('El módulo es requerido'),
    incidencia: Yup.string().required('La incidencia es requerida'),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      modulo: '',
      incidencia: '',
      url: window.location.href
    },
    validationSchema,
    onSubmit: (values, { setErrors }) => {
      console.log('Formulario enviado:', values);
      const response = incidenciaSend(values)

      validation.resetForm();
      toggleModal();



    },
  });

  return (
    <>
      <div className="boton-flotante" onClick={() => { setModalAbierto(true); }}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <Modal isOpen={modalAbierto} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Formulario</ModalHeader>
        <Form
          onSubmit={e => {
            e.preventDefault();

            validation.handleSubmit();
            console.log(validation)
            return false;
          }}
        >
          <ModalBody>
            <FormGroup>
              <Label for="correo">Correo</Label>
              <Input type="email" name="email" value={validation.values.email} onChange={validation.handleChange} onBlur={validation.handleBlur} />
              {validation.touched.email && validation.errors.email && <div className="text-danger">{validation.errors.email}</div>}
            </FormGroup>
            <FormGroup>
              <Label for="modulo">Módulo</Label>
              <Input type="text" name="modulo" value={validation.values.modulo} onChange={validation.handleChange} onBlur={validation.handleBlur} />
              {validation.touched.modulo && validation.errors.modulo && <div className="text-danger">{validation.errors.modulo}</div>}
            </FormGroup>
            <FormGroup>
              <Label for="incidencia">Incidencia</Label>
              <Input type="textarea" name="incidencia" value={validation.values.incidencia} onChange={validation.handleChange} onBlur={validation.handleBlur} />
              {validation.touched.incidencia && validation.errors.incidencia && <div className="text-danger">{validation.errors.incidencia}</div>}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit'>Enviar</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default BotonFlotante;
