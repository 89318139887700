import React, { useState, useEffect } from 'react';
import { cpResource, getProveedores_tipos, estadosMunicipiosCatalogo, proveedoresResource, getPago_metodos, getPago_plazos, getRegimens, getPago_terminos } from '../../../helpers/backend_helper';
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Form, Input, FormFeedback, Button } from "reactstrap";

const DatosBancarios = (props) => {
    const [bancarios, setBancarios] = useState({
        pago_termino: null
    });

    const [selects, setSelects] = useState({
        optionsPersona: [
            { label: 'Fisica', value: 0 },
            { label: 'Moral', value: 1 }
        ],
        estados: [],
        tipos: [],
        municipios: [],
        pago_terminos: [],
        plazos: [],
        metodos: [],
    })
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: bancarios,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            values.type = 'bancarios';
            const response = await proveedoresResource('put', values, props.proveedor.id);
            if (response.status) {
                setBancarios(response.data);
                props.setUpdate(false)
            }
        },
    });

    useEffect(() => {
        const getBancario = async () => {
            const response = await proveedoresResource('show', { params: { bancarios: true } }, props.proveedor.id)
            setBancarios(response.bancarios)
        }
        getBancario()
    }, []);

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [terminos, metodos, plazos] = await Promise.all([
                    getPago_terminos(),
                    getPago_metodos(),
                    getPago_plazos()
                ]);

                setSelects({
                    ...selects,
                    pago_terminos: terminos.terminos,
                    metodos: metodos.metodos,
                    plazos: plazos.plazos
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#e9ecef',
        }),
    };

    return (
        <>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (props.update)
                        validation.handleSubmit();
                    console.log(validation)
                    return false;
                }}
            >
                <div className='tableInformacionGeneral col-11 col-lg-12 d-flex flex-column p-3'>
                    <div className='d-flex col-12 col-lg-11 justify-content-start align-items-center flex-column flex-lg-row'>
                        <div className='mt-3 mt-lg-0 d-flex col-12 col-lg-8 justify-content-center align-items-center flex-column'>
                            <div className='d-flex col-12 col-lg-12 align-self-star mx-lg-3 justify-content-between mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column flex-lg-row col-12 col-lg-12'>
                                    <div className='d-flex flex-column col-12 col-lg-6 mx-lg-3'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Términos de pago*</label>
                                        <Select
                                            isDisabled={!props.update}
                                            styles={customStyles}
                                            name="pago_termino_id"
                                            options={selects.pago_terminos}
                                            value={validation.values.pago_termino}
                                            onChange={option => [validation.setFieldValue('pago_termino_id', option.id), validation.setFieldValue('pago_termino', option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.pago_termino_id && validation.errors.pago_termino_id) && <FormFeedback type="invalid">{validation.errors.pago_termino_id}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-7 mx-3'>
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-12 flex-lg-row mx-lg-5'>
                                    <div className='d-flex flex-column col-12 col-lg-3 mx-lg-4 px-lg-2'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Banco</label>
                                        <Input
                                            disabled={!props.update}
                                            name="banco"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.banco || ""}
                                            invalid={
                                                validation.touched.banco &&
                                                    validation.errors.banco
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.banco && validation.errors.banco) && <FormFeedback type="invalid">{validation.errors.banco}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-3'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número de cuenta</label>
                                        <Input
                                            disabled={!props.update}
                                            name="cuenta_numero"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.cuenta_numero || ""}
                                            invalid={
                                                validation.touched.cuenta_numero &&
                                                    validation.errors.cuenta_numero
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.cuenta_numero && validation.errors.cuenta_numero) && <FormFeedback type="invalid">{validation.errors.cuenta_numero}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-star mx-lg-3 justify-content-between mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column flex-lg-row col-12 col-lg-12'>
                                    <div className='d-flex flex-column col-12 col-lg-3 mx-lg-2 px-lg-2'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Formas de pago</label>
                                        <Select
                                            isDisabled={!props.update}
                                            styles={customStyles}
                                            name="pago_metodo_id"
                                            options={selects.metodos}
                                            value={validation.values.pago_metodo}
                                            onChange={option => [validation.setFieldValue('pago_metodo_id', option.id), validation.setFieldValue('pago_metodo', option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.pago_metodo_id && validation.errors.pago_metodo_id) && <FormFeedback type="invalid">{validation.errors.pago_metodo_id}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-3'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Monto de crédito</label>
                                        <Input
                                            disabled={!props.update}
                                            name="proveedor.credito_monto"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.proveedor?.credito_monto || ""}
                                            invalid={
                                                validation.touched.proveedor?.credito_monto &&
                                                    validation.errors.proveedor?.credito_monto
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.proveedor?.credito_monto && validation.errors.proveedor?.credito_monto) && <FormFeedback type="invalid">{validation.errors.proveedor.credito_monto}</FormFeedback>}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-12 flex-lg-row mx-md-0 mx-lg-5'>
                                    <div className='d-flex flex-column col-12 col-lg-7 mx-lg-2 px-lg-4'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>CLABE Interbancaria</label>
                                        <Input
                                            disabled={!props.update}
                                            name="clabe"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.clabe || ""}
                                            invalid={
                                                validation.touched.clabe &&
                                                    validation.errors.clabe
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.clabe && validation.errors.clabe) && <FormFeedback type="invalid">{validation.errors.clabe}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-star mx-lg-3 justify-content-between mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column flex-lg-row col-12 col-lg-12'>
                                    <div className='d-flex flex-column col-12 col-lg-3 mx-lg-2 px-lg-2'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Tiempos de entrega</label>
                                        <Select
                                            isDisabled={!props.update}
                                            styles={customStyles}
                                            name="entrega_tiempo_id"
                                            options={selects.plazos}
                                            value={validation.values.entrega_tiempo}
                                            onChange={option => [validation.setFieldValue('entrega_tiempo_id', option.id), validation.setFieldValue('entrega_tiempo', option)]}
                                            getOptionLabel={(option) => `${option.nombre}`}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {(validation.touched.entrega_tiempo_id && validation.errors.entrega_tiempo_id) && <FormFeedback type="invalid">{validation.errors.entrega_tiempo_id}</FormFeedback>}
                                    </div>
                                    <div className='d-flex flex-column col-12 col-lg-3'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Costos de envio</label>
                                        <Input
                                            disabled={!props.update}
                                            name="proveedor.costo_envio"
                                            className="inputDetalleProveedor"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.proveedor?.costo_envio || ""}
                                            invalid={
                                                validation.touched.proveedor?.costo_envio &&
                                                    validation.errors.proveedor?.costo_envio
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {(validation.touched.proveedor?.costo_envio && validation.errors.proveedor?.costo_envio) && <FormFeedback type="invalid">{validation.errors.proveedor?.costo_envio}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.update &&
                        <button className={`btnVer col-3 mx-2`} type='submit'>
                            Guardar
                        </button>
                    }
                </div>
            </Form>
        </>

    );
}

export default DatosBancarios;
