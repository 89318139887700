import React, { useState, useEffect } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import PasswConfirm from "../../components/CommonForBoth/PasswConfirm";
import { movimientosResource } from "../../helpers/backend_helper";
import Select from "react-select";
import { inventariosResource } from "../../helpers/backend_helper";
import { FaRegTrashAlt } from 'react-icons/fa';
import { ToastHelper } from "../../helpers/alerts/alertHelper";

const Actionmodal = ({ modal, setModal, movimiento, setMovimiento, confirm, setFilters, filters, sucursal }) => {
    //Modificacion
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const productoBase = {
        nombre: '',
        id: null,
        cantidad: '',
        solicitado: '',
        stock: false
    }
    const [suma, setSuma] = useState(movimiento.precio_total)
    const [productos, setProductos] = useState(movimiento.productosData)
    const [inventarios, setInventarios] = useState([])
    const [producto, setProducto] = useState({ ...productoBase })
    /*****/
    const [confirmPass, setConfirmPass] = useState({ show: false })
    const save = async () => {
        const response = await movimientosResource('put', movimiento, movimiento.id);
        if (response.status) {
            setFilters({ ...filters });
            setMovimiento({ id: null, movimiento_tipo: { nombre: '' }, colaborador: { name: '' }, productosData: [] });
            setModal({ show: false });
        }
    }

    useEffect(() => {
        const getInventarios = async () => {
            const response = await inventariosResource('show', { params: { select: true } }, sucursal.id);
            if (response.status) {
                setInventarios(response.inventarios)
            }
        }
        getInventarios()
    }, []);

    const addProducto = () => {

    }

    const handleChange = (e, id) => {
        let canSave = true;
        let sumaTotal = 0;
        inventarios.map((inv) => {
            console.log(inv.producto.id, id)
            if (inv.id == id) {
                if (inv.cantidad < e.target.value) {
                    canSave = false;
                }
            }
        })
        if (canSave) {
            let arrayMov = movimiento.productosData.map((p) => {
                if (p.producto_id == id) {
                    p.cantidad = e.target.value
                }
                sumaTotal += p.cantidad + p.producto.producto.precio
                return p;
            })
            setSuma(sumaTotal)
            setMovimiento({ ...movimiento, productosData: arrayMov })
        } else {
            ToastHelper('Error superaste la cantidad disponible para este producto', 'error');
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>{movimiento.id}-{movimiento.movimiento_tipo.nombre}</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    {modal.action == 'cancel' &&
                        <div className="row" style={{ textAlign: 'center' }}>
                            <p>Estás a punto de cancelar un informe de movimiento,</p>
                            <p>¿Estás seguro de la acción? Es un proceso irreversible.</p>
                        </div>
                    }
                    {modal.action == 'modification' &&
                        <div className="row" >
                            <ul>
                                <li style={{ listStyleType: 'none' }}>Colaborador que realizo el movimiento:{movimiento.colaborador.name}</li>
                                <li style={{ listStyleType: 'none' }}>Fecha del movimiento: {movimiento.created_at}</li>
                                <li style={{ listStyleType: 'none' }}>Cantidad de productos: {movimiento.cantidad}</li>
                                <li style={{ listStyleType: 'none' }}>Desglose de productos:</li>
                            </ul>

                            <div className="col-sm-12">
                                <Select
                                    styles={customStyles}
                                    options={inventarios} // Opciones para el Select
                                    value={producto}
                                    onChange={option => [addProducto({ ...option, donacion: false, stock: 0 })]}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                <br />
                            </div>
                            {movimiento.productosData.length && movimiento.productosData.map((p) => (
                                <div className="col-sm-12" key={p.id}>
                                    <div className="row" >
                                        <div className="col-sm-2" style={{ textAlign: 'center' }}>
                                            <FaRegTrashAlt />
                                        </div>
                                        <div className="col-sm-5">
                                            <Input
                                                name="producto"
                                                type="text"
                                                value={p.producto_nombre}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-sm-1" >Cant.</div>
                                        <div className="col-sm-3" >
                                            <Input
                                                name="producto"
                                                type="text"
                                                value={p.cantidad}
                                                onChange={(e) => handleChange(e, p.producto_id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {productos.length ? productos.map((p) => (
                                <div className="col-sm-12" key={p.id}>
                                    <div className="row" >
                                        <div className="col-sm-2" style={{ textAlign: 'center' }}>
                                            <FaRegTrashAlt />
                                        </div>
                                        <div className="col-sm-5">
                                            <Input
                                                name="producto"
                                                type="text"
                                                value={p.producto_nombre}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-sm-1" >Cant.</div>
                                        <div className="col-sm-3" >
                                            <Input
                                                name="producto"
                                                type="text"
                                                value={p.cantidad}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                                :
                                ''
                            }

                        </div>
                    }
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                    <Button color="primary" onClick={() => [setMovimiento({ ...movimiento, data: { action: modal.action, estatus_id: modal.action=='cancel' ? 5:(modal.action=='modification' ? 3:3), messageAction: modal.action=='cancel' ? 'Movimiento Cancelado':(modal.action=='modification' ? 'Se modifico el movimiento y se atendio':'Se atendio el movimiento'), productosModificados: modal.action=='modification' ? true:false } }), setConfirmPass({ show: true })]}>
                        Confirmar
                    </Button>
                {' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
            <PasswConfirm modal={confirmPass} setModal={setConfirmPass} save={save} />
        </Modal>
    )
}
export default Actionmodal;