import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Table, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getCompras_tipos, productosResource } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";

const Step2 = ({ modal, setModal, compra, setCompra, setStep, productos, setProductos }) => {

    const [producto, setProducto] = useState(null);

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 1) {
            const options = await productosResource('get', { params: { search: inputValue,proveedor_id:compra.proveedor_id } });
            callback(options.productos);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: compra,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            setCompra(values)
            setStep({ step: 2, name: 'Paso 2 - Selección de productos', size: 'ls' })
        },
    });

    const save = () => {
        setProductos([...productos, producto])
        setProducto(null)
    }

    const nextStep = () => {
        setStep({ step: 3, name: 'Paso 3 - Añadir compra', size: 'ls' })
    }

    const productChange = (e, p,name) => {
        console.log(e)
        let array = productos.map((prod) => {
            if (prod.id == p.id) {
                if(name=='cantidad_solicitada'){
                    p.cantidad_solicitada = e.target.value
                }else{
                    p.precio_unitario = e.target.value 
                }
                
            }
            return prod
        })
        setProductos(array)
    }

    const productCad = (e, p,name) => {
        console.log(e)
        let array = productos.map((prod) => {
            if (prod.id == p.id) {
                p.caducidad = e.target.value
            }
            return prod
        })
        setProductos(array)
    }



    
    
    return (
        <div className="row">
            <div className="col-sm-12">
                <label className="form-label">Buscador de productos </label>
                <AsyncSelect
                    styles={customStyles}
                    cacheOptions
                    defaultOptions
                    value={producto}
                    onChange={option => [setProducto(option)]}
                    loadOptions={loadOptions}
                    getOptionLabel={(option) => `${option.nombre}`}
                    getOptionValue={(option) => option.id}
                />
                <br />
            </div>
            <div className="col-sm-12">
                <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                    <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: 'transparent' }}>SKU</th>
                                <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                <th style={{ backgroundColor: 'transparent' }}>Caducidad</th>
                                <th style={{ backgroundColor: 'transparent' }}>Cantidad</th>
                                <th style={{ backgroundColor: 'transparent' }}>Precio unitario</th>
                                <th style={{ backgroundColor: 'transparent' }}>IVA</th>
                                <th style={{ backgroundColor: 'transparent' }}>IEPS</th>
                                
                                <th style={{ backgroundColor: 'transparent' }}>Presentación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.length && productos.map((prod, i) => (
                                <tr key={i}>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        {prod.sku}
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        {prod.nombre}
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        <Input
                                            name="caducidad"
                                            type="date"
                                            className="inputAñadirInventario"
                                            onChange={(e) => productCad(e, prod,'caducidad')}
                                            value={prod.caducidad || ""}
                                        />
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        <Input
                                            name="cantidad_solicitada"
                                            className="inputAñadirInventario"
                                            onChange={(e) => productChange(e, prod,'cantidad_solicitada')}
                                            value={prod.cantidad_solicitada || ""}

                                        />
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        <Input
                                            name="precio_unitario"
                                            className="inputAñadirInventario"
                                            onChange={(e) => productChange(e, prod,'precio_unitario')}
                                            value={prod.precio_unitario || ""}

                                        />
                                    </th>
                                    
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        {prod.iva ? `${prod.iva}%`:'Excento'}
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        {prod.ieps ? `${prod.ieps}%`:'Excento'}
                                    </th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        {prod.unidad_medida?.nombre}
                                    </th>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            
            {productos.length ?
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit" onClick={() => nextStep()}>
                        Continuar
                    </Button>{' '}
                </div>
                :
                ''
            }
        
            {producto &&
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block onClick={() => save()}>
                        Añadir producto
                    </Button>{' '}
                </div>
            }

        </div>
    )
}

export default Step2