import React from 'react';

const CardCiudad = ({ nombre, estado, sucursales, creado }) => {
  return (
    <div className='cardCiudad text-start mx-4'>
        <h6 className='p-1 titleCiudadCardSpace mt-2' style={{ fontSize: "0.9em" }}></h6>
        <h6 className='p-1 titleCiudadCard mt-3 text-center font-weight-bold' style={{ fontSize: "0.9em" }}>{nombre}</h6>
        <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Estado: <span className='descriptionCardCiudad'>{estado}</span></h6>
        <h6 className='spaceCardCiudad' style={{ fontSize: "0.9em" }}>Sucursales: <span className='descriptionCardCiudad'>{sucursales}</span></h6>
        <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}>Creado: <span className='descriptionCardCiudad'>{creado}</span></h6>
        <h6 className='spaceCardCiudad spaceCardCiudadLast' style={{ fontSize: "0.9em" }}></h6>
    </div>
  )
}

export default CardCiudad;
