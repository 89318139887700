import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';

const ChartBarras = () => {
  const options = useMemo(() => ({
    color: ['#F94144', '#F9C74F'], // Colores para las barras
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
        orient: 'horizontal',
        type: 'plain',
        bottom: '3%',
        itemGap: 10, // Puedes ajustar esto según sea necesario
        itemWidth: 25, // Puedes ajustar esto según sea necesario
        itemHeight: 8,
        icon: 'circle',
        align: 'left',
        width: '50%', // Ajusta el ancho de la leyenda para permitir que los elementos se distribuyan en filas de 3
        // Asegúrate de que el contenedor del gráfico sea lo suficientemente ancho para permitir 3 elementos por fila
      },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '13%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: ['Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'] // Meses en el eje Y
    },
    series: [
      {
        name: 'Pagar',
        type: 'bar',
        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6] // Ejemplo de datos para "Pagar"
      },
      {
        name: 'Cobrar',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6] // Ejemplo de datos para "Cobrar"
      }
    ]
  }),[]);

  return (
    <div className='cardModulo p-3 text-start mx-4'>
      <h2>Grafica para finanzas-Contabilidad</h2>
      <ReactECharts option={options} style={{ height: 400 }} />
    </div>
  )
}

export default ChartBarras;
