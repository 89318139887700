import React, { useState, useEffect } from "react";

import AsyncSelect from 'react-select/async';
import { productosResource, getCategorias, getUnidad_medidas, productosProveedoresResource, getSat_catalogo } from "../../../helpers/backend_helper";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { FaTrash } from 'react-icons/fa';
import { Form, Input, FormFeedback, Button, Table, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label } from "reactstrap";

const EditProduct = ({ setModal, modal, setFilters, filters }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const save = async () => {

        if (response.status) {
            setFilters({ ...filters })
            setModal({ show: false })
        }
    }
    const [selects, setSelects] = useState({
        categorias: [],
        unidades: [],
        sat: []
    })

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: modal.producto,
        validationSchema: Yup.object({
            producto: Yup.object().shape({
                nombre: Yup.string().required("Nombre del producto es requerido"),
                descripcion: Yup.string().required("Descripción es requerida"),
                categoria_id: Yup.number().required("Departamento es requerido"),
                cantidad_unidad: Yup.number()
                    .typeError("Cantidad debe ser un número")
                    .positive("Cantidad debe ser positiva")
                    .required("Cantidad es requerida"),
            }),
            producto_sat_codigo_id: Yup.number().required(
                "Codigo de producto del SAT es requerido"
            ),
            
            precio: Yup.number()
                .typeError("Precio debe ser un número")
                .positive("Precio debe ser positivo")
                .required("Precio es requerido"),
            unidad_medida_id: Yup.number().required("Unidad es requerida"),
        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await productosProveedoresResource('put', values, values.id)
            if (response.status) {
                //setFilters({ ...filters })
                setModal({ show: false })
                validation.resetForm();
            }

        },
    });
    console.log(modal.producto)
    useEffect(() => {
        const getSelects = async () => {
            try {
                const [categorias, unidades, sat] = await Promise.all([
                    getCategorias(),
                    getUnidad_medidas(),
                    getSat_catalogo()
                ]);
                setSelects({
                    ...selects,
                    categorias: categorias.categorias,
                    unidades: unidades.unidad_medidas,
                    sat: sat.sat
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const editProduct = (product) => {
        let newlistado = productos.filter(i => i.nombre != product.nombre)
        setProductos(newlistado)
    }

    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={'ls'}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Editar Producto
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            Añadir productos
                        </div>
                        <div className="col-sm-12">

                            <div className="row">
                                <div className="col-sm-12">
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();

                                            validation.handleSubmit();
                                            console.log(validation)
                                            return false;
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label className="form-label">Nombre del producto</label>
                                                <Input
                                                    name="producto.nombre"
                                                    className="inputAñadirInventario"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.producto?.nombre || ""}
                                                    invalid={
                                                        validation.touched.producto?.nombre &&
                                                            validation.errors.producto?.nombre
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.producto?.nombre && validation.errors.producto?.nombre) && <FormFeedback type="invalid">{validation.errors.producto?.nombre}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-12">
                                                <label className="form-label">Description</label>
                                                <Input
                                                    name="producto.descripcion"
                                                    className="inputAñadirInventario"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.producto?.descripcion || ""}
                                                    invalid={
                                                        validation.touched.producto?.descripcion &&
                                                            validation.errors.producto?.descripcion
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.producto?.descripcion && validation.errors.producto?.descripcion) && <FormFeedback type="invalid">{validation.errors.producto?.descripcion}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-12">
                                                <label className="form-label">Codigo de producto del SAT</label>
                                                <Select
                                                    styles={customStyles}
                                                    name="producto_sat_codigo_id"
                                                    options={selects.sat}
                                                    value={validation.values.producto_sat_codigo}
                                                    onChange={option => [validation.setFieldValue('producto_sat_codigo_id', option.id), validation.setFieldValue('producto_sat_codigo', option)]}
                                                    getOptionLabel={(option) => `${option.nombre}`}
                                                    getOptionValue={(option) => option.id}
                                                />
                                                {(validation.touched.producto_sat_codigo_id && validation.errors.producto_sat_codigo_id) && <FormFeedback type="invalid">{validation.errors.producto_sat_codigo_id}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Departamento</label>
                                                <Select
                                                    styles={customStyles}
                                                    name="producto.categoria_id"
                                                    options={selects.categorias}
                                                    value={validation.values.categoria}
                                                    onChange={option => [validation.setFieldValue('producto.categoria_id', option.id), validation.setFieldValue('categoria', option)]}
                                                    getOptionLabel={(option) => `${option.nombre}`}
                                                    getOptionValue={(option) => option.id}
                                                />
                                                {(validation.touched.producto?.categoria_id && validation.errors.producto?.categoria_id) && <FormFeedback type="invalid">{validation.errors.producto?.categoria_id}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Precio</label>
                                                <Input
                                                    name="precio"
                                                    className="inputAñadirInventario"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.precio || ""}
                                                    invalid={
                                                        validation.touched.precio &&
                                                            validation.errors.precio
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.precio && validation.errors.precio) && <FormFeedback type="invalid">{validation.errors.precio}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Unidad</label>
                                                <Select
                                                    styles={customStyles}
                                                    name="unidad_medida_id"
                                                    options={selects.unidades}
                                                    value={validation.values.unidad_medida}
                                                    onChange={option => [validation.setFieldValue('unidad_medida_id', option.id), validation.setFieldValue('unidad_medida', option)]}
                                                    getOptionLabel={(option) => `${option.nombre}`}
                                                    getOptionValue={(option) => option.id}
                                                />
                                                {(validation.touched.unidad_medida_id && validation.errors.unidad_medida_id) && <FormFeedback type="invalid">{validation.errors.unidad_medida_id}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Cantidad</label>
                                                <Input
                                                    name="producto.cantidad_unidad"
                                                    className="inputAñadirInventario"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.producto?.cantidad_unidad || ""}
                                                    invalid={
                                                        validation.touched.producto?.cantidad_unidad &&
                                                            validation.errors.producto?.cantidad_unidad
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {(validation.touched.producto?.cantidad_unidad && validation.errors.producto?.cantidad_unidad) && <FormFeedback type="invalid">{validation.errors.producto?.cantidad_unidad}</FormFeedback>}
                                            </div>
                                            <div className="col-sm-8" style={{ textAlign: 'right' }}>
                                                <Button color="primary" block type="submit">
                                                    Añadir producto
                                                </Button>{' '}
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}
export default EditProduct;