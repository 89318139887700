import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PreviewPDF = ({modalPdf,setModalPdf}) => {
    const [pending, setPending] = useState(false);

    return (
        <Modal isOpen={modalPdf.show} className="modal-dialog modal-xl">
                <ModalHeader className="bg-primary">Archivo</ModalHeader>
                <ModalBody>
                    <div className="row">
                     <iframe
                        src={`${modalPdf.url}`}
                        
                        allowFullScreen
                        style={{
                            overflow: "auto",
                            height: "500px",
                            width: "100%",
                        }}
                    ></iframe>   
                    </div>  
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalPdf({show:false})}>Cancelar</Button>
                </ModalFooter>
            </Modal>
    );
};

export default PreviewPDF;
