import React, { useMemo, useEffect, useState } from "react";
import { PaginationNo, FilterSearch } from '../../components/CommonForBoth/tables/pagination';
import { ventasResource, sucursalesResource, getReportGeneral } from "../../helpers/backend_helper";
import PreviewPDF from "../../components/CommonForBoth/previewPDF";
import { FaEye } from "react-icons/fa";
//import DetalleCompra from '../modals/DetalleCompra';
import CancelarModal from "./modals/CancelarModal";
import MasivaClientes from "./modals/MasivaClientes";

const ListVentas = ({ filters, setFilters, ordenes }) => {
    const [masivaClientes, setMasivaClientes] = useState({ show: false })
    const headersTable = useMemo(() => [
        { name: 'Noº orden', class: '' },
        { name: 'Estado de venta', class: '' },
        { name: 'Noº Factura', class: '' },
        { name: 'Noº Tiket', class: '' },
        { name: 'Cliente', class: 'justify-content-center text-center' },
        { name: 'Fecha venta', class: 'justify-content-center text-center' },
        { name: 'Responsable', class: 'justify-content-center text-center' },
        { name: 'Productos', class: 'justify-content-center text-center' },
        { name: 'Total', class: 'justify-content-center text-center' },
        { name: 'Documentos', class: 'justify-content-center text-center' },
        { name: 'Acciones', class: 'justify-content-center text-center' },
    ], []);
    const [modalPdf, setModalPdf] = useState({ show: false })

    const [meta, setMeta] = useState({})
    const [sucursales, setSucursales] = useState({})
    const [ventas, setVentas] = useState([]);
    const [modalDetalleVenta, setModalDetalleVenta] = useState({ show: false, venta: {} })
    const [cancelar, setCancelar] = useState({ show: false })

    useEffect(() => {
        const getVentas = async () => {
            const response = await ventasResource('get', { params: filters })
            setVentas(response.ventas)
        }
        getVentas()
    }, [filters]);

    useEffect(() => {
        const getSucursales = async () => {
            const response = await sucursalesResource('get', { params: { select: true } })
            setSucursales(response.sucursales)
        }
        getSucursales()
    }, []);

    const getReport = async (report) => {
        let config = {
            params: { report: 'ventas', fecha_inicial: filters.fecha_inicial, fecha_final: filters.fecha_final },
            headers: { 'Accept': report.report == 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
            responseType: "blob"
        }

        const response = await getReportGeneral(config);
        if (report.report == 'pdf') {
            let nombre = 'Archivo';
            var reader = new FileReader();
            reader.readAsDataURL(response);
            reader.fileName = 'reporte.pdf';
            reader.onloadend = function (name) {
                name = 'reporte.pdf';
                var base64data = reader.result;
                setModalPdf({ show: true, update: false, url: base64data });
            };
        } else {
            console.log(response, 'ok')
            const url = URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                'Reporte.xlsx'
            )
            document.body.appendChild(link)
            link.click()
        }
    }

    return (
        <>
            <FilterSearch reports={getReport} filters={filters} setFilters={setFilters} masiva={setMasivaClientes} title={'Importar Clientes de Excel'} sucursales={sucursales} />
            {ordenes &&
                <div>
                    <button className='buttonProveedores button-modal-eliminacion col-12 col-lg-2' onClick={() => setModal({ show: true })}>Nueva Orden</button>
                </div>
            }
            <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
                <div className="table-responsive table-responsive-sucursales">
                    <table className="table table-sucursales">
                        <thead className='text-center'>
                            <tr>
                                {headersTable.map((h, i) => (
                                    <th key={i} className={h.class}>{h.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {ventas.length ? ventas.map((v, i) => (
                                <tr key={i}>
                                    <th>{v.id}</th>
                                    <th>{v.estatus.nombre}</th>
                                    <th>{v.estatus.nombre}</th>
                                    <th>{v.tiket}</th>
                                    <th>{v.cliente.nombre}</th>
                                    <th>{v.fecha}</th>
                                    <th>{v.responsable.name}</th>
                                    <th>{v.cantidad_total}</th>
                                    <th>{v.precio_total}</th>
                                    <th>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' disabled onClick={() => setModalDetalle({ show: true, cotizacion: c })}>Ver Cotización</button>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setModalPdf({ show: true, url: c.docCot })}>Ver factura</button>
                                    </th>
                                    <th>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setCancelar({ show: true, venta: v })}>Cancelar</button>
                                    </th>
                                </tr>
                            ))
                                :
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={16} style={{ textAlign: 'center' }}>
                                        No hay registros para mostrar
                                    </th>
                                </tr>
                            }
                            {/* Puedes agregar más filas aquí */}
                        </tbody>
                    </table>
                    <PaginationNo filters={filters} setFilters={setFilters} />
                </div>

            </div>
            <PreviewPDF modalPdf={modalPdf} setModalPdf={setModalPdf} />
            <CancelarModal setModal={setCancelar} modal={cancelar} />
            {masivaClientes.show &&
                <MasivaClientes modal={masivaClientes} setModal={setMasivaClientes} />
            }
        </>
    )
}

export default ListVentas;

