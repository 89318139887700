import React, { useMemo, useEffect, useState } from "react";
import { PaginationNo, FilterSearch } from '../../components/CommonForBoth/tables/pagination';
import { cotizacionesResource } from "../../helpers/backend_helper";
import NewCotizacion from "./modals/NewCotizacion";
import CotizacionDetalle from "./modals/CotizacionDetalle"
import PreviewPDF from "../../components/CommonForBoth/previewPDF";
import MasivaCotizacion from './modals/MasivaCotizacion'

const TablaCotizaciones = ({ filters, setFilters }) => {
  const headersTable = useMemo(() => [
    { name: 'Noº Cotización', class: '' },
    { name: 'Estado de compra', class: '' },
    { name: 'Estatus de proveedor', class: '' },
    { name: 'Proveedor', class: '' },
    { name: 'Acciones', class: 'justify-content-center text-center' },
  ], []);
  
  const [modalPdf, setModalPdf] = useState({ show: false })
  const [meta, setMeta] = useState({})
  const [cotizaciones, setCotizaciones] = useState([]);
  const [modal, setModal] = useState({ show: false });
  const [modalDetalle, setModalDetalle] = useState({ show: false, cotizacion: {} })

  useEffect(() => {
    const getCotizaciones = async () => {
      const response = await cotizacionesResource('get', { params: filters })
      setCotizaciones(response.cotizaciones)
    }
    getCotizaciones()
  }, [filters]);
  const [masiva, setMasiva] = useState({ show: false })
  return (
    <>
      
      <div>
        <button className='buttonProveedores button-modal-eliminacion col-12 col-lg-2' onClick={() => setModal({ show: true })}>Nueva cotizacion</button>
      </div>
      <FilterSearch filters={filters} setFilters={setFilters} masiva={setMasiva} title={'Importar Cotizaciones de Excel'}/>
      <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
        <div className="table-responsive table-responsive-sucursales">
          <table className="table table-sucursales">
            <thead className='text-center'>
              <tr>
                {headersTable.map((h, i) => (
                  <th key={i} className={h.class}>{h.name}</th>
                ))}
              </tr>
            </thead>
            <tbody className='text-center'>
              {cotizaciones.length ? cotizaciones.map((c) => (
                <tr key={c.id}>
                  <td style={{ textAlign: 'center' }}>{c.id}</td>

                  <td style={{ textAlign: 'center' }}><button className={c.estatus.id == 6 ? 'indicadorAccionesYellow fontBold600 col-9' : 'indicadorAccionesGreen col-9'}>{c.estatus.nombre}</button></td>
                  {c.nota ? 
                    <td style={{ textAlign: 'center' }}><button className={c.estatus.id == 6 ? 'indicadorAccionesGreen fontBold600 col-9' : 'indicadorAccionesGreen fontBold600 col-9'}>{'Recibido'}</button></td>
                    :
                    <td style={{ textAlign: 'center' }}><button className={c.estatus.id == 6 ? 'indicadorAccionesYellow fontBold600 col-9' : 'indicadorAccionesYellow fontBold600 col-9'}>{'Email enviado'}</button></td>
                  }
                  

                  <td style={{ textAlign: 'center' }}>{c.proveedor.nombre}</td>
                  <td>
                    <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setModalDetalle({ show: true, cotizacion: c })}>Ver Cotización</button>
                    {c.nota ? 
                      <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setModalPdf({ show: true, url: c.docCot })}>Ver Respuesta</button>
                    :
                    <button className='indicadorAccionesNegro fontBold600 col-6' style={{background:'gray'}} disabled>Ver Respuesta</button>
                    }
                  </td>

                </tr>
              ))
                :
                <tr style={{ textAlign: 'center' }}>
                  <th colSpan={16} style={{ textAlign: 'center' }}>
                    No hay registros para mostrar
                  </th>
                </tr>
              }
              {/* Puedes agregar más filas aquí */}
            </tbody>
          </table>
          <PaginationNo filters={filters} setFilters={setFilters} />
        </div>
        <NewCotizacion modal={modal} setModal={setModal} />
        {modalDetalle.show &&
          <CotizacionDetalle modal={modalDetalle} setModal={setModalDetalle} />
        }
        <PreviewPDF setModalPdf={setModalPdf} modalPdf={modalPdf} />
        {masiva.show &&
        <MasivaCotizacion modal={masiva} setModal={setMasiva}/>
      }
      </div>
    </>
  )
}

export default TablaCotizaciones;