import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const ModalProveedor = ({ modal, setModal }) => {
    const [proveedor,setProveedor] = useState({
        nombre:'',
        telefono:'',
        web:'',
        proveedor_tipos_id:'',
        tipo:null,
        tipo_id:'',
        contrato:'',
        credito_monto:'',
        datos_fiscales_id:'',
        datos_fiscales:null,
        direccion_id:'',
        direccion:null,
        accreedor:null,
        municipio_id:null,
        municipio:null,
        fiscales:{
            estado:null,
            municipio_id:null,
            municipio:null,
            catalogo:'',
            cp:null
        },
        banco:{

        }
    })
    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    const [step, setStep] = useState({step:1,name:'Paso 1 - Informacion de la persona fisica o moral',size:'ls'});
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Añadir proveedor
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            {step.name}
                            <div className="text-center">
                                {step.step} of 5
                            </div>
                            <Progress
                                animated
                                max="5"
                                value={step.step}
                                style={{ height: '7px' }}
                            />
                        </div>
                        <div className="col-sm-12">
                            {step.step == 1 &&
                                <Step1 setStep={setStep} getBase64={getBase64} proveedor={proveedor} setProveedor={setProveedor} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 2 &&
                                <Step2 setStep={setStep} getBase64={getBase64} proveedor={proveedor} setProveedor={setProveedor} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 3 &&
                                <Step3 setStep={setStep} proveedor={proveedor} setProveedor={setProveedor} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 4 &&
                                <Step4 setStep={setStep} getBase64={getBase64} proveedor={proveedor} setProveedor={setProveedor} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 5 &&
                                <Step5 setStep={setStep} getBase64={getBase64} proveedor={proveedor} setProveedor={setProveedor} modal={modal} setModal={setModal}/>
                            }
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </Modal>
    )
}
export default ModalProveedor