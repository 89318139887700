import React, { useEffect, useState } from 'react';
import DatosBancarios from './Detalle/DatosBancarios';
import DatosFiscales from './Detalle/DatosFiscales';
import DocumentosLegales from './Detalle/DocumentosLegales';
import InformacionGeneral from './InformacionGeneral';
import Opciones from './Opciones';
import OpcionesDetalleProveedor from './OpcionesDetalleProveedor';
import "../../styles/Compras.css";
import { proveedoresResource } from '../../helpers/backend_helper';
import { useParams } from 'react-router-dom';
import TablaHistorial from './TablaHistorial';
import ProductosOfrece from './ProductosOfrece';
import AddProducto from '../../components/Modales/Compras/AddProducto';
import AddProductos from './modals/AddProductos';
import TablaDetalleEvaluacion from './modals/TablaDetalleEvaluacion';
import EvaluarProveedor from './modals/EvaluarProveedor';

const Proveedor = () => {
    const { id } = useParams()
    const [update,setUpdate] = useState(false)
    const [filters, setFilters] = useState({get:false});
    const [showModalAdd,setShowModalAdd] = useState({show:false,detalle:true})
    
    const [showEvaluar, setShowEvaluar] = useState(false);
    const [showEliminar, setShowEliminar] = useState(false);
    const [modalView, setModalView] = useState('details'); 


    const [proveedor, setProveedor] = useState({
        direccion:{cp:{asentamiento:''}}
    });
    useEffect(() => {
        const getProveedor = async () => {
            const response = await proveedoresResource('show', {}, id)
            setProveedor(response.proveedor)
        }
        getProveedor()
    }, []);

    const [opcionActiva, setOpcionActiva] = useState('Informacion general');

    const componentesTabla = {
        'Informacion general': InformacionGeneral,
        'Datos fiscales': DatosFiscales,
        'Datos bancarios': DatosBancarios,
        'Documentos legales': DocumentosLegales,
    };

    const handleClickOpcion = (opcion) => {

        setOpcionActiva(opcion);
    };

    const ComponenteSeleccionado = componentesTabla[opcionActiva];

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <div>
            <Opciones onClick={[handleClickOpcion]} opcionActiva={opcionActiva} vista={"detalle"} update={update} setUpdate={setUpdate}/>
            <OpcionesDetalleProveedor
                opcionActiva={opcionActiva}
                onClickOpcion={handleClickOpcion}
            />
            <div className='mx-1'>

                {/* Renderiza el componente seleccionado */}
                {ComponenteSeleccionado && <ComponenteSeleccionado proveedor={proveedor} setProveedor={setProveedor} update={update} setUpdate={setUpdate} getBase64={getBase64}/>}
            </div>
            <div className='mt-5 col-12 d-flex flex-column flex-lg-row'>
                <div className='col-11 col-lg-5'>
                    <div className=' d-flex align-items-center mb-2 mt-lg-0 mb-lg-0'>
                        <span className='titleHistorial fontBold600'>Historial de compras</span>
                        <img className='mx-3' src={`${process.env.PUBLIC_URL}/assets/images/settings.svg`} />
                    </div>
                    <TablaHistorial proveedor={proveedor}/>
                </div>
                <div className='col-11 col-lg-6 mx-lg-5'>
                    <div className=' d-flex align-items-center justify-content-between flex-column flex-lg-row mt-4 mt-lg-0'>
                        <div className='d-flex align-items-center mb-2 mt-lg-0 mb-lg-0'>
                            <span className='titleHistorial fontBold600'>Productos que ofrece</span>
                            <img className='mx-3' src={`${process.env.PUBLIC_URL}/assets/images/settings.svg`} />
                        </div>
                        <button onClick={() => setShowModalAdd({...showModalAdd,show:true,detalle:true})} className='buttonAgregraDetalle px-2 mt-2 mb-2 mt-lg-0 mb-lg-0'>Agregar productos</button>
                    </div>
                    <ProductosOfrece proveedor={proveedor} filters={filters} setFilters={setFilters}/>
                </div>
            </div>
            <AddProductos proveedor={proveedor} setProveedor={setProveedor} modal={showModalAdd} setModal={setShowModalAdd}  filters={filters} setFilters={setFilters}/>
            
            {false && <EvaluarProveedor 
            onClose={() => setShowEvaluar(false)}
            view={modalView}
            changeView={setModalView} />}
        </div>
    );
};

export default Proveedor;
