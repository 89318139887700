import React, { useState } from "react";
import { Container, Button, Table } from "reactstrap";
import ModalProducto from './modals/ModalProducto'
import {useNavigate, useParams, Link } from "react-router-dom";
import { productosResource, primerInvSucursal } from "../../helpers/backend_helper";
import Swal from 'sweetalert2'


const PrimerInventario = () => {
    const navigate = useNavigate();
    const { id, token } = useParams()
    const [productos, setProductos] = useState([]);
    const [modalProducto, setModalProducto] = useState({ show: false, update: false })
    const save = async (save=true) => {
        const response = await primerInvSucursal({ productos }, id);
        if (response.status) {
            returSucursal();
        }
    }

    const returSucursal = () => {
        Swal.fire({
            title: 'Sucursal Creada',
            text: "Se ha creado con éxito la sucursal.",
            confirmButtonText: "Aceptar",
            customClass: {
                confirmButton: 'swal2-confirm-custom !important' 
            },
            willClose: () => {
                navigate(`/sucursal/${id}`);
            }
        });
    }
    return (
        <div className="d-flex">
            <main className="flex-grow-1">
                <p>Paso 3. Ingresar inventario</p>
                <Container fluid>
                    <div className="col-12">
                        <div className="text-right">
                            <p>Productos añadidos</p>
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                        <Table style={{ backgroundColor: 'transparent', width: '80%' }}>
                                            <tbody>
                                                {productos.map((prod) => (
                                                    <tr key={prod.id}>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.imagen}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.sku}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.nombre}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.solicitado}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <button className='button-option-title p-2 mx-md-3' onClick={() => setModalProducto({ ...modalProducto, show: true })}>Añadir producto</button>
                            <button className='buttonAñadirInventarioOption p-2 mx-md-3' onClick={() => save()} disabled={productos.length==0}>Finalizar  carga de Inventario</button>
                            <a className='p-2 mx-md-3' href="#" onClick={()=>returSucursal()} style={{ color: 'green' }}>Omitir Paso</a>
                        </div>
                    </div>
                </Container>
                <ModalProducto productos={productos} setProductos={setProductos} modal={modalProducto} setModal={setModalProducto} />
            </main>
        </div>
    )
}
export default PrimerInventario;