import React, { useEffect, useState } from "react";
import {Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const NewCotizacion = ({ modal, setModal }) => {
    const [productos, setProductos] = useState([]);
    const [cotizacion,setCotizacion] = useState({})
    const [step, setStep] = useState({step:1,name:'Paso 1 - Selección de tipo de compra',size:'ls'});
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Nueva Cotización
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            {step.name}
                            <div className="text-center">
                                {step.step} of 3
                            </div>
                            <Progress
                                animated
                                max="3"
                                value={step.step}
                                style={{ height: '7px' }}
                            />
                        </div>
                        <div className="col-sm-12">
                            {step.step == 1 &&
                                <Step1 setStep={setStep} cotizacion={cotizacion} setCotizacion={setCotizacion} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 2 &&
                                <Step2 productos={productos} setProductos={setProductos} setStep={setStep} cotizacion={cotizacion} setCotizacion={setCotizacion} modal={modal} setModal={setModal}/>
                            }
                            {step.step == 3 &&
                                <Step3 productos={productos} setProductos={setProductos} setStep={setStep} cotizacion={cotizacion} setCotizacion={setCotizacion} modal={modal} setModal={setModal}/>
                            }
                            
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </Modal>
    )
}

export default NewCotizacion