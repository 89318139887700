import React, { useMemo, useState } from 'react';
import { EyeIcon } from '@heroicons/react/outline';
import "../../styles/buttons.css";
import "../../styles/select.css";
import "../../styles/inputs.css"

import ListCompras from './tabs/ListCompras'
import ListProveedores from '../Proveedores/ListProveedores';
import TablaCotizaciones from '../Cotizaciones/TablaCotizaciones';
import { ToastContainerHelper } from '../../helpers/alerts/alertHelper';


const Compras = () => {
  
  const [filters, setFilters] = useState({ search: '', per_page: 20, page: 1 });
  const [item, setItem] = useState(null);
  const [activeTab,setActiveTab]=useState(0);
  const labelStatus = (status) => {
    if (status == 'Completa') {
      return (
        <span className='badge rounded-pill px-2' style={{ backgroundColor: '#2FCD2C', fontWeight: 500, height: '20px' }}>{status}</span>
      )
    } else if (status == 'Pendiente') {
      return (
        <span className='badge rounded-pill px-2 text-dark' style={{ backgroundColor: '#FDCD52', fontWeight: 500, height: '20px' }}>{status}</span>
      )
    } else if (status == 'En proceso') {
      return (
        <span className='badge rounded-pill px-2 text-dark' style={{ backgroundColor: '#FDEC52', fontWeight: 500, height: '20px' }}>{status}</span>
      )
    } else {
      return (
        <span className='badge rounded-pill px-2' style={{ backgroundColor: '#EA3323', fontWeight: 500, height: '20px' }}>{status}</span>
      )
    }
  }

  return (
    <div className="d-flex flex-column w-100">
      <ToastContainerHelper/>
      <div className="d-flex flex-row justify-content-between">
        <div className='flex w-25'>
          <p className='fs-5 fw-bold'>Compras</p>
        </div>
        <div className='d-flex flex-row w-75'>
          <button className={`mkp-btn-primary ${activeTab==0 ? 'tabActive':''} w-25 mx-2 mt-1`} onClick={()=>[setActiveTab(0),setFilters({...filters,internas:false})]}>Compras externas</button>
          <button className={`mkp-btn-primary ${activeTab==1 ? 'tabActive':''} w-25 mx-2 mt-1`} onClick={()=>[setActiveTab(1),setFilters({...filters,internas:true})]}>Compras internas</button>
          <button className={`mkp-btn-primary ${activeTab==2 ? 'tabActive':''} w-25 mx-2 mt-1`} onClick={()=>setActiveTab(2)}>Proveedores</button>
          <button className={`mkp-btn-primary ${activeTab==3 ? 'tabActive':''} w-25 mx-2 mt-1`} onClick={()=>setActiveTab(3)}>Cotizaciones</button>
          <button className={`mkp-btn-primary ${activeTab==4 ? 'tabActive':''} w-25 mx-2 mt-1`} onClick={()=>[setActiveTab(4),setFilters({...filters,ordenes:true})]}>Órdenes de compra</button>
        </div>
      </div>
      {activeTab < 2 &&
      <ListCompras filters={filters} setFilters={setFilters}/>
      }
      {activeTab == 2 &&
      <ListProveedores filters={filters} setFilters={setFilters}/>
      }
      {activeTab == 3 &&
      <TablaCotizaciones filters={filters} setFilters={setFilters}/>
      }
      {activeTab == 4 &&
      <ListCompras filters={filters} setFilters={setFilters} ordenes={true}/>
      }
    </div>
  );
};

export default Compras;