import React, {useEffect, useState} from 'react';

import DataTable from 'react-data-table-component';

const DataTableOnlyHelper = ({columns, items, pending}) => {
	
	const [newItems, setNewItems] = useState(items);
	const [filterText, setFilterText] = useState('');

	let filterColumns = columns.filter(col => {
		if(!col.button){ 
			return col;
		}
	});
	filterColumns = filterColumns.map(col => col.id);

	useEffect(() => {
		let filteredItems = [];
		const search = () => {
			if(filterText !== ''){
				filterColumns.map(col => {
					let filtered = items.filter(item => String(item[col]) && String(item[col]).toLowerCase().includes(filterText.toLowerCase()));
					filteredItems = filteredItems.concat(filtered);
				});
				const unique = [...new Set(filteredItems.map(item => item))];
				setNewItems(unique);
			}else{
				setNewItems(items)
				filteredItems=[];
				
			}
		}
		search();
	}, [filterText, items]);

	const paginationComponentOptions = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	};

	return (
		<React.Fragment>
            <DataTable
            	pagination
            	paginationPerPage={10}
            	paginationRowsPerPageOptions={[10,25, 50, 100]}
            	columns={columns}
            	data={newItems}
            	paginationComponentOptions={paginationComponentOptions}
            	progressPending={pending}
            	progressComponent={<div><i className="fas fa-solid fa-spinner fa-spin"></i> Cargando...</div>}
            	noDataComponent={<div><i className="fas fa-solid fa-info text-primary"></i> No hay registros para mostrar</div>}
            	highlightOnHover={true}
            	subHeaderWrap={false}
            />
		</React.Fragment>
    );
}

export default DataTableOnlyHelper;