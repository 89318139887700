import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { auditoriaResource } from "../../../../helpers/backend_helper";

const Step3 = ({ modal, setModal, auditoria, setAuditoria, setStep,setProductos,productos }) => {
    const [selects, setSelects] = useState({
        cotizaciones: []
    })

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: auditoria,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            values.productos = productos
            const response  = auditoriaResource('post',values)
            
            if(response.status){
                setStep({step:1,name:'Paso 1',size:'xl'})
                setModal({show:false})
            }
        },
    });

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Hora Final</label>
                    <Input
                        name="hora_final"
                        type="time"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.hora_final || ""}
                        invalid={
                            validation.touched.hora_final &&
                                validation.errors.hora_final
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.hora_final && validation.errors.hora_final) && <FormFeedback type="invalid">{validation.errors.hora_final}</FormFeedback>}
                </div>
                
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step3