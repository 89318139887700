import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, List, FormFeedback, FormGroup, Label } from "reactstrap";
import PasswConfirm from "../../components/CommonForBoth/PasswConfirm";
import { movimientosResource } from "../../helpers/backend_helper";

const InformeModal = ({ modal, setModal, setMovimiento,movimiento,setModalAction,modalAction,setFilters,filters }) => {
    const [productos, setProductos] = useState([])
    const [confirmPass, setConfirmPass] = useState({ show: false })
    const save = async () => {
        const response = await movimientosResource('put', movimiento, movimiento.id);
        if (response.status) {
            setFilters({ ...filters });
            setMovimiento({ id: null, movimiento_tipo: { nombre: '' }, colaborador: { name: '' }, productosData: [] });
            setModal({ show: false });
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>{movimiento.id}-{movimiento.movimiento_tipo.nombre}</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <p>Colaborador que realizo el movimiento:{movimiento.colaborador.name}</p>
                        <p>Fecha del movimiento:{movimiento.created_at}</p>
                        <p>Cantidad de productos:{movimiento.cantidad}</p>
                        <p>Desglose de productos</p>
                        <div className="col-12">
                        <List>
                            {movimiento.productosData.length && movimiento.productosData.map((p) => (
                                <li key={p.id}>
                                    {`${p.producto_nombre} - Cantidad ${p.cantidad}`}
                                </li>
                            ))}
                        </List>
                        </div>
                        <p>Total:{Number(movimiento.precio_total).toLocaleString('en-US')}</p>
                        <div className="col-sm-6">
                            <Button block color="primary" style={{backgroundColor:'#B2BD00'}} onClick={() => [setModalAction({...modalAction,show:true,action:'cancel'}),setModal({show:false})]}>
                                Cancelar
                            </Button>{' '}
                        </div>
                        <div className="col-sm-6">
                            <Button block className="btn-noSucursales" style={{backgroundColor:'#B2BD00'}} onClick={() => [setModalAction({...modalAction,show:true,action:'modification'}),setModal({show:false})]}>
                                Modificar
                            </Button>{' '}
                        </div>
                        <div className="col-sm-12">
                            <br/>
                            <Button color="secondary" style={{backgroundColor:'#04B606'}} block onClick={() => [setMovimiento({ ...movimiento, data: { action: 'confirm', estatus_id:3, messageAction: 'Se atendio el movimiento', productosModificados: false } }), setConfirmPass({ show: true })]}>
                                Confirmar Movimiento
                            </Button>
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirm()}>
                    Confirmar
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal({ ...modal, show: false })}>
                    Descartar
                </Button>
            </ModalFooter>
            <PasswConfirm modal={confirmPass} setModal={setConfirmPass} save={save} />
        </Modal>
    )
}

export default InformeModal;