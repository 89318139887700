import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import AsyncSelect from 'react-select/async';
import Select from "react-select";

const Step1 = ({ modal, setModal, auditoria, setAuditoria, setStep,setProductos,productos }) => {
    const [selects, setSelects] = useState({
        cotizaciones: []
    })

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: auditoria,
        validationSchema: Yup.object({
           
        }),
        onSubmit: async (values, { setErrors }) => {
            setAuditoria(values)
            setStep({step:2,name:'Paso 2 - Selección de productos',size:'xl'})
        },
    });

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Hora de Inicio</label>
                    <Input
                        name="hora_inicio"
                        type="time"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.hora_inicio || ""}
                        invalid={
                            validation.touched.hora_inicio &&
                                validation.errors.hora_inicio
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.hora_inicio && validation.errors.hora_inicio) && <FormFeedback type="invalid">{validation.errors.hora_inicio}</FormFeedback>}
                </div>
                
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default Step1