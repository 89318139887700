import React, { useState, useEffect } from "react";
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cpResource, getProveedores_tipos, estadosMunicipiosCatalogo, ciudadesResource } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";

const Step1 = ({ proveedor, setProveedor, modal, setModal, getBase64, setStep }) => {
    const optionsProveedor = [
        { label: 'Acreedor', value: 1 },
        { label: 'Proveedor', value: 0 }
    ]
    const [selects, setSelects] = useState({
        optionsProveedor: [
            { label: 'Acreedor', value: 1 },
            { label: 'Proveedor', value: 0 }
        ],
        estados: [],
        tipos: [],
        municipios: []
    })
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: proveedor,
        validationSchema: Yup.object({
            nombre: Yup.string().required('Nombre es requerido'),
            telefono: Yup.string().required('Teléfono es requerido'),
            web: Yup.string().url('La dirección web no es válida'),
            tipo_id: Yup.number().integer('Tipo debe ser un número entero'),
            estado_id: Yup.number().integer('Tipo debe ser un número entero'),
            ine: Yup.string().required('INE es requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setProveedor(values)
            setStep({ step: 2, name: 'Paso 2 - Datos Fiscales', size: 'ls' })
        },
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: validation.values.estado_id, municipio_id: validation.values.municipio.id } });
            callback(options.data);
        }
    };

    const addFile = async (e) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            validation.setFieldValue('ine', base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos, estados] = await Promise.all([
                    getProveedores_tipos(),
                    estadosMunicipiosCatalogo({ params: { estados: true } })
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.tipos,
                    estados: estados.data
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    useEffect(() => {
        const getCiudadesCatalogo = async () => {
            const response = await ciudadesResource('get', { params: { estado_id: validation.values.estado_id } });
            if (response.status) {
                setSelects({ ...selects, municipios: response.ciudades })
            }
        }
        if (validation.values.estado_id) {
            getCiudadesCatalogo()
        }
    }, [validation.values.estado_id]);
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                validation.handleSubmit();
                console.log(validation)
                return false;
            }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Nombre</label>
                    <Input
                        name="nombre"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nombre || ""}
                        invalid={
                            validation.touched.nombre &&
                                validation.errors.nombre
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Acreedor / Proveedor</label>
                    <Select
                        styles={customStyles}
                        name="acreedor"
                        options={selects.optionsProveedor}
                        value={validation.values.acreedor}
                        onChange={option => validation.setFieldValue('acreedor', option.id)}
                    />
                    {(validation.touched.acredor && validation.errors.acredor) && <FormFeedback type="invalid">{validation.errors.acredor}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Tipo de proveedor</label>
                    <Select
                        styles={customStyles}
                        name="tipo_id"
                        options={selects.tipos}
                        value={validation.values.tipo}
                        onChange={option => [validation.setFieldValue('tipo_id', option.id), validation.setFieldValue('tipo', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Contacto</label>
                    <Input
                        name="telefono"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.telefono || ""}
                        invalid={
                            validation.touched.telefono &&
                                validation.errors.telefono
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.telefono && validation.errors.telefono) && <FormFeedback type="invalid">{validation.errors.telefono}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Correo Electrónico</label>
                    <Input
                        name="email"
                        type="email"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                            validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.email && validation.errors.email) && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Sitio web</label>
                    <Input
                        name="web"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.web || ""}
                        invalid={
                            validation.touched.web &&
                                validation.errors.web
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.web && validation.errors.web) && <FormFeedback type="invalid">{validation.errors.web}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Estado</label>
                    <Select
                        styles={customStyles}
                        name="estado_id"
                        options={selects.estados}
                        value={validation.values.estado}
                        onChange={option => [validation.setFieldValue('estado_id', option.id), validation.setFieldValue('estado', option)]}
                        getOptionLabel={(option) => `${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.estado_id && validation.errors.estado_id) && <FormFeedback type="invalid">{validation.errors.estado_id}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Ciudad</label>
                    <Select
                        isDisabled={!validation.values.estado_id}
                        styles={customStyles}
                        name="municipio"
                        options={selects.municipios}
                        value={validation.values.municipio}
                        onChange={option => [validation.setFieldValue('municipio', option),validation.setFieldValue('municipio_id', option.id)]}
                        getOptionLabel={(option) => `${option.no} - ${option.nombre}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.municipio && validation.errors.municipio) && <FormFeedback type="invalid">{validation.errors.municipio}</FormFeedback>}
                </div>

                <div className="col-sm-6">
                    <label className="form-label">Codigo Postal</label>
                    <AsyncSelect
                        styles={customStyles}
                        cacheOptions
                        defaultOptions
                        value={validation.values.cp}
                        onChange={option => [validation.setFieldValue('cp', option), validation.setFieldValue('cp_id', option.id), validation.setFieldValue('colonia', option.asentamiento)]}
                        loadOptions={loadOptions}
                        getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                        getOptionValue={(option) => option.id}
                    />
                    {(validation.touched.cp && validation.errors.cp) && <FormFeedback type="invalid">{validation.errors.cp.value ? validation.errors.cp.value : 'Error'}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Colonia</label>
                    <Input
                        readOnly
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.colonia}
                    />

                </div>
                <div className="col-sm-6">
                    <label className="form-label">Numero ext.</label>
                    <Input
                        name="exterior"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.exterior || ""}
                        invalid={
                            validation.touched.exterior &&
                                validation.errors.exterior
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.exterior && validation.errors.exterior) && <FormFeedback type="invalid">{validation.errors.exterior}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">Numero Int.</label>
                    <Input
                        name="interior"
                        className="inputAñadirInventario"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.interior || ""}
                        invalid={
                            validation.touched.interior &&
                                validation.errors.interior
                                ? true
                                : false
                        }
                    />
                    {(validation.touched.interior && validation.errors.interior) && <FormFeedback type="invalid">{validation.errors.interior}</FormFeedback>}
                </div>
                <div className="col-sm-6">
                    <label className="form-label">INE.</label>
                    <Input
                        accept=".pdf"
                        name="ine"
                        type="file"
                        className="inputAñadirInventario"
                        onChange={(e) => addFile(e)}
                    />
                    {(validation.touched.ine && validation.errors.ine) && <FormFeedback type="invalid">{validation.errors.ine}</FormFeedback>}
                </div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}></div>
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit">
                        Continuar
                    </Button>{' '}
                </div>
                <div className="col-sm-6 text-center" style={{ textAlign: 'left' }}>
                    <Button color="secondary" block onClick={() => [setModal({ ...modal, show: false })]}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Form>
    )
}
export default Step1;