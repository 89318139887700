import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input,Spinner } from "reactstrap";
import { proveedorMasiva } from "../../../helpers/backend_helper";

const MasivaProveedor = ({ modal, setModal }) => {
    const [file, setFile] = useState({file:null});

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const [spinnerElement, setSpinnerElement] = useState(false);
    const handleSubmit = async () => {
        setSpinnerElement(true)
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await proveedorMasiva(formData);

            if (response.status) {
                // Aquí puedes manejar la respuesta exitosa
                setModal({show:false})
            } else {
                setSpinnerElement(false)
                // Manejar error en la respuesta
                console.error("Error al enviar el archivo");
            }
        } catch (error) {
            setSpinnerElement(false)
            console.error("Error de red:", error);
        }
    };

    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Carga de cotizaciones con Excel
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="form-label">Archivo</label>
                            <Input type="file" onChange={handleFileChange} />
                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
            {!spinnerElement ?
                    <>
                        <Button color="primary" block type="button" onClick={(handleSubmit)}>
                            Confirmar
                        </Button>
                    </>
                    :
                    <>
                        <Button className='buttonAñadirInventarioOption p-2 mx-md-2' disable={true}>
                            <Spinner size="sm">Guardando datos...</Spinner>
                            <span>{' '}Guardando datos...</span>
                        </Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    );
};

export default MasivaProveedor;
