import React, { useState } from 'react';
import EliminarProveedor from '../../components/Modales/Compras/EliminarProveedor';
import EvaluarProveedor from './modals/EvaluarProveedor';

const Opciones = ({ onClick, opcionActiva, vista, update, setUpdate }) => {
  const [showEvaluar, setShowEvaluar] = useState(false);
  const [showEliminar, setShowEliminar] = useState(false);
  const [modalView, setModalView] = useState('details');


  const abrirEvaluar = () => setShowEvaluar(true);
  const abrirEliminar = () => setShowEliminar(true);

  let contenido;

  switch (vista) {
    case 'principal':
      const opcionesPrincipales = ['Compras externas', 'Compras internas', 'Proveedores', 'Cotizaciones', 'Órdenes de compra'];
      contenido = (
        <div className='d-flex justify-content-center col-12 col-lg-7'>
          <div className='d-none d-lg-flex'>
          </div>
          <div className='col-12 col-lg-3 px-lg-1 mx-0 d-flex align-items-center flex-column flex-lg-row'>
            {opcionesPrincipales.map((opcion, index) => (
              <button
                key={index}
                className={`button-option-title-custom col-12 col-lg-12 mb-3 mb-lg-0 ${opcionActiva === opcion ? 'button-option-title-custom-active' : ''}`}
                onClick={() => onClick(opcion)}
              >
                {opcion}
              </button>
            ))}
          </div>
        </div>
      );
      break;
    case 'detalle':
      const opcionesDetalle = [
        { nombre: 'Evaluar' },
        { nombre: 'Editar proveedor', accion: abrirEliminar },
        { nombre: 'Eliminar proveedor', accion: abrirEliminar }
      ];
      contenido = (
        <div className='d-flex justify-content-between col-11'>
          <div className='d-none d-lg-flex'>
          </div>
          <div className='col-12 col-lg-3 mx-lg-2 mx-0 d-flex align-items-center flex-column'>
            <button
              className={`button-option-detalle-compras-green col-12 mb-3 ${opcionActiva === 'evaluar' ? 'active' : ''}`}
              onClick={() => console.log('ok')}
            >
              Evaluar
            </button>
            {update ?
              <button
                className={`button-option-detalle-compras-green col-12 mb-3 ${opcionActiva === 'evaluar' ? 'active' : ''}`}
                onClick={() => setUpdate(false)}
              >
                Cancelar
              </button>
              :
              <button
                className={`button-option-detalle-compras-green col-12 mb-3 ${opcionActiva === 'evaluar' ? 'active' : ''}`}
                onClick={() => setUpdate(true)}
              >
                Editar proveedor
              </button>
            }
            <button
              className={`button-option-detalle-compras col-12 mb-3 ${opcionActiva === 'evaluar' ? 'active' : ''}`}
              onClick={() => console.log('ok')}
            >
              Eliminar proveedor
            </button>
          </div>
        </div>
      );
      break;
    default:
      contenido = <div>No se encontró la vista especificada.</div>;
  }

  return (
    <div className='col-11 col-lg-12 px-lg-5 mx-lg-5 d-flex justify-content-between'>
      {contenido}
      {showEvaluar && <EvaluarProveedor
        onClose={() => setShowEvaluar(false)}
        view={modalView}
        changeView={setModalView} />}
      {showEliminar && <EliminarProveedor
        onClose={() => setShowEliminar(false)}
        view={modalView}
        changeView={setModalView} />}
    </div>
  );
};

export default Opciones;
