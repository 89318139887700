import axios from "axios"
import { del, get, post, postFile, put, postData, file} from "./api_helper"
import * as url from "./url_helper"
import {ToastHelper} from './alerts/alertHelper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
        return null
}

const getAuthUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user).user
        return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}
//reset pasword
export const resetAccount = (data) => post(url.RESET_ACCOUNT, data)
export const resetAuth = (data) => post(url.RESET_AUTH, data)
export const firstAuth = (data) => post(url.FIRST_AUTH, data)
// Login Method





export const postLoginToken = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
          throw response.data
    })
    .catch(err => {
        var message = ''
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404:
                    message = "Lo sentimos el recurso solicitado no existe"
                    break
                case 422:
                    message = err.response.data.message
                    break
                case 500:
                    message = "Lo sentimos! algo ha salido mal, por favor contacta a nuestro equipo de soporte."
                    break
                case 401:
                    message = "Credenciales inválidas"
                    break
                default:
                    message = err.response?.data?.message || err.message
                    break
            }
           ToastHelper(message,'error')
           return err.response.data
        } else {
            message = err.message
        }
        throw message
    })
}

// get Users

//Catalogos sin login
//export const resourcesFuelSales = (type,data={},id=null) => typeResource(url.RESOURCE_FUEL_SALES,type,data,id)
//ARchivos
/*
export const getHours = config => get(url.GET_HOURS, config)
export const getAsunto = config => get(url.GET_ASUNTO, config)
//Solicitudes de credito 
export const creditRequestsResource = (type,data={},id=null) => typeResource(url.RESOURCE_CREDITS_REQUESTS,type,data,id)
//Contactanos
export const contactResource = (type,data={},id=null) => typeResource(url.RESOURCE_CONTACTS,type,data,id)
//aliados
export const aliadoResource = (type,data={},id=null) => typeResource(url.RESOURCE_ALIADOS,type,data,id)
*/
//Inventarios
export const sucursalesResource = (type,data={},id=null) => typeResource(url.SUCURSALES_RESOURCE,type,data,id)
export const productosResource = (type,data={},id=null) => typeResource(url.PRODUCTOS_RESOURCE,type,data,id)

export const inventariosResource = (type,data={},id=null) => typeResource(url.INVENTARIOS_RESOURCE,type,data,id)
//Catalogos
export const estadosMunicipiosCatalogo = (config) => get(url.ESTADOS_MUNICIPIOS_CATALOGO,config);
export const cpResource = (type,data={},id=null) => typeResource(url.CP_RESOURCE,type,data,id)
export const getCategorias = (config) => get(url.GET_CATEGORIAS,config);
export const getUnidad_medidas = (config) => get(url.GET_UNIDAD_MEDIDAS,config);
export const getProducto_tipos = (config) => get(url.GET_PRODUCTO_TIPOS,config);
export const getEstatus = (config) => get(url.GET_ESTATUS,config);
export const divisasResourse = (type,data={},id=null) => typeResource(url.DIVISAS_RESOURCE,type,data,id);
export const ciudadesResource = (type,data={},id=null) => typeResource(url.CIUDADES_RESOURCE,type,data,id);
export const getProveedores_tipos = (config) => get(url.GET_PROVEEDOR_TIPOS,config);
export const getRegimens = (config) => get(url.GET_Regimens,config);
export const getCfdis = (config) => get(url.GET_CFDI,config);
export const getPago_terminos = (config) => get(url.GET_PAGO_TERMINOS,config);
export const getPago_metodos = (config) => get(url.GET_PAGO_METODOS,config);
export const getPago_plazos = (config) => get(url.GET_PAGO_PLAZOS,config);
export const getPago_periodos = (config) => get(url.GET_PAGO_PERIODOS,config);
export const getSat_catalogo = (config) => get(url.GET_SAT_CATALOGO,config);
export const getCompras_tipos = (config) => get(url.GET_COMPRAS_TIPOS,config);
//PROVEEDORES
export const proveedoresResource = (type,data={},id=null) => typeResource(url.PROVEEDORES_RESOURCE,type,data,id)
export const productosProveedoresResource = (type,data={},id=null) => typeResource(url.PRODUCTOS_PROVEEDORES_RESOURCE,type,data,id)
export const proveedorMasiva = (file) => postData(url.PROVEEDOR_MASIVA,file);
//COTIZACIONES
export const cotizacionesResource = (type,data={},id=null) => typeResource(url.COTIZACIONES_RESOURCE,type,data,id)
export const getCotizacionProveedores = (data) => post(`${url.GET_COTIZACION_PROVEEDORES}`,data);
export const getProductCotizacion= (id) => get(`${url.GET_PRODUCT_COTIZACION}/${id}`);
export const cotizacionMasiva = (file) => postData(url.COTIZACION_MASIVA,file);
export const cotizacionRespuesta = (file) => postData(url.COTIZACION_RESPUESTA,file);

//Sucursales
export const primerInvSucursal = (data,id) => post(`${url.PRIMER_INV_SUCURSAL}/${id}`,data);
//Codigos de verificacion
export const codesResource = (type,data={},id=null) => typeResource(url.CODE_RESOURCE,type,data,id)
export const passwordConfirm = (data) => post(`${url.PASSWORD_CONFIRM}`,data);
//Colaboradores
export const colaboradoresResource = (type,data={},id=null) => typeResource(url.COLABORADOR_RESOURCE,type,data,id)
//movimientos
export const movimientosResource = (type,data={},id=null) => typeResource(url.MOVIMIENTOS_RESOURCE,type,data,id)
//Compras
export const comprasResource = (type,data={},id=null) => typeResource(url.COMPRAS_RESOURCE,type,data,id)
//ventas
export const ventasResource = (type,data={},id=null) => typeResource(url.VENTAS_RESOURCE,type,data,id)
export const ventasCancelar = (data,id) => post(`${url.VENTAS_CANCELAR}/${id}`,data);
//Data Home
export const getHomeData = (config) => get(url.GET_HOME_DATA,config);
export const getReportGeneral = (config) => get(url.GET_REPORT_GENERAL,config);
//ClientesS
export const clientMasiva = (file) => postData(url.CLIENTE_MASIVA,file);
export const clientResource = (type,data={},id=null) => typeResource(url.CLIENTS_RESOURCE,type,data,id)

//Costeo
export const costeoGet = (data,id) => get(`${url.GET_COSTEO}/${id}`,data);
//Auditorias
export const auditoriaResource  = (type,data={},id=null) => typeResource(url.AUDITORIA_RESOURCE,type,data,id)
//Incidencias
export const incidenciaSend  = (data) => post(`${url.INCIDENCIA_SEND}`,data);


const typeResource = (route,type,data={},id=null) => {
    switch (type) {
    case 'get':
        return get(route,data);
        break
    case 'show':
        return get(`${route}/${id}`,data);
        break
    case 'post':
        return post(`${route}`,data);
        break
    case 'put':
        return put(`${route}/${id}`,data);
        break
    case 'del':
        return del(`${route}/${id}`);
        break
    case 'postFile':
        return postFile(`${route}`,data);
        break
    }

}

export {
    getLoggedInUser,
    isUserAuthenticated,
    getAuthUser
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}
