import React, { useState } from 'react';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill, BsBoxArrowRight } from 'react-icons/bs';
import "../../styles/Sidebar.css";
import { NavLink } from 'react-router-dom';
import BotonFlotante from '../CommonForBoth/BotonFlotante';
const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const NavItem = ({ icon, text, to }) => (
        <li className={`nav-item ${collapsed ? 'justify-content-center' : 'flex-row d-flex'}`}>
            <img src={`${process.env.PUBLIC_URL}/${icon}`} className='sidebar-icon p-3' alt="Icon" />
            <NavLink to={to} className={`sidebar-text ${collapsed ? 'd-none' : ''} p-3 text-decoration-none`} aria-current="page">
                {text}
            </NavLink>
        </li>
    );

    return (
        <div className={`sidebar d-none d-xl-flex flex-column flex-shrink-0 p-0 bg-light ${collapsed ? 'collapsed' : ''}`}>
            <NavLink to={'/'} className="w-100 d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none justify-content-center align-items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon.svg`} className='w-75 d-flex mb-4 ' alt="Icon" />
            </NavLink>

            <div className={`toggle-btn ${collapsed ? 'toggle-btn-collapsed' : ''}`} onClick={toggleSidebar}>
                {collapsed ? <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar/Vector.svg`} /> : <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar/Vector.svg`} />}
            </div>
            <ul className="nav nav-pills flex-column mb-auto w-100">
                <NavItem icon="assets/images/sidebar/home.svg" text="Home" to={"/"}/>
                <NavItem icon="assets/images/sidebar/inventario.svg" text="Inventarios" to={"/inventarios"}/>
                <NavItem icon="assets/images/sidebar/produccion.svg" text="Producción" to={"/produccion"}/>
                <NavItem icon="assets/images/sidebar/contabilidad.svg" text="Finanzas y contabilidad" to={"/finanzas"}/>
                <NavItem icon="assets/images/sidebar/presupuesto.svg" text="Crédito y cobranza" to={"/credito"}/>
                <NavItem icon="assets/images/sidebar/colaboradores.svg" text="Colaboradores" to={"/colaboradores"}/>
                <NavItem icon="assets/images/sidebar/ventas.svg" text="Ventas" to={"/ventas"}/>
                <NavItem icon="assets/images/sidebar/compras.svg" text="Compras" to={"/compras"}/>
                <NavItem icon="assets/images/sidebar/buscador.svg" text="Log" to={"/log"}/>
            </ul>
            <div className="mt-auto p-3">
                <NavLink to={'/login'} className="nav-link p-3 text-decoration-none align-items-center justify-content-center d-flex w-100 text-white" id="logout">
                    {collapsed ? <BsBoxArrowRight size={25} /> : 'Cerrar sesión'}
                </NavLink>
            </div>
            <BotonFlotante/>
        </div>
    );
};

export default Sidebar;
