import React, { useMemo, useEffect, useState } from "react";
import { PaginationNo, FilterSearch } from '../../../components/CommonForBoth/tables/pagination';
import { comprasResource,sucursalesResource,getReportGeneral } from "../../../helpers/backend_helper";
import NewCompraModal from "../modals/NewCompraModal";
import PreviewPDF from "../../../components/CommonForBoth/previewPDF";
import { FaEye } from "react-icons/fa";
import DetalleCompra from '../modals/DetalleCompra';
import { cotizacionesResource } from "../../../helpers/backend_helper";
import CotizacionDetalle from "../../Cotizaciones/modals/CotizacionDetalle"
import { FaRegEye } from "react-icons/fa";

const ListCompras = ({ filters, setFilters, ordenes }) => {
    const headersTable = useMemo(() => [
        { name: 'Noº orden', class: '' },
        { name: 'Proveedor', class: '' },
        { name: 'Responsable', class: '' },
        { name: 'Fecha de compra', class: '' },
        { name: 'Método de pago', class: 'justify-content-center text-center' },
        
        { name: 'Monto total', class: 'justify-content-center text-center' },
        { name: 'Estado de compra', class: 'justify-content-center text-center' },
        { name: 'Documentos', class: 'justify-content-center text-center' },
        { name: 'Acciones', class: 'justify-content-center text-center' },
    ], []);
    const [modalPdf, setModalPdf] = useState({ show: false })
    const [modal, setModal] = useState({ show: false })
    const [meta, setMeta] = useState({})
    const [compras, setCompras] = useState([]);
    const [modalDetalleCompra, setModalDetalleCompra] = useState({ show: false, compra: {} })
    const [sucursales, setSucursales] = useState({})
    const [modalCotizacion,setModalCotizacion] =useState({show:false,cotizacion:{}})

    const getCotizacionModa = async(id) =>{
        const response = await cotizacionesResource('show',{},id);
        if(response.status){
            setModalCotizacion({...modalCotizacion,show:true,cotizacion:response.cotizacion})
        }
        
    }
    useEffect(() => {
        const getCompras = async () => {
            const response = await comprasResource('get', { params: filters })
            setCompras(response.compras)
        }
        getCompras()
    }, [filters]);

    useEffect(() => {
        const getSucursales = async () => {
            const response = await sucursalesResource('get', { params: {select:true} })
            setSucursales(response.sucursales)
        }
        getSucursales()
    }, []);
    
    const getReport = async (report) => {
        let config = {
            params: { report: 'compras', fecha_inicial: filters.fecha_inicial, fecha_final: filters.fecha_final },
            headers: { 'Accept': report.report == 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
            responseType: "blob"
        }

        const response = await getReportGeneral(config);
        if (report.report == 'pdf') {
            let nombre = 'Archivo';
            var reader = new FileReader();
            reader.readAsDataURL(response);
            reader.fileName = 'reporte.pdf';
            reader.onloadend = function (name) {
                name = 'reporte.pdf';
                var base64data = reader.result;
                setModalPdf({ show: true, update: false, url: base64data });
            };
        } else {
            const url = URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                'Reporte.xlsx'
            )
            document.body.appendChild(link)
            link.click()
        }
    }
    return (
        <>
            
            {ordenes &&
                <div>
                    <button className='buttonProveedores button-modal-eliminacion col-12 col-lg-2' onClick={() => setModal({ show: true })}>Nueva Orden</button>
                </div>
            }
            <FilterSearch reports={getReport} filters={filters} setFilters={setFilters} sucursales={sucursales} setSucursales={setSucursales} />
            <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
                <div className="table-responsive table-responsive-sucursales">
                    <table className="table table-sucursales">
                        <thead className='text-center'>
                            <tr>
                                {headersTable.map((h, i) => (
                                    <th key={i} className={h.class}>{h.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {compras.length ? compras.map((c, i) => (
                                <tr key={i}>
                                    <th>{c.id}</th>
                                    <th>{c.proveedor.nombre}</th>
                                    <th>{c.responsable.name}</th>
                                    <th>{c.fecha_compra}</th>
                                    <th>{'Contado'}</th>
                                    
                                    <th>{c.total}</th>
                                    <td style={{ textAlign: 'center' }}><button className={c.estatus.id == 11 ? 'indicadorAccionesGreen fontBold600 col-9' : 'indicadorAccionesYellow'}>{c.estatus.nombre}</button></td>
                                    <th>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => getCotizacionModa(c.cotizacion_id)}>Ver Cotización</button>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setModalPdf({ show: true, url: c.comprobante })}>Ver factura</button>
                                    </th>
                                    <th>
                                    
                                    <a className='col-6' href="#" onClick={() => setModalDetalleCompra({ show: true, compra: c })}><FaRegEye/></a>
                                    </th>
                                </tr>
                            ))
                                :
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={16} style={{ textAlign: 'center' }}>
                                        No hay registros para mostrar
                                    </th>
                                </tr>
                            }
                            {/* Puedes agregar más filas aquí */}
                        </tbody>
                    </table>
                    <PaginationNo filters={filters} setFilters={setFilters} />
                </div>
                {modal.show &&
                    <NewCompraModal modal={modal} setModal={setModal} />
                }
            </div>
            <PreviewPDF modalPdf={modalPdf} setModalPdf={setModalPdf} />
            {modalDetalleCompra.show &&
                <DetalleCompra modal={modalDetalleCompra} setModal={setModalDetalleCompra} />
            }
            {modalCotizacion.show &&
                <CotizacionDetalle modal={modalCotizacion} setModal={setModalCotizacion}/>
            }
        </>
    )
}

export default ListCompras;