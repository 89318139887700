import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
<FontAwesomeIcon icon="fa-solid fa-chevron-left" />

import "../../../styles/VerProducto.css"
import TablaDetalleEvaluacion from './TablaDetalleEvaluacion';

const EvaluarProveedor = ({onClose, view, changeView}) => {

    const [archivoContrato, setArchivoContrato] = useState(null);

    const manejarCambioArchivo = (event) => {
      const archivo = event.target.files[0];
      setArchivoContrato(archivo);
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    onClose(); 
  };

  const handleClose = () => {
    onClose();
    changeView("details")
  }

  const producto = {
    nombre: "BioGrow Plus",
    unidad: "5 KG",
    precio: "$25.00",
    tipo: "Fertilizante Orgánico"
  };


  const renderModalContent = () => {
    switch (view) {
      case 'details':
        return(
            <div className='col-12 d-flex flex-column'>
                        <div className='mb-3'>
                        </div>
                    <div className='d-flex col-12 justify-content-center flex-column align-self-center p-3'>
                    <div className='d-flex col-12 flex-column flex-lg-row'>
                        <div className='col-12'>
                            <div className='d-flex col-12 col-lg-12 align-self-start mb-2 flex-column flex-lg-row'>
                            <div className='d-flex flex-column col-12 col-lg-6'>
                                <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Tipo de proveedor</label>
                                <div  className='inputSucursal d-flex px-2 align-items-center' placeholder='escribe'>Equipos y materiales</div>
                            </div>
                            </div>
                            <div className='d-flex col-12 justify-content-center mb-2'>
                                        <div className='d-flex flex-column col-12'>
                                        <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Resumen de compra</label>
                                        <TablaDetalleEvaluacion/>
                                        </div>
                            </div>
                            <div className='d-flex col-12 col-lg-11 align-self-start mb-2 flex-column flex-lg-row'>
                                    <div className='col-12 col-lg-6'>
                                        <div className='d-flex flex-column col-12 col-lg-6 ml-lg-4'>
                                            <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Ponderacion Final</label>
                                            <div  className='inputSucursal d-flex px-2 align-items-center' placeholder='escribe'>100%</div>
                                            </div>
                                            <div className='d-flex flex-column col-12 col-lg-6'>
                                            <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Resultado</label>
                                            <div  className='inputSucursal d-flex px-2 align-items-center' placeholder='escribe'>Excelente</div>
                                        </div>
                                    </div>
                                    <div className='d-flex col-12 col-lg-6 justify-content-end mb-2 flex-column align-items-center'>
                                        <div className='d-flex align-self-start col-12 col-lg-6 flex-column flex-lg-row'>
                                            <div className="d-flex col-12 col-lg-12 ">
                                                <button className="mt-3 fontBold500 button-modal-eliminacion col-12 col-lg-12" style={{height: "2.3em"}}>Imprimir</button>
                                            </div>

                                            <div className="d-flex col-12 col-lg-12 mx-lg-2">
                                                <button className="mt-3 fontBold500 button-modal-eliminacion col-12 col-lg-12" style={{height: "2.3em"}} onClick={() => changeView('paso6')}>Enviar</button>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="d-flex col-12 col-lg-12">
                                                <button className="mt-3 fontBold500 button-modal-eliminacionRegresar col-12 col-lg-12 mx-lg-4" style={{height: "2.3em"}} onClick={() => changeView('paso6')}>Confirmar</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            case "paso6":
                return(
                <div className='col-12 d-flex flex-column mt-1 justify-content-center align-items-center'>
                    <div className="d-flex justify-content-center align-items-center col-12">
                        <button className="mt-3 fontBold500 button-modal-eliminacion col-7" onClick={handleClose}>Aceptar</button>
                    </div>
                </div>
                )
      default:
        return <div>Contenido no definido</div>;
    }
  };

  const renderModalTitle = () => {
    switch (view) {
      case 'details':
        return 'Evaluación Periodo 01/01/24 - 16/02/24 '; 
        case 'paso6':
            return (
                <>
                  <span>Reporte de evaluación </span>
                  <br/>
                  <span>Periodo 01/01/24 - 16/02/24</span>
                  <br/>
                  <span>Enviado al correo del proveedor.</span>
                </>
              );
              
      default:
        return 'Editar';
    }
  };

  const getModalDialogStyles = (view) => {
    const styles = {
      'details': { maxWidth: '100%', width: "40em", margin: '0.5rem' },
      'paso6': { maxWidth: '70em', width: "60em", margin: '0.5rem' },
    };
  
    return styles[view] || { maxWidth: '100em', width: "40em", margin: '0.5rem' };
  };
  
  const modalDialogStyle = getModalDialogStyles(view);

  return (
    <div className="modal show d-flex justify-content-center " tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered " role="document" style={modalDialogStyle}>
                <div className="modal-content contentModalDetalleCompra">
                    <div className={`d-flex justify-content-${view === "paso6" ? "center" : "between"} align-items-center`}>
                        <button type="button" className="fontBold500 close p-2 mx-3" style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <span className=''>{renderModalTitle()} </span>
                        </button>
                    {view === "paso6" ?
                    <></>
                    :
                    <button type="button" className="close p-2 mx-3" onClick={handleClose} style={{color: 'black', border: 'none', fontWeight: 700, fontSize: '1.5rem', backgroundColor: 'transparent'}} aria-label="Close">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    }
                    </div>
          <div className="modal-body">
            {renderModalContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluarProveedor;
