import React, { useState, useEffect } from 'react';
import { proveedoresResource } from '../../../helpers/backend_helper';
import { Form, Input, FormFeedback, Button } from "reactstrap";
import PreviewPDF from '../../../components/CommonForBoth/previewPDF';
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";

const DocumentosLegales = (props) => {
    const [legales, setLegales] = useState({})
    const [modalPdf, setModalPdf] = useState({ show: false })
    useEffect(() => {
        const getBancario = async () => {
            const response = await proveedoresResource('show', { params: { legales: true } }, props.proveedor.id)
            setLegales(response.legales)
        }
        getBancario()
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: legales,
        validationSchema: Yup.object({
            acta_constitutiva: Yup.string().required('Por favor, introduce el acta constitutiva'),
            contrato: Yup.string().required('Por favor, introduce el contrato'),
            ine: Yup.string().required('Por favor, introduce el INE'),
            cuenta_bancaria: Yup.string().required('Por favor, introduce la cuenta bancaria'),
            comprobante_domicilio: Yup.string().required('Por favor, introduce el comprobante de domicilio'),
            rfc_comprobante: Yup.string().required('Por favor, introduce el RFC del comprobante'),
            situacion_fiscal: Yup.string().required('Por favor, introduce la situación fiscal'),
        }),
        onSubmit: async (values, { setErrors }) => {
            values.type = 'legales';
            const response = await proveedoresResource('put',values,props.proveedor.id);
            if(response.status){
                setLegales(response.legales);
                props.setUpdate(false)
            }
        },
    });

    const addFile = async (e,name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await props.getBase64(archivo);
            validation.setFieldValue(name, base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    return (
        <>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if(props.update)
                    validation.handleSubmit();
                    console.log(validation)
                    return false;
                }}
            >
                <div className='tableInformacionGeneral col-11 col-lg-12 d-flex flex-column p-3'>
                    <div className='d-flex col-12 col-lg-11 justify-content-start align-items-center flex-column flex-lg-row'>

                        <div className='mt-3 mt-lg-0 d-flex col-12 col-lg-8 justify-content-center align-items-center flex-column'>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Comprobante de Identificacion Fiscal (RFC)</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="rfc_comprobante"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'rfc_comprobante')}
                                                />
                                                {(validation.touched.cfdi && validation.errors.rfc_comprobante) && <FormFeedback type="invalid">{validation.errors.rfc_comprobante}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.rfc_comprobante}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.rfc_comprobante_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-6 mx-lg-4'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>INE del representante</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="ine"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'ine')}
                                                />
                                                {(validation.touched.cfdi && validation.errors.ine) && <FormFeedback type="invalid">{validation.errors.ine}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.ine}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.ine_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Comprobante de Domicilio</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="comprobante_domicilio"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'comprobante_domicilio')}
                                                />
                                                {(validation.touched.cfdi && validation.errors.comprobante_domicilio) && <FormFeedback type="invalid">{validation.errors.comprobante_domicilio}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.comprobante_domicilio}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.comprobante_domicilio_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-6 mx-lg-4'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Contrato de compra</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="contrato"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'cfdi')}
                                                />
                                                {(validation.touched.cfdi && validation.errors.contrato) && <FormFeedback type="invalid">{validation.errors.contrato}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.contrato}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.contrato_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Constancia de Situación Fiscal</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="situacion_fiscal"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'situacion_fiscal')}
                                                />
                                                {(validation.touched.cfdi && validation.errors.situacion_fiscal) && <FormFeedback type="invalid">{validation.errors.situacion_fiscal}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.situacion_fiscal}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.situacion_fiscal_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-6 mx-lg-4'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Acta Administrativa</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="acta_constitutiva"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'acta_constitutiva')}
                                                />
                                                {(validation.touched.acta_constitutiva && validation.errors.acta_constitutiva) && <FormFeedback type="invalid">{validation.errors.acta_constitutiva}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.acta_constitutiva}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.acta_constitutiva_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-6'>
                                    <label htmlFor='inputConBoton' className='d-flex justify-content-start'>Comprobante de Cuenta Bancaria</label>
                                    <div className='inputDetalleProveedor d-flex justify-content-between align-items-center' id='inputConBoton'>
                                        <span className='inputValue'>{/*Aque se llena con el valor del documento del backend*/}</span>
                                        {props.update ?
                                            <>
                                                <Input
                                                    accept=".pdf"
                                                    name="cuenta_bancaria"
                                                    type="file"
                                                    className="inputDetalleProveedor"
                                                    onChange={(e) => addFile(e, 'cuenta_bancaria')}
                                                />
                                                {(validation.touched.cuenta_bancaria && validation.errors.cuenta_bancaria) && <FormFeedback type="invalid">{validation.errors.cuenta_bancaria}</FormFeedback>}
                                            </>
                                            :
                                            <>
                                                <span className='inputValue'>{legales.acta_constitutiva}</span>
                                                <button className='btnVer col-3 mx-2' onClick={() => setModalPdf({ show: true, url: legales.cuenta_bancaria_doc })}>Ver</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {props.update &&
                    <button className={`btnVer col-3 mx-2`} type='submit'>
                        Guardar
                    </button>
                    }
                </div>

            </Form>
            <PreviewPDF setModalPdf={setModalPdf} modalPdf={modalPdf} />

        </>

    );
}

export default DocumentosLegales;
