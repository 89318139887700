import React, { useState, useMemo, useEffect } from "react";
import { FilterSearch, PaginationNo } from "../../../components/CommonForBoth/tables/pagination";
import NewAuditoria from "./modals/NewAuditoria";
import { auditoriaResource } from "../../../helpers/backend_helper";

const Auditorias = () => {
    const [filters, setFilters] = useState({ search: '', per_page: 20, page: 1 });
    const [modalAuditoria, setModalAuditoria] = useState({ show: false });
    const [auditorias, setAuditorias] = useState([])
    
    const headersTable = useMemo(() => [
        { name: 'Fecha', class: '' },
        { name: 'Hora inicio', class: '' },
        { name: 'Hora final', class: '' },
        { name: 'Responsable', class: '' },
        { name: 'Estatus', class: 'justify-content-center text-center' },
        { name: 'Observaciones', class: 'justify-content-center text-center' },
        { name: 'Acciones', class: 'justify-content-center text-center' },
    ], []);

    useEffect(() => {
        const getAuditoria = async () => {
            const response = await auditoriaResource('get', {params:filters})
            setAuditorias(response.auditorias)
        }
        getAuditoria()
    }, []);
    return (
        <div className="d-flex flex-column w-100">
            <div className="d-flex flex-row justify-content-between">
                <div className='flex w-25'>
                    <p className='fs-5 fw-bold'>Auditorias</p>
                </div>
                <div className='d-flex flex-row w-2'>
                    <button className={`mkp-btn-primary w-30 mx-4 mt-2`} onClick={() => [setModalAuditoria({show:true})]}>Generar una auditoria</button>
                </div>
            </div>
            <FilterSearch filters={filters} setFilters={setFilters} />

            <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
                <div className="table-responsive table-responsive-sucursales">
                    <table className="table table-sucursales">
                        <thead className='text-center'>
                            <tr>
                                {headersTable.map((h, i) => (
                                    <th key={i} className={h.class}>{h.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {auditorias.length ? auditorias.map((v, i) => (
                                <tr key={i}>
                                    <th>{v.fecha}</th>
                                    <th>{v.hora_inicio}</th>
                                    <th>{v.hora_final}</th>
                                    <th>{'Colaborador Responsable'}</th>
                                    <th>{v.estatus}</th>
                                    <th>{'Sin Observaciones'}</th>
                                    <th>
                                        <button className='indicadorAccionesNegro fontBold600 col-6' onClick={() => setCancelar({ show: true, venta: v })}>Descargar</button>
                                    </th>
                                </tr>
                            ))
                                :
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={16} style={{ textAlign: 'center' }}>
                                        No hay registros para mostrar
                                    </th>
                                </tr>
                            }
                            {/* Puedes agregar más filas aquí */}
                        </tbody>
                    </table>
                    <PaginationNo filters={filters} setFilters={setFilters} />
                </div>
                {modalAuditoria.show &&
                    <NewAuditoria modal={modalAuditoria} setModal={setModalAuditoria}/>
                }
            </div>
        </div>
    )
}
export default Auditorias;