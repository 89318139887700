import React, { useState, useEffect } from "react";
import { cpResource, getEstatus, sucursalesResource, colaboradoresResource } from "../../helpers/backend_helper"
import { Button, Label, Spinner, Form, Input, FormFeedback, FormGroup } from 'reactstrap';
import CustomSelect from "../../components/SELECT/CustomSelect";
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { useNavigate } from 'react-router-dom';
import { update_sucursal } from '../../store/sucursales/action';

const NuevaSucursal = ({ sucursal, dispatch }) => {
    const navigate = useNavigate();
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const [selects, setSelects] = useState({
        estatus: [],
        colaboradores: [],
        cps: []
    })

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [estatus, colaboradores] = await Promise.all([
                    getEstatus({ params: { model: 'Sucursal' } }),
                    colaboradoresResource('get', {})
                ]);

                setSelects({
                    ...selects,
                    estatus: estatus.estatus,
                    colaboradores: colaboradores.colaboradores
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: sucursal,
        validationSchema: Yup.object({
            nombre: Yup.string().required('El nombre es requerido'),
            email: Yup.string().email('Formato de correo electrónico inválido').required('El correo electrónico es requerido'),
            telefono: Yup.string().required('El teléfono es requerido').matches(/^\d{10}$/, 'El teléfono debe tener 10 dígitos numéricos'),
            estatus_id: Yup.number().required('El estatus es requerido'),
            //responsable_id: Yup.number().required('El responsable es requerido'),
            calle: Yup.string().required('La calle es requerida'),
            exterior: Yup.string().required('El numero exterior es requerido'),
            cp: Yup.object().required('El código postal es requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await sucursalesResource('post', values)
            console.log(response, 'respueste')
            if (response.status) {
                values.token = response.token;
                dispatch(update_sucursal({ ...values }))
                validation.resetForm();
                navigate(`/sucursal/nueva/${response.sucursal.id}/${response.token}`);
            } else {
                setErrors(response.response.data.errors)
            }
        },
    });
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: sucursal.estado_id, municipio_id: sucursal.ciudad_id } });
            callback(options.data);
        }
    };

    return (
        <div className="d-flex">
            <main className="flex-grow-1">
                <p>Paso 2. Datos de la Sucursal</p>
                <Form
                    onSubmit={e => {
                        console.log(validation)
                        e.preventDefault();
                        validation.handleSubmit();

                        return false;

                    }}
                >
                    <div className="col-8">
                        <div className="row">
                            <div className="col-sm-6">
                                <label className="form-label">Nombre</label>
                                <Input
                                    name="nombre"
                                    className="inputAñadirInventario"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.nombre || ""}
                                    invalid={
                                        validation.touched.nombre &&
                                            validation.errors.nombre
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Estado de la sucursal</label>
                                <CustomSelect
                                    name="estatus_id"
                                    options={selects.estatus} // Opciones para el Select
                                    value={selects.estatus.find(option => option.value === validation.values.estatus_id)}
                                    onChange={option => validation.setFieldValue('estatus_id', option.id)}
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.estatus_id && validation.errors.estatus_id) && <FormFeedback type="invalid">{validation.errors.estatus_id.value ? validation.errors.estatus_id.value : 'Error'}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Telefono</label>
                                <Input
                                    name="telefono"
                                    type="text"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.telefono || ""}
                                    invalid={
                                        validation.touched.telefono &&
                                            validation.errors.telefono
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.telefono && validation.errors.telefono) && <FormFeedback type="invalid">{validation.errors.telefono}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Correo Electronico</label>
                                <Input
                                    name="email"
                                    type="email"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email &&
                                            validation.errors.email
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.email && validation.errors.email) && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Responsable del almacén</label>
                                <CustomSelect
                                    name="responsable_id"
                                    options={[]} // Opciones para el Select
                                    value={selects.colaboradores.find(option => option.value === validation.values.responsable_id)}
                                    onChange={option => validation.setFieldValue('responsable_id', option.id)}
                                    getOptionslabel={(option) => option.nombre}
                                    getOptionsValue={(option) => option.id}
                                    onClick={() => console.log('clocl')}
                                />
                                {(validation.touched.responsable_id && validation.errors.responsable_id) && <FormFeedback type="invalid">{validation.errors.responsable_id.value ? validation.errors.responsable_id.value : 'Error'}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <br/><br/>
                                <FormGroup check>
                                    <Input type="checkbox"
                                        name="is_matriz"
                                        value={validation.values.is_matriz}
                                        onChange={(e) => validation.values.is_matriz = !validation.values.is_matriz}
                                    />
                                    <Label check>
                                        ¿Es la sucursal matriz?
                                    </Label>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <br />
                                <h5><b>Dirección</b></h5>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Calle</label>
                                <Input
                                    name="calle"
                                    type="text"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.calle || ""}
                                    invalid={
                                        validation.touched.calle &&
                                            validation.errors.calle
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.calle && validation.errors.calle) && <FormFeedback type="invalid">{validation.errors.calle}</FormFeedback>}
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Numero Ext.</label>
                                <Input
                                    name="exterior"
                                    type="text"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.exterior || ""}
                                    invalid={
                                        validation.touched.exterior &&
                                            validation.errors.exterior
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.exterior && validation.errors.exterior) && <FormFeedback type="invalid">{validation.errors.exterior}</FormFeedback>}
                            </div>
                            <div className="col-sm-3">
                                <label className="form-label">Numero Int.</label>
                                <Input
                                    name="interior"
                                    type="text"
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.interior || ""}
                                    invalid={
                                        validation.touched.interior &&
                                            validation.errors.interior
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.interior && validation.errors.interior) && <FormFeedback type="invalid">{validation.errors.interior}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Codigo Postal</label>
                                <AsyncSelect
                                    styles={customStyles}
                                    cacheOptions
                                    defaultOptions
                                    value={validation.values.cp}
                                    onChange={option => [validation.setFieldValue('cp', option),validation.setFieldValue('cp_id', option.id), validation.setFieldValue('colonia', option.asentamiento)]}
                                    loadOptions={loadOptions}
                                    getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                                    getOptionValue={(option) => option.id}
                                />
                                {(validation.touched.cp && validation.errors.cp) && <FormFeedback type="invalid">{validation.errors.cp.value ? validation.errors.cp.value : 'Error'}</FormFeedback>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Colonia</label>
                                <Input
                                    name="colonia"
                                    type="text"
                                    readOnly
                                    className="inputAñadirInventario"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.colonia || ""}
                                    invalid={
                                        validation.touched.colonia &&
                                            validation.errors.colonia
                                            ? true
                                            : false
                                    }
                                />
                                {(validation.touched.colonia && validation.errors.colonia) && <FormFeedback type="invalid">{validation.errors.colonia}</FormFeedback>}
                            </div>
                            <div className="col-sm-12"></div>
                            <div className="col-sm-6">
                                <br /><br />
                                <Button color="secondary" type="submit">Continuar</Button>
                            </div>
                        </div>
                        <div>
                            <br /><br /><br /><br /><br /><br /><br /><br />
                        </div>
                    </div>
                </Form>

            </main>
        </div>
    )
}

const mapStateToProps = state => ({
    sucursal: state.Sucursal.sucursal,
    validacion: state.Sucursal.validacion
});

export default connect(mapStateToProps)(NuevaSucursal);