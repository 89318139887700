import React, { useEffect, useState } from "react";
import { Table, Modal, Button, ModalBody, ModalFooter, ModalHeader, Form, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";

const HistorialProducto = ({ modal, setModal, producto, filters, setFilters }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const transacciones = [
        { value: 'compra', label: 'compra' },
        { value: 'venta', label: 'venta' },
    ];
    const [historias,setHistorias] = useState(producto.historia)

    const [selectProvClient,setSelectProvClient] = useState(null);
    
    const [selectTransaction, setSelectTransaction] = useState(null)
    const handleDateChange = (date, setter, first = false) => {
        setter(date);
        if (first) {
            setFilters({ ...filters, fecha_inicio: date })
        } else {
            setFilters({ ...filters, fecha_fin: date })
        }
    };
    useEffect(() => {
        setHistorias(producto.historia)
    }, [producto]);
    
    const changePersona = (nombre_persona) => {
        setHistorias(historias.filter((h)=>{
            if(h.nombre_persona==nombre_persona){
                return h
            }
            
        }))
    }

    const changeTransact = (nombre) => {
        setHistorias(historias.filter((h)=>{
            if(h.tipo_transaccion==nombre){
                return h
            }
            
        }))
    }
    return (
        <>
            <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={'xl'}>
                <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                    Detalle de producto
                </ModalHeader>

                <ModalBody>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <b>Filtrar por periodo de </b>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleDateChange(date, setStartDate, 'first')}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Fecha inicial"
                                />
                                <b> al</b>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => handleDateChange(date, setEndDate)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="Fecha final"
                                />
                            </div>
                            <div className="col-sm-4">
                                    <b>Tipo de transaccion</b>
                                    <Select
                                        name="tipo_id"
                                        options={transacciones}
                                        value={selectTransaction}
                                        onChange={option => [setSelectTransaction(option),changeTransact(option.tipo_transaccion)]}
                                    />
                            </div>
                            <div className="col-sm-4">
                            <b>Proveedor / Cliente</b>
                                    <Select
                                        name="tipo_id"
                                        options={producto.historia}
                                        value={selectProvClient}
                                        onChange={option => [setSelectProvClient(option),changePersona(option.nombre_persona)]}
                                        getOptionLabel={(option) => `${option.nombre_persona}`}
                                        getOptionValue={(option) => option.nombre_persona}
                                    />
                            </div>
                            
                            <br/>
                            <div className="table-responsive table-responsive-sucursales">
                                
                                <table className="table table-sucursales">
                                    <thead className='text-center'>
                                        <tr>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Factura</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Costo unitario</th>
                                            <th scope="col">Proveedor/Cliente</th>
                                            <th scope="col">Metodo de pago</th>
                                            <th scope="col">Total Pagado</th>
                                            <th scope="col">Estado T.</th>
                                            <th scope="col">Responsable</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {historias.length ? historias.map((p, index) => (
                                            <tr className='' key={index}>
                                                <th scope="col">{p.fecha}</th>
                                                <th scope="col">{12345678}</th>
                                                <th scope="col">{Number(p.cantidad).toLocaleString('US')}</th>
                                                <th scope="col">${Number(p.precio).toLocaleString('US')}</th>
                                                <th scope="col">{p.nombre_persona}</th>
                                                <th scope="col">{p.nombreMetodo}</th>
                                                <th scope="col">${Number(p.sumaProductos).toLocaleString('US')}</th>
                                                <th scope="col">Estado T.</th>
                                                <th scope="col">Colaborador responsable</th>
                                            </tr>
                                        ))
                                            :
                                            <tr style={{ textAlign: 'center' }}>
                                                <th colSpan={16} style={{ textAlign: 'center' }}>
                                                    No hay registros para mostrar
                                                </th>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br />
                </ModalBody>
                <ModalFooter>
                    
                </ModalFooter>
            </Modal >
        </>
    )
}
export default HistorialProducto;