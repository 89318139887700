import React from 'react';
import { ToastContainerHelper } from '../../helpers/alerts/alertHelper';

const Card = ({ titlePrincipal, monto, fecha, ingreso }) => {
  return (
    <div className='cardModulo p-3 text-start mx-4'>
      
        {/* Utiliza em para el tamaño del texto para que sea relativo al tamaño de fuente base de su contenedor */}
        <h6 className='p-1' style={{ fontSize: "0.9em" }}>{titlePrincipal}<span style={{color: "#04B606"}} className="text-Color">{ingreso}</span></h6>
        {/* Ajusta el tamaño de fuente principal basado en vw para que sea relativo al ancho de la ventana de visualización */}
        <h1 className='p-1' style={{ fontSize: "2em" }}>{monto}</h1>
        {/* Usa em para el tamaño del texto para que sea relativo al tamaño de fuente base de su contenedor */}
        
    </div>
  )
}

export default Card;
