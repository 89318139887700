import React, { useState } from 'react'
import InformeModal from '../../Informes/InformeModal';
import Actionmodal from '../../Informes/Actionmodal';

const TablaHistorial = ({movimientos,setMovimientos,sucursal,setFilters,filters}) => {
  const [movimiento,setMovimiento] = useState({id:null,movimiento_tipo:{nombre:''},colaborador:{name:''},productosData:[]});
  const [modalView, setModalView] = useState('details');
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalInforme,setModalInforme] = useState({show:false});
  const [modalAction,setModalAction] =useState({show:false,action:''})
  const handleShowModal = (item) => {
    setShowEditModal(true);
  };

  const getMovimiento = async(mov) =>{
    setMovimiento(mov);
    setModalInforme({show:true});
  }

  const confirm = async() => {
    console.log(movimiento)
    //await movimientosResource('put',movimiento,movimiento.id)
  }
  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
      <div className="table-responsive table-responsive-sucursales">
        <table className="table table-sucursales">
          <thead className='text-center'>
            <tr>
              <th scope="col">Folio</th>
              <th scope="col">Fecha</th>
              <th scope="col">Operación en</th>
              <th scope="col">Tipo</th>
              <th scope="col">Tipo M.</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Total</th>
              <th scope="col">Ingreso/Egreso</th>
              <th scope="col">Rendimiento</th>
              <th scope="col">Estatus</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {movimientos.length ? movimientos.map((mov) => (
              <tr key={mov.id}>
                <td><span>{mov.id}</span></td>
                <td>{mov.created_at}</td>
                <td>{sucursal.nombre}</td>
                <td>{mov.movimiento_tipo.nombre}</td>
                <td>MXN</td>
                <td>{Number(mov.cantidad).toLocaleString('en-US')}</td>
                <td>${Number(mov.precio_total).toLocaleString('en-US')}</td>
                <td>Egreso</td>
                <td>Rendimiento</td>
                <td>{mov.estatus.nombre}</td>
                <td>
                  {mov.can.change ?
                  <button className={mov.class} onClick={()=>getMovimiento(mov)}>
                    {mov.estatus.nombre}
                  </button>
                  :
                  <button className={mov.class} disabled>
                    {mov.estatus.nombre}
                  </button>
                  }
                </td>
              </tr>
            ))
            :
            <tr style={{ textAlign: 'center' }}>
                <th colSpan={16} style={{ textAlign: 'center' }}>
                    No hay registros para mostrar
                </th>
            </tr>
            }
            {/* Puedes agregar más filas aquí */}
          </tbody>
        </table>
      </div>
      <Actionmodal sucursal={sucursal} setFilters={setFilters} filters={filters} confirm={confirm} movimiento={movimiento} setMovimiento={setMovimiento} modal={modalAction} setModal={setModalAction}/>
      <InformeModal  setFilters={setFilters} filters={filters} movimiento={movimiento} modal={modalInforme} setModal={setModalInforme} modalAction={modalAction} setModalAction={setModalAction} setMovimiento={setMovimiento}/>
    </div>
  )
}

export default TablaHistorial