import React, { useState, useEffect } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input, FormFeedback, FormGroup, Label, List } from "reactstrap";
import AsyncSelect from 'react-select/async';
import { productosResource, movimientosResource, inventariosResource } from "../../../helpers/backend_helper";
import { ToastHelper } from "../../../helpers/alerts/alertHelper";
import Salidas from "./Salidas"
import Ajustes from "./Ajustes"
import Transpaso from "./Transpaso"
import Devolucion from "./Devolucion"
import PasswConfirm from "../../../components/CommonForBoth/PasswConfirm";
import ConfirmacionModal from "../../../components/CommonForBoth/ConfirmacionModal"
import { detalle_sucursal } from "../../../store/titles/action";
import Select from "react-select";

const ModalMovimientos = ({ modal, setModal, sucursal, dispatch }) => {
    const [confPass, setConfPass] = useState({ show: false, status: false })
    const [confirm, setConfirm] = useState({ show: false, status: false, action: 'movimiento' })
    const [movimientoBase, setMovimientoBase] = useState({
        movimiento: null,
        productos: []
    })
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };
    const [movimiento, setMovimiento] = useState({movimiento: null})
    const [error, setError] = useState({})
    const productoBase = {
        nombre: '',
        id: null,
        cantidad: '',
        solicitado: '',
        stock: false
    }
    const [productos, setProductos] = useState([])
    const [inventarios, setInventarios] = useState([])
    const [producto, setProducto] = useState({ ...productoBase })
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 1) {
            const options = await productosResource('get', { params: { search: inputValue } });
            callback(options.productos);
        }
    };

    useEffect(() => {
        const getInventarios = async () => {
            const response = await inventariosResource('show', { params: { select: true } }, sucursal.id);
            if (response.status) {
                setInventarios(response.inventarios)
            }
        }
        getInventarios()
    }, []);

    const save = async () => {
        const data = { productos: productos, movimiento: movimiento.movimiento, sucursal_id: modal.sucursal_id, movimiento_id: modal.movimiento_id };
        const response = await movimientosResource('post', data);
        if (response.status) {
            let data = { movimiento_id: response.movimiento.id, action: 'movimiento' }
            ToastHelper('Movimiento creado en espera de confirmacion por el supervisor.', 'success');
            closeModal();
        } else {

        }
        console.log(response)
    }
    const closeModal = () => {
        dispatch(detalle_sucursal({
            insumos: false,
            movimiento: false,
            update: false,
            delete: false,
            save: false,
        }))
        setMovimiento({movimiento: null})
        setProductos([])
        setModal({ ...modal, show: false })
    }

    const sendMov = () => {
        setConfPass({ ...confPass, show: true })
    }

    const addCantidad = (e) => {
        if(e.target.value <= 0){
            ToastHelper('No puede ser igual o menor a 0', 'error')
            return false;
        }
        if (e.target.value <= parseFloat(producto.cantidad)) {
            setError({});
            setProducto({ ...producto, solicitado: e.target.value })
        } else {
            ToastHelper('Superaste el limite disponible en stock', 'error')
        }
    }

    const addProducto = async () => {
        const validation = productos.filter((p) => p.id === producto.id);
        let canSave = validation.length === 0;
        if(producto.solicitado <= 0 || producto.solicitado==undefined){
            ToastHelper('la cantidad no puede ser igual o menor a 0', 'error')
            return false;
        }
        if (canSave) {
            console.log(movimiento)
            if (movimiento.movimiento == 'transpaso') {
                console.log(producto.sucursal_destino_id,'sucursal_destino')
                if (producto.sucursal_destino_id == undefined) {
                    ToastHelper('Tienes que elegir una sucursal de destino', 'error')
                    return false;
                }
            }
            setProductos([...productos, producto]);
            setProducto({})


        } else {
            ToastHelper('Ya tienes el producto en tu inventario', 'error')
        }
    }

    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} backdrop="static">
            <ModalHeader toggle={() => closeModal()}>{modal.title ? modal.title : 'Realizar Movimiento'}</ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <List>
                                {productos.map((p) => (
                                    <li key={p.id}>
                                        {`${p.nombre} - Cantidad ${p.solicitado}`}
                                    </li>
                                ))}

                            </List>
                            {movimiento.movimiento ?
                                <>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label className="form-label">Buscador de productos </label>
                                            <Select
                                                styles={customStyles}
                                                options={inventarios} // Opciones para el Select
                                                value={producto}
                                                onChange={option => [setProducto({ ...option, donacion: false, stock: 0 })]}
                                                getOptionLabel={(option) => `${option.nombre}`}
                                                getOptionValue={(option) => option.id}
                                            />

                                            <br />

                                        </div>
                                    </div>
                                    {producto.id && movimiento.movimiento == 'salidas' &&
                                        <Salidas addCantidad={addCantidad} setMovimiento={setMovimiento} movimiento={movimiento} producto={producto} setProducto={setProducto} />
                                    }
                                    {producto.id && movimiento.movimiento == 'transpaso' &&
                                        <Transpaso addCantidad={addCantidad} setMovimiento={setMovimiento} sucursalOrigen={sucursal} movimiento={movimiento} producto={producto} setProducto={setProducto} />
                                    }
                                    {producto.id && movimiento.movimiento == 'ajustes' &&
                                        <Ajustes addCantidad={addCantidad} setMovimiento={setMovimiento} movimiento={movimiento} producto={producto} setProducto={setProducto} />
                                    }
                                    {producto.id && movimiento.movimiento == 'devoluciones' &&
                                        <Devolucion addCantidad={addCantidad} setMovimiento={setMovimiento} movimiento={movimiento} producto={producto} setProducto={setProducto} />
                                    }
                                </>
                                :
                                <>
                                    <label className="form-label">Selecciona el tipo de movimiento a realizar</label>
                                    <br />
                                    <FormGroup tag="fieldset">
                                        <FormGroup check>
                                            <Input
                                                name="mov"
                                                type="radio"
                                                onChange={(e) => [setMovimientoBase({ ...movimiento, movimiento: 'salidas', movimiento_id: 1 }), setModal({ ...modal, title: 'Agregar producto a Salida', movimiento_id: 1 })]}
                                            />
                                            {' '}
                                            <Label check>Salida / Donación / Caducidad</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                name="mov"
                                                type="radio"
                                                onChange={(e) => [setMovimientoBase({ ...movimiento, movimiento: 'transpaso', movimiento_id: 2 }), setModal({ ...modal, title: 'Movimiento de traspaso', movimiento_id: 2 })]}
                                            />
                                            {' '}
                                            <Label check>Traspaso</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                name="mov"
                                                type="radio"
                                                onChange={(e) => [setMovimientoBase({ ...movimiento, movimiento: 'ajustes', movimiento_id: 3 }), setModal({ ...modal, title: 'Movimiento de ajuste', movimiento_id: 3 })]}
                                            />
                                            {' '}
                                            <Label check>Ajustes de inventario</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                name="mov"
                                                type="radio"
                                                onChange={(e) => [setMovimientoBase({ ...movimiento, movimiento: 'devoluciones', movimiento_id: 4 }), setModal({ ...modal, title: 'Movimiento de devolucion', movimiento_id: 4 })]}
                                            />
                                            {' '}
                                            <Label check>Devolución</Label>
                                        </FormGroup>
                                    </FormGroup>
                                </>
                            }
                        </div>
                    </div>
                    {!movimiento.movimiento ?
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <Button onClick={() => setMovimiento({ ...movimientoBase })} className="mt-3 fontBold500 button-modal-eliminacion col-7">
                                Continuar
                            </Button>{' '}
                        </div>
                        :
                        <>
                            {producto.id ?

                                <ModalFooter>
                                    <Button color="secondary" onClick={() => [setProducto({})]}>
                                        Descartar
                                    </Button>
                                    <Button className='buttonAñadirInventarioOption' onClick={() => [addProducto()]}>
                                        Añadir producto
                                    </Button>{' '}
                                </ModalFooter>
                                :
                                <div className="col-12" style={{ textAlign: 'center' }}>
                                    {productos.length ?
                                        <Button onClick={() => sendMov()} className="mt-3 fontBold500 button-modal-eliminacion col-7">
                                            Finalizar carga de productos
                                        </Button>
                                        :
                                        <Button onClick={() => setMovimiento({ ...movimiento, movimiento: '' })} className="mt-3 col-7">
                                            cancelar
                                        </Button>
                                    }

                                </div>
                            }
                        </>
                    }


                </div>
                <br />
            </ModalBody>
            <PasswConfirm modal={confPass} setModal={setConfPass} save={save} />
        </Modal>
    )
}
export default ModalMovimientos;