import {
    UPDATE_TITLE,
    DETALLE_SUCURSAL
  } from "./actionTypes"

  export const update_title = title => {
    return {
      type: UPDATE_TITLE,
      payload: title,
    }
  }

  export const detalle_sucursal = data => {
    return {
      type: DETALLE_SUCURSAL,
      payload: data,
    }
  }