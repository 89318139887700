import React, { useEffect, useState } from 'react'
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import { productosProveedoresResource } from '../../helpers/backend_helper';
import EditProduct from './modals/EditProduct';

const ProductosOfrece = (props) => {
  const [showModalEdit,setShowModalEdit] = useState({show:false,detalle:true})
  const [productos, setProductos] = useState([])
  useEffect(() => {
    const getProductos = async () => {
      const response = await productosProveedoresResource('get', { params: { proveedor: props.proveedor.id } })
      console.log(props.proveedor.id,'saicjicjsaijcijascijsicj')
      setProductos(response.productos)
    }
    getProductos()
  }, [props.filters]);

  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
      <div className="table-responsive table-responsive-sucursale table-proveedores">
        <table className="table">
          <thead className='text-start'>
            <tr className=''>
              <th scope="col">Nombre</th>
              <th scope="col">Departamento</th>
              <th scope="col">Precio</th>
              <th scope="col">Descripción</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody className='text-start'>
            {productos.length ? productos.map((p) => (
              <tr key={p.producto.id}>
                <td>{p.producto.nombre}</td>
                <td>{p.producto.categoria.nombre}</td>
                <td>{p.precio}</td>
                <td>{p.producto.nombre}</td>
                <td className='d-flex justify-content-between col-9'>
                  <a className='' href='#' onClick={() => setShowModalEdit({ ...showModalEdit, show: true,producto:p })}><FaEdit /></a>
                  <a className='' href='#' ><FaRegTrashAlt /></a>
                </td>
              </tr>
            ))
              :
              <tr>
                <th>No se han registrado productos</th>
              </tr>
            }

          </tbody>
        </table>
      </div>
      {showModalEdit.show &&
        <EditProduct filters={props.filters} setFilters={props.setFilters} modal={showModalEdit} setModal={setShowModalEdit} />
      }
    </div>
  )
}

export default ProductosOfrece;