import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/Login.css";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";
import { cotizacionRespuesta } from "../../helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Respuesta = () => {
    const navigate = useNavigate()
    const { id, token } = useParams()
    const [file, setFile] = useState({ archivo: null })
    const sabeFile = async () => {
        const response = await cotizacionRespuesta(file, id)
        if (response.status) {
            toast.success('Respuesta guardada correctamente', { theme: "light" });
            window.location.href = 'https://mkpagricola.com/'
        }
    }

    const addFile = async (e, name) => {
        const archivo = e.target.files[0];
        try {
            const base64 = await getBase64(archivo);
            setFile({ cotizacion_id: id, archivo: base64, token: token });
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    return (
        <>
            <div className="LoginContainer">

                <div className="imageLoginContainer d-none d-lg-flex">
                    <img src="/assets/images/imageLogin.svg" alt="Login" className="imgLogin"></img>
                </div>

                <div className="inputsLoginContainer mt-5 mb-5">
                    <div className="logoContainer">
                        <img src="/assets/images/icon.svg" alt="Icon"></img>

                    </div>
                    <div className="col-sm-10">
                        <label className='labelAñadir'>Respuesta de cotizacion</label>
                        <div className="upload-container col-12">
                            <input type="file" id="file-upload" className="input-file" onChange={(e) => addFile(e)} accept=".pdf" />
                            <label htmlFor="file-upload" className="upload-box">
                                <div className="upload-icon col-12">
                                    {/* Aquí podrías incluir el ícono de carga */}

                                    <img src={`${process.env.PUBLIC_URL}/assets/images/file.svg`} alt="Upload" />
                                </div>
                                <p className="upload-text">{file.archivo ? 'Documento agregado' : 'Carga aqui el documento'}</p>
                                {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Vista previa" />} */}
                            </label>
                            <br /><br />
                        </div>
                        <Button color="primary" block type="submit" onClick={() => sabeFile()}>
                            Añadir evidencia
                        </Button>{' '}
                    </div>
                </div>

            </div>


        </>
    )
}
export default Respuesta;