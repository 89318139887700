import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/Compras.css"
import { proveedoresResource } from "../../helpers/backend_helper";
import CardProveedor from "./CardProveedores";
import { FilterSearch,PaginationNo } from "../../components/CommonForBoth/tables/pagination";
import ModalProveedor from "./modals/ModalProveedor";
import MasivaProveedor from "./modals/MasivaProveedor";

const ListProveedores = (filters, setFilters) => {
    const [masiva, setMasiva] = useState({ show: false })
    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState([]);
    const [modalProveedor,setModalProveedor] = useState({show:false})
    useEffect(() => {
        const getProveedores = async () => {
            const response = await proveedoresResource('get', { params: filters })
            setProveedores(response.proveedores)
        }
        getProveedores()
    }, [filters]);
    
    return (
        <>
            
            <div>
                <button className='buttonProveedores button-modal-eliminacion col-12 col-lg-2' onClick={() => setModalProveedor({show:true})}>Añadir nuevo proveedor</button>
            </div>
            <FilterSearch filters={filters} setFilters={setFilters}  masiva={setMasiva} title={'Importar Proveedores de Excel'}/>
            <div className='containerCardsProveedores col-12 mt-3 d-flex flex-wrap'>

                {
                    proveedores.length ? proveedores.map((p) => (
                        <CardProveedor proveedor={p} key={p.id} />
                    ))
                    :
                    <p>No hay registros para mostrar</p>
                }
                <PaginationNo filters={filters} setFilters={setFilters} />
            </div>
            <ModalProveedor modal={modalProveedor} setModal={setModalProveedor}/>
            {masiva.show &&
        <MasivaProveedor modal={masiva} setModal={setMasiva}/>
      }
        </>
    )
}
export default ListProveedores;