import React, { useState, useEffect } from 'react';
import { Form, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cpResource, getProveedores_tipos, estadosMunicipiosCatalogo, ciudadesResource } from "../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { proveedoresResource } from '../../helpers/backend_helper';

const InformacionGeneral = (props) => {

    const [showAddModal, setShowAddModal] = useState(false);
    const [modalView, setModalView] = useState('details');

    const [selects, setSelects] = useState({
        optionsProveedor: [
            { label: 'Acreedor', value: 1 },
            { label: 'Proveedor', value: 0 }
        ],
        estados: [],
        tipos: [],
        municipios: []
    })

    const showModalAdd = (item) => {
        setShowAddModal(true);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#e9ecef',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: props.proveedor,
        validationSchema: Yup.object({
            nombre: Yup.string().required('Nombre es requerido'),
            telefono: Yup.string().required('Teléfono es requerido'),
            web: Yup.string().url('La dirección web no es válida'),
            tipo_id: Yup.number().integer('Tipo debe ser un número entero'),
            estado_id: Yup.number().integer('Tipo debe ser un número entero'),
        }),
        onSubmit: async (values, { setErrors }) => {
            values.type = 'general';
            const response = await proveedoresResource('put', values, props.proveedor.id);
            if (response.status) {
                props.setProveedor(response.data);
                props.setUpdate(false)
            }
        },
    });

    useEffect(() => {
        const getSelects = async () => {
            try {
                const [tipos, estados] = await Promise.all([
                    getProveedores_tipos(),
                    estadosMunicipiosCatalogo({ params: { estados: true } })
                ]);

                setSelects({
                    ...selects,
                    tipos: tipos.tipos,
                    estados: estados.data
                });
            } catch (error) {
                console.error('Error al obtener selects:', error);
            }
        };
        getSelects();
    }, []);

    useEffect(() => {
        const getCiudadesCatalogo = async () => {
            const response = await ciudadesResource('get', { params: { estado_id: validation.values.estado_id } });
            if (response.status) {
                setSelects({ ...selects, municipios: response.ciudades })
            }
        }
        if (validation.values.estado_id) {
            getCiudadesCatalogo()
        }
    }, [validation.values.estado_id]);

   
    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 2) {
            const options = await cpResource('get', { params: { search: inputValue, estado_id: validation.values.estado_id, municipio_id: validation.values.municipio.id } });
            callback(options.data);
        }
    };

    return (
        <>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (props.update)
                        validation.handleSubmit();
                    console.log(validation)
                    return false;
                }}
            >
                <div className='tableInformacionGeneral col-11 col-lg-11 d-flex flex-column p-3'>
                    <div className='d-flex col-12 col-lg-11 justify-content-start align-items-center flex-column flex-lg-row'>
                        <div className='col-2 d-flex justify-content-center mx-4'>
                            <img className='d-flex imgProveedorDetalle' src={`${process.env.PUBLIC_URL}/assets/images/user.jpeg`} style={{ width: '260px' }} />
                        </div>
                        <div className='mt-3 mt-lg-0 d-flex col-12 col-lg-9 justify-content-start align-items-end flex-column'>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-8 ml-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Nombre de la persona física o moral</label>
                                    <Input
                                        disabled={!props.update}
                                        name="nombre"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.nombre || ""}
                                        invalid={
                                            validation.touched.nombre &&
                                                validation.errors.nombre
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.nombre && validation.errors.nombre) && <FormFeedback type="invalid">{validation.errors.nombre}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>País</label>
                                    <input className='inputDetalleProveedor' value={'Mexico'} disabled />
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Estado</label>
                                    <Select
                                        isDisabled={!props.update}
                                        styles={customStyles}
                                        name="estado_id"
                                        options={selects.estados}
                                        value={validation.values.estado}
                                        onChange={option => [validation.setFieldValue('estado_id', option.id), validation.setFieldValue('estado', option)]}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {(validation.touched.estado_id && validation.errors.estado_id) && <FormFeedback type="invalid">{validation.errors.estado_id}</FormFeedback>}
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-4 px-lg-2'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número de contacto</label>
                                    <Input
                                        disabled={!props.update}
                                        name="telefono"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.telefono || ""}
                                        invalid={
                                            validation.touched.telefono &&
                                                validation.errors.telefono
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.telefono && validation.errors.telefono) && <FormFeedback type="invalid">{validation.errors.telefono}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Correo eléctronico</label>
                                    <Input
                                        disabled={!props.update}
                                        name="datos_fiscales.email"
                                        type="email"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.datos_fiscales?.email || ""}
                                        invalid={
                                            validation.touched.datos_fiscales?.email &&
                                                validation.errors.datos_fiscales?.email
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.datos_fiscales?.email && validation.errors.datos_fiscales?.email) && <FormFeedback type="invalid">{validation.errors.datos_fiscales?.email}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>

                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Ciudad</label>
                                    <Select
                                        isDisabled={!validation.values.estado_id}
                                        styles={customStyles}
                                        name="municipio"
                                        options={selects.municipios}
                                        value={validation.values.municipio}
                                        onChange={option => [validation.setFieldValue('municipio', option), validation.setFieldValue('municipio_id', option.id), validation.setFieldValue('cp.id', null), validation.setFieldValue('cp', null)]}
                                        getOptionLabel={(option) => `${option.no} - ${option.nombre}`}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {(validation.touched.municipio && validation.errors.municipio) && <FormFeedback type="invalid">{validation.errors.municipio}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>

                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>C.P.</label>
                                    <AsyncSelect
                                        isDisabled={!props.update}
                                        styles={customStyles}
                                        cacheOptions
                                        defaultOptions
                                        value={validation.values.cp}
                                        onChange={option => [validation.setFieldValue('cp', option), validation.setFieldValue('cp_id', option.id), validation.setFieldValue('colonia', option.asentamiento)]}
                                        loadOptions={loadOptions}
                                        getOptionLabel={(option) => `${option.cp} - ${option.asentamiento}`}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {(validation.touched.cp && validation.errors.cp) && <FormFeedback type="invalid">{validation.errors.cp.value ? validation.errors.cp.value : 'Error'}</FormFeedback>}
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-8 ml-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Tipo de proveedor</label>
                                    <Select
                                        isDisabled={!props.update}
                                        styles={customStyles}
                                        name="tipo_id"
                                        options={selects.tipos}
                                        value={validation.values.tipo}
                                        onChange={option => [validation.setFieldValue('tipo_id', option.id), validation.setFieldValue('tipo', option)]}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                        getOptionValue={(option) => option.id}
                                    />
                                    {(validation.touched.tipo_id && validation.errors.tipo_id) && <FormFeedback type="invalid">{validation.errors.tipo_id}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Colonia</label>
                                    <Input
                                        readOnly
                                        className="inputAñadirInventario"
                                        value={validation.values.direccion.cp.asentamiento}
                                    />
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Calle</label>
                                    <Input
                                        disabled={!props.update}
                                        name="direccion.calle"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.direccion?.calle || ""}
                                        invalid={
                                            validation.touched.direccion?.calle &&
                                                validation.errors.direccion?.calle
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.direccion?.calle && validation.errors.direccion?.calle) && <FormFeedback type="invalid">{validation.errors.direccion?.calle}</FormFeedback>}
                                </div>
                            </div>
                            <div className='d-flex col-12 col-lg-12 align-self-start mx-lg-3 justify-content-start mb-2 flex-column flex-lg-row'>
                                <div className='d-flex flex-column col-12 col-lg-8 ml-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Sitio Web</label>
                                    <Input
                                        disabled={!props.update}
                                        name="web"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.web || ""}
                                        invalid={
                                            validation.touched.web &&
                                                validation.errors.web
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.web && validation.errors.web) && <FormFeedback type="invalid">{validation.errors.web}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número ext.</label>
                                    <Input
                                        disabled={!props.update}
                                        name="direccion.exterior"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.direccion?.exterior || ""}
                                        invalid={
                                            validation.touched.direccion?.exterior &&
                                                validation.errors.direccion?.exterior
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.exterior && validation.errors.exterior) && <FormFeedback type="invalid">{validation.errors.exterior}</FormFeedback>}
                                </div>
                                <div className='d-flex flex-column col-12 col-lg-3 px-lg-4'>
                                    <label className='labelSucursal inputModalVerProducto d-flex justify-content-start'>Número int.</label>
                                    <Input
                                        disabled={!props.update}
                                        name="direccion.interior"
                                        className="inputDetalleProveedor"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.direccion?.interior || ""}
                                        invalid={
                                            validation.touched.direccion?.interior &&
                                                validation.errors.direccion?.interior
                                                ? true
                                                : false
                                        }
                                    />
                                    {(validation.touched.interior && validation.errors.interior) && <FormFeedback type="invalid">{validation.errors.interior}</FormFeedback>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.update &&
                        <button className={`btnVer col-3 mx-2`} type='submit'>
                            Guardar
                        </button>
                    }
                </div>
            </Form>
        </>

    );
}

export default InformacionGeneral;
