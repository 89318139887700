import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label,Table } from "reactstrap";
import Evidencia from "./Evidencia";
import PreviewPDF from "../../../components/CommonForBoth/previewPDF";

const CotizacionDetalle = ({ modal, setModal }) => {
    
    const [productos, setProductos] = useState([]);
    const [cotizacion, setCotizacion] = useState({})
    const [modalFile, setModalFile] = useState({show:false})
    const [step, setStep] = useState({ step: 2, name: 'Paso 1 - Selección de tipo de compra', size: 'ls' });
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Detalle de cotización
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        
                        <div className="col-sm-12">

                            <div className="row">

                                <div className="col-sm-6">
                                    <label className="form-label">Tipo de compra</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.tipo.nombre || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Tipo de compra</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.sucursal.nombre || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Responsable</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.responsable.name || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Requerimiento</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.nombre_requerimiento || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Proveedor</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.nombre_requerimiento || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Fecha Cotizacion</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.created_at || ""}
                                    />
                                </div>

                                <div className="col-sm-6">
                                    <label className="form-label">Estatus de compra</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.nombre_requerimiento || ""}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">Estatus de Cotizacion</label>
                                    <Input
                                        readOnly
                                        name="nombre_requerimiento"
                                        className="inputAñadirInventario"
                                        value={modal.cotizacion.estatus.nombre || ""}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                                        <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ backgroundColor: 'transparent' }}>Cantidad</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>Cantidad Solicitada</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>P.unitario</th>
                                                    <th style={{ backgroundColor: 'transparent' }}>Importe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modal.cotizacion.productos.map((prod, i) => (
                                                    <tr key={i}>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.cantidad}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.producto.nombre}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.cantidad}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.precio_unitario}</th>
                                                        <th style={{ backgroundColor: 'transparent' }}>{prod.importe}</th>
                                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                                    <Button color="primary" block type="submit" onClick={()=>setModalFile({show:true,cotizacion:modal.cotizacion})}>
                                        Añadir evidencia
                                    </Button>{' '}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
            <Evidencia modal={modalFile} setModal={setModalFile}/>
            
        </Modal>
    )
}

export default CotizacionDetalle