import React, { useState, useRef } from 'react';
import { resetAuth, firstAuth } from "../../helpers/backend_helper"
import { Form, Input, FormFeedback } from "reactstrap";
import { useNavigate, useParams } from 'react-router-dom';

const TwoStep = () => {
    const navigate = useNavigate();
    const { action, token } = useParams();
    document.title = "Verificacion | MKP";

    const [values, setValues] = useState(['', '', '', '', '', '']);
    const inputsRefs = useRef([]);

    const sendCode = async () => {
        let code = values.join('');
        const response = (action === 'errorCode' || action === 'resetAccount') ? await resetAuth({ code: code, token: token }) : await firstAuth({ code: code, token: token });
        if (response.status) {
            if (action === 'errorCode' || action === 'resetAccount') {
                navigate("/login/errorCode/ok");
            } else {
                localStorage.setItem("authUser", JSON.stringify(response));
                navigate("/");
            }
        }
    };

    const handleChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
        if (value === '' && index > 0) {
            // Si el valor es vacío y no estamos en el primer input, movemos el foco al input anterior
            inputsRefs.current[index - 1].focus();
        } else if (value !== '' && index < 5) {
            // Si el valor no es vacío y no estamos en el último input, movemos el foco al siguiente input
            inputsRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && values[index] === '' && index > 0) {
            // Si se presiona la tecla de retroceso y el valor del input actual es vacío, movemos el foco al input anterior
            inputsRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="LoginContainer">
            <div className="imageLoginContainer d-none d-lg-flex">
                <img src="/assets/images/imageLogin.svg" alt="Login" className="imgLogin" />
            </div>
            <div className="inputsLoginContainer mt-5 mb-5">
                <div className="logoContainer">
                    <img src="/assets/images/icon.svg" alt="Icon" />
                    <div className="textImgLogin">{action === 'resetAccount' ? '¿Olvidaste tu contraseña?' : ''}</div>
                    <div className="textImgLogin">{action === 'resetAccount' ? 'Ingresa el código de verificación que recibiste en tu número de celular.' : ''}</div>
                </div>
                <div className="col-12">
                    <div className="col-6">
                        <div className="row">
                            <p>Codigo</p>
                            {[...Array(6)].map((_, index) => (
                                <div className="col-2" key={index} style={{ textAlign: 'center' }}>
                                    <input
                                        ref={(ref) => (inputsRefs.current[index] = ref)}
                                        type="text"
                                        value={values[index]}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        maxLength={1}
                                        style={{ backgroundColor: '#B2BD0066', width: '45px', height: '45px', textAlign: 'center' }}
                                    />
                                </div>
                            ))}
                            <div className="col-12 mt-5">
                                <button onClick={() => sendCode()} className="but-log">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoStep;
