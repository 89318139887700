import React, { useState } from 'react'

const TablaInventarioInterno = ({ inventarios }) => {
  const [activoSeleccionado, setActivoSeleccionado] = useState('Activos');

  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
      <div className="table-responsive table-responsive-sucursales">
        <div className='col-12 d-flex justify-content-center'>
          <button
            className={`mb-3 mt-3 col-2 ${activoSeleccionado === 'Activos' ? 'buttonAñadirInventario' : 'button-option-title'}`}
            onClick={() => setActivoSeleccionado('Activos')}
          >
            Activos
          </button>
          <button
            className={`mb-3 mt-3 col-2 ${activoSeleccionado === 'Consumibles' ? 'buttonAñadirInventario' : 'button-option-title'}`}
            onClick={() => setActivoSeleccionado('Consumibles')}
          >
            Consumibles
          </button>
        </div>
        <table className="table table-sucursales">
          <thead className='text-center'>
            <tr>
              <th scope="col">Número de serie</th>
              <th scope="col">Nombre del artículo</th>
              <th scope="col">Categoria</th>
              <th scope="col">Condición</th>
              <th scope="col">Proveedor</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Valor unitario</th>
              <th scope="col">Fecha de compra</th>
              <th scope="col">Observaciones</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {inventarios.length ? inventarios.map((p) => (
              <tr key={p.id}>
                <td><span>{p.id}</span></td>
                <td>{p.nombre}</td>
                <td>{p.producto.categoria.nombre}</td>
                <td>{'Nuevo'}</td>
                <td>{p.proveedor_nombre}</td>
                <td>{p.cantidad}</td>
                <td>{p.producto.precio}</td>
                <td>01/12/23</td>
                <td>No hay observaciones</td>
                <td className='d-flex justify-content-center'>
                  <button className='col-6 buttonOptionInvetarioInterno' onClick={() => abrirEditar({/* datos del item a editar */ })}><img src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`} /></button>
                  <button className='col-6 buttonOptionInvetarioInterno' onClick={() => abrirEliminar({/* datos del item a editar */ })}><img src={`${process.env.PUBLIC_URL}/assets/images/trash.svg`} /></button>
                </td>
              </tr>
            ))
              :
              <tr style={{ textAlign: 'center' }}>
                <th colSpan={16} style={{ textAlign: 'center' }}>
                  No hay registros para mostrar
                </th>
              </tr>
            }

          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TablaInventarioInterno