import React from 'react';

const OpcionesDetalleProveedor = ({ opcionActiva, onClickOpcion }) => {
  const opciones = [
    'Informacion general',
    'Datos fiscales',
    'Datos bancarios',
    'Documentos legales',
  ];

  return (
    <div className='col-12 d-flex flex-column-reverse flex-lg-row justify-content-between'>
        <div className='col-8 d-flex flex-column flex-lg-row'>
            {opciones.map((opcion) => (
              <button
                key={opcion}
                className={`mt-2 mt-lg-0 mx-1 px-3 btnOpcionDetalleProveedor ${opcionActiva === opcion ? 'btnOpcionDetalleProveedorActive' : ''}`}
                onClick={() => onClickOpcion(opcion)}
              >
                {opcion}
              </button>
            ))}
        </div>
        <div className='col-4'></div>
    </div>
  );
};

export default OpcionesDetalleProveedor;
