import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
export const FilterSearch = (props) => {
    const pagInateOptions = [
        { label: 'Todos', value: '' },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 }
    ]

    const [selectedSucursal, setSelectedSucursal] = useState('')
    return (
        <div className="d-flex flex-row justify-content-end w-100" style={{ marginTop: 4 + 'rem' }}>
            <div className='d-flex flex-row justify-content-end w-100 gap-2'>
                {props.reports &&
                <>
                    <button className='mkp-btn-secondary col-12 col-lg-2' onClick={() => props.reports({ report: 'excel' })}>Excel</button>
                    <button className='mkp-btn-danger col-12 col-lg-2' onClick={() => props.reports({ report: 'pdf' })}>Pdf</button>
                </>
                }
                {props.title &&
                    <button className='mkp-btn-primary col-6 col-lg-3' onClick={() => props.masiva({ show: true })}>{props.title}</button>
                }
                <div className='d-flex w-25'>
                    <select className='mkp-select border border-2 border-dark' value={props.filters.per_page} onChange={(e) => [props.setFilters({ ...props.filters, per_page: e.target.value })]}>
                        {
                            pagInateOptions.map((pag) =>
                                <option key={pag.value} value={pag.value}>{pag.label}</option>
                            )
                        }
                    </select>
                </div>
                {props.sucursales &&
                    <div className='d-flex w-25'>

                        <select className='mkp-select border border-2 border-dark' value={selectedSucursal} onChange={(e) => [props.setFilters({ ...props.filters, sucursal_id: e.target.value })]}>
                            <option value={''}>Sucursales</option>
                            {

                                props.sucursales.length && props.sucursales.map((pag) =>
                                    <option key={pag.id} value={pag.id}>{pag.nombre}</option>
                                )
                            }
                        </select>

                    </div>
                }
                <div className='d-flex w-50'>
                    <input type="text" placeholder='Buscar' value={props.filters.search} className='w-100 search' onChange={(e) => [props.setFilters({ ...props.filters, search: e.target.value })]} />
                </div>
            </div>
        </div>
    )
}

export const PaginationNo = (props) => {
    return (
        <div className="d-flex flex-row justify-content-end w-100" style={{ marginTop: 1 + 'rem' }}>
            <div className='d-flex flex-row justify-content-end w-50 gap-2'>
                <div className='d-flex w-15'>
                    <Pagination>
                        <PaginationItem>
                            <PaginationLink
                                first
                                href="#"
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                previous
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink className={props.filters.page == 1 ? 'active' : ''} onClick={() => props.setFilters({ ...props.filters, page: 1 })}>
                                1
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                next
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                last
                            />
                        </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </div>
    )
}

