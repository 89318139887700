import React, { useEffect, useState } from "react";
import { Form, Modal, Button, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import { ventasResource,ventasCancelar } from "../../../helpers/backend_helper";


const CancelarModal = ({ modal, setModal }) => {
    const [productos, setProductos] = useState([]);
    const [compra, setCompra] = useState({})
    const [step, setStep] = useState({ step: 1, name: 'Paso 1 - Selección de tipo de compra', size: 'ls' });
    const [cancelacionData, setCancelacionData] = useState({ venta: null, cancelar: true })

    const save = async () => {
        const response = await ventasCancelar(cancelacionData, modal.venta.id)
        
        if (response.status){
            setModal({show:false});
        }
    }
    return (
        <Modal isOpen={modal.show} toggle={() => setModal({ ...modal, show: false })} size={step.size}>
            <ModalHeader toggle={() => setModal({ ...modal, show: false })}>
                Cancelar compra
            </ModalHeader>
            <ModalBody>
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="form-label">Notas</label>
                            <textarea
                                style={{ width: '100%' }}
                                name="notas"
                                className="inputAñadirInventario" // Utilizamos la clase form-control de Bootstrap para estilos básicos de textarea
                                rows="10" // Establecemos el número de filas que se mostrarán inicialmente en el textarea
                                onChange={(e) => setCancelacionData({ ...cancelacionData, notas: e.target.value })}

                            />

                        </div>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block type="submit" onClick={()=>save()}>
                    Confirmar
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default CancelarModal