import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Table, ModalBody, ModalFooter, ModalHeader, Progress, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik, Field, ErrorMessage } from "formik";
import { cotizacionesResource, getCompras_tipos, productosResource } from "../../../helpers/backend_helper";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { sucursalesResource } from "../../../helpers/backend_helper";
import { ToastHelper } from "../../../helpers/alerts/alertHelper";

const Step2 = ({ modal, setModal, cotizacion, setCotizacion, setStep, productos, setProductos }) => {

    const [producto, setProducto] = useState(null);

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length > 1) {
            const options = await productosResource('get', { params: { select:true,search: inputValue,interno:cotizacion.tipo_id } });
            callback(options.productos);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b',
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: cotizacion,
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, { setErrors }) => {
            setCotizacion(values)
            setStep({ step: 2, name: 'Paso 2 - Selección de productos', size: 'ls' })
        },
    });

    const save = () => {
        const validation = productos.filter((p) => p.id === producto.id);
        let canSave = validation.length === 0;
        if (modal.update || canSave) {
            setProductos([...productos, producto])
            setProducto(null)    
        } else {
            ToastHelper('Ya tienes el producto en tu inventario','error')
        }
        
    }

    const nextStep = () => {
        setStep({ step: 3, name: 'Paso 3 - Selección de proveedor', size: 'xl' })
    }

    const productChange = (e,p) => {
        let array = productos.map((prod)=>{
            if(prod.id == p.id){
                prod.cantidad_solicitada=e.target.value     
            } 
            return prod
        })
        setProductos(array)
    }
    return (
        <div className="row">
            <div className="col-sm-12">
                <label className="form-label">Buscador de productos </label>
                <AsyncSelect
                    styles={customStyles}
                    cacheOptions
                    defaultOptions
                    value={producto}
                    onChange={option => [setProducto(option)]}
                    loadOptions={loadOptions}
                    getOptionLabel={(option) => `${option.nombre}`}
                    getOptionValue={(option) => option.id}
                />
                <br />
            </div>
            <div className="col-sm-12">
                <div className="card-body" style={{ backgroundColor: '#B2BD00', }}>
                    <Table style={{ backgroundColor: 'transparent', width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: 'transparent' }}>Nombre</th>
                                <th style={{ backgroundColor: 'transparent' }}>Unidad de Medida</th>
                                <th style={{ backgroundColor: 'transparent' }}>Cantidad Solicitada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.map((prod,i) => (
                                <tr key={i}>
                                    <th style={{ backgroundColor: 'transparent' }}>{prod.nombre}</th>
                                    <th style={{ backgroundColor: 'transparent' }}>{prod.unidad.nombre}</th>
                                    <th style={{ backgroundColor: 'transparent' }}>
                                        <Input
                                            name="cantidad_solicitada"
                                            className="inputAñadirInventario"
                                            onChange={(e)=>productChange(e,prod)}
                                            value={prod.cantidad_solicitada || ""}
                                            
                                        />
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            {productos.length ?
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block type="submit" onClick={() => nextStep()}>
                        Continuar
                    </Button>{' '}
                </div>
                :
                ''
            }

            {producto &&
                <div className="col-sm-6" style={{ textAlign: 'right' }}>
                    <Button color="primary" block onClick={() => save()}>
                        Añadir producto
                    </Button>{' '}
                </div>
            }

        </div>
    )
}

export default Step2