import React from 'react'

const TablaDetalleCompra = () => {

  return (
    <div className='col-12 col-lg-12 mt-0 h-100 containerTableSucursales'>
    <div className="table-responsive table-responsive-sucursales mi-tabla-scroll">
        <table className="table table-sucursales">
        <thead className='text-start table-thead'>
            <tr className='' style={{color: "white"}}>
            <th scope="col">SKU</th>
            <th scope="col">Categoría</th>
            <th scope="col">Nombre del producto</th>
            <th scope="col">Divisa</th>
            <th scope="col">Precio unitario</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Total</th>
            </tr>
        </thead>
        <tbody className='text-start table-tbody'>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            <tr>
              <td>8473782392</td>
              <td>Plaguicidas</td>
              <td>Lorem ipsum a673</td>
              <td>MXN</td>
              <td>$150.00</td>
              <td>5</td>
              <td>$750</td>
            </tr>
            {/* Puedes agregar más filas aquí */}
        </tbody>
        </table>
    </div>
</div>
  )
}

export default TablaDetalleCompra;