import React, { useMemo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CardCiudad from '../../components/INVENTARIOS/CardCiudad';
import Filtros from '../../components/INVENTARIOS/Filtros';
import "../../styles/Inventarios.css";
import { Input, Form, FormFeedback, Label, FormGroup } from 'reactstrap'
import { sucursalesResource, productosResource, ciudadesResource } from "../../helpers/backend_helper"
import ListProductos from './ListProductos'

const Inventarios = () => {
  const [tabActiva, setTabActiva] = useState('inventario');
  const [ciudades, setCiudades] = useState([])
  const [dataInventarios, setDataInventarios] = useState({ stockTotal: 0, precioTotal: 0 })
  const [sucursales, setSucursales] = useState([
    { id: 1, nombre: "San Luis Potosí", estado: "San Luis Potosí", sucursales: "02", creado: "Del 01 al 15 de Dic." },
    { id: 2, nombre: "Guadalajara", estado: "Jalisco", sucursales: "03", creado: "Del 01 al 15 de Dic." },
  ]);
  const [inventarios, setInventarios] = useState([]);

  useEffect(() => {
    const getCiudades = async () => {
      const response = await ciudadesResource('get', { params: { ciudades: true } });
      if (response.status) {
        setCiudades(response.ciudades)
      }
    }
    const getProductos = async () => {
      const response = await productosResource('get', { params: { resumen: true } });
      if (response.status) {
        setInventarios(response.productos)
        setDataInventarios({ precioTotal: response.precioTotal, stockTotal: response.stockTotal })
      }
    }
    getProductos()
    getCiudades()
  }, []);

  const inventario = true;
  const navigate = useNavigate();

  return (
    <div className="d-flex">
      <main className="flex-grow-1">
        {inventarios.length ? (
          <>
            <Filtros title={"Inventarios"} dataInventarios={dataInventarios} ciudades={ciudades.length} />
            {ciudades.length > 0 ? (
              <div className="row mt-3">
                {ciudades.map((ciudad, index) => (
                  <div key={ciudad.id} className="col-lg-3 col-md-6 col-sm-12 mb-3 text-decoration-none" style={{ color: "black" }} onClick={() => navigate(`/ciudad/${ciudad.id}`)}>
                    <CardCiudad {...ciudad} nombre={ciudad.nombre} estado={ciudad.estado.nombre} sucursales={ciudad.sucursales_cantidad} creado={ciudad.created_at} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-sucursales-container d-flex justify-content-center align-items-center">
                <div className="no-sucursales-content text-center">
                  <h6 className="mb-2">Aun no haz dado de alta sucursales</h6>
                  <Link to={"/ciudad/nueva"}>
                    <button className="btn-noSucursales descriptionCardCiudad">Crear sucursales</button>
                  </Link>

                </div>
              </div>
            )}
          </>
        ) : (
          <div className="no-sucursales-container d-flex justify-content-center align-items-center">
            <div className="no-sucursales-content text-center mt-5">
              <h6 className="mb-2 mt-5">No hay inventario disponible,</h6>
              <h6 className="mb-4">agrega primero el inventario para continuar.</h6>
              <Link to={"agregar"}>
                <button className="btn-noSucursales descriptionCardCiudad">Añadir inventario</button>
              </Link>
            </div>
          </div>
        )}
        <ListProductos/>
      </main>
    </div>
  );
}

export default Inventarios;
