import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ options, value, onChange, name, isDisabled,getOptionsLabel }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#b4bd009b', // Estilo del control del Select
            // Otros estilos comunes que desees aplicar a todos los Selects
        }),
        // Puedes definir más estilos aquí según tus necesidades
    };

    return (
        <Select
            name={name}
            options={options}
            value={value}
            onChange={onChange}
            styles={customStyles}
            getOptionLabel={(option) => `${getOptionsLabel ? option.label:option.nombre}`}
            getOptionValue={(option) => getOptionsLabel ? option.value:option.id}
            isDisabled={isDisabled}
        />
    );
};

export default CustomSelect;